import React, { useEffect, useState, Fragment } from 'react';
import { moocAPI } from '../../../services';
import useAsyncError from '../../../hooks/useAsyncError';
import RenderWithLoading from '../../generic/RenderWithLoading';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useParams, Redirect } from 'react-router-dom';
import ShareCertificateModal from '../../modals/ShareCertificateModal';
import CompletionConfirmationCard from './CompletionConfirmationCard';
import CourseInfoCard from './CourseInfoCard';
import ROUTES from '../../../consts/routes';
import { Helmet } from 'react-helmet';

const CertificatePageContent: React.FC = () => {
    const throwAsyncError = useAsyncError();
    const { certificateId } = useParams<{ certificateId: string }>();

    const [
        certificateData,
        setCertificateData,
    ] = useState<CertificateData | null>(null);
    const [certificateImgLoaded, setCertificateImgLoaded] = useState<boolean>(
        false,
    );
    const [notFound, setNotFound] = useState<boolean>(false);
    const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);

    useEffect(() => {
        moocAPI
            .get(`courses/certificates/${certificateId}/`)
            .then(res => {
                if (res === null) {
                    setNotFound(true);
                } else {
                    setCertificateData(res);
                }
            })
            .catch(throwAsyncError);
    }, [certificateId, throwAsyncError]);

    if (notFound) {
        return <Redirect to={'/' + ROUTES.PAGE_NOT_FOUND} />;
    }

    return (
        <Fragment>
            <RenderWithLoading hasLoaded={certificateData !== null}>
                {certificateData?.certificate_url && (
                    <Container style={{ marginTop: '2rem' }}>
                        <Helmet>
                            <meta
                                property='og:url'
                                content={window.location.href}
                            />
                            <meta property='og:type' content='og:object' />
                            <meta
                                property='og:title'
                                content={`Certificate of completion for ${certificateData.student_course.course.title}`}
                            />
                            <meta
                                property='og:description'
                                content={
                                    certificateData.student_course.course
                                        .sub_title
                                }
                            />
                            <meta
                                property='og:image'
                                content={certificateData.certificate_url}
                            />
                            <meta
                                property='twitter:site'
                                content='recourseai'
                            />
                            <meta property='twitter:card' content='summary' />
                        </Helmet>
                        <h2 className='h2 mb-5'>
                            {certificateData.student_course.course.title}
                        </h2>
                        <Row>
                            <Col md={5} xs={12}>
                                <Row style={{ marginBottom: '2rem' }}>
                                    <Col>
                                        <CompletionConfirmationCard
                                            studentCourse={
                                                certificateData.student_course
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <CourseInfoCard
                                            course={
                                                certificateData.student_course
                                                    .course
                                            }
                                            hideEnrol={
                                                certificateData.allow_download
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Col>

                            <Col md={7} xs={12}>
                                <Row className='mb-3'>
                                    <img
                                        style={{ maxWidth: '600px' }}
                                        src={certificateData.certificate_url}
                                        alt={`${certificateData.student_course.student_name}'s certificate of completion for ${certificateData.student_course.course.title} provided by ${certificateData.student_course.course.provider.name}`}
                                        onLoad={() =>
                                            setCertificateImgLoaded(true)
                                        }
                                    />
                                </Row>
                                {certificateData.allow_download &&
                                    certificateImgLoaded && (
                                        <Row className='d-flex justify-content-start'>
                                            <Col style={{ flexGrow: 0 }}>
                                                <Button
                                                    onClick={() =>
                                                        setShareModalOpen(true)
                                                    }
                                                >
                                                    Share
                                                </Button>
                                            </Col>
                                            <Col style={{ flexGrow: 0 }}>
                                                <a
                                                    href={moocAPI.absoluteUrl(
                                                        `courses/certificates/${certificateId}/pdf`,
                                                    )}
                                                >
                                                    <Button>Download</Button>
                                                </a>
                                            </Col>
                                        </Row>
                                    )}
                            </Col>
                        </Row>
                    </Container>
                )}
                {certificateData?.student_course && (
                    <ShareCertificateModal
                        show={shareModalOpen}
                        onClose={() => setShareModalOpen(false)}
                        course={certificateData.student_course.course}
                    />
                )}
            </RenderWithLoading>
        </Fragment>
    );
};

export default CertificatePageContent;
