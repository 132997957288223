import React, { Fragment } from 'react';
import { Row, Col, Button, Alert, Card, ListGroup } from 'react-bootstrap';
import { Result, Total } from './InvestigationActivity';
import TimeShorthand from '../../../generic/TimeShorthand';

interface Props {
    myOrders: InvestigationOrder[];
    result: Result | null;
    total: Total | null;
    setResultState: React.Dispatch<React.SetStateAction<Result | null>>;
}

export const formatTotalTime = (totalTime: string): string => {
    const split = totalTime.split('-');
    return `up to ${split[split.length - 1].trim()}`;
};

const OrderItem: React.FC<{
    name: Investigation['name'] | JSX.Element;
    cost: Investigation['cost'];
    timeEstimated: Investigation['time_estimated'];
    className?: string;
}> = ({ name, cost, timeEstimated, className }): JSX.Element => {
    return (
        <div className={`text-left ${className}`}>
            <Row noGutters className='mb-1'>
                <Col className='px-2 text-break'>{name}</Col>
            </Row>
            {(cost || timeEstimated) && (
                <Row noGutters style={{ fontSize: '0.9rem' }}>
                    <Col className='px-2'>{cost}</Col>
                    <Col className='px-2'>
                        {timeEstimated && (
                            <TimeShorthand>
                                {formatTotalTime(timeEstimated)}
                            </TimeShorthand>
                        )}
                    </Col>
                </Row>
            )}
        </div>
    );
};

const MyOrders: React.FC<Props> = ({
    myOrders,
    result,
    total,
    setResultState,
}) => {
    return (
        <div
            className={`d-flex flex-column flex-grow-1 my-2 px-2 min-h-0 ${
                myOrders.length ? 'border-bottom' : ''
            }`}
        >
            {myOrders.length === 0 ? (
                <Alert
                    variant='secondary'
                    style={{ textAlign: 'center', marginTop: '15px' }}
                >
                    Use the list on the left to order investigations
                </Alert>
            ) : (
                <Fragment>
                    <div
                        className='overflow-auto'
                        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                        tabIndex={0}
                        role='group'
                        aria-label='my-orders'
                    >
                        {myOrders.map((orderSetVal, orderSetIndex) => (
                            <Card
                                key={orderSetIndex}
                                className='mb-2 border-0'
                                style={{
                                    borderColor: 'black',
                                    borderRadius: '0.5rem',
                                }}
                            >
                                <Card.Title
                                    className='pt-2 pl-2 mb-2 h6'
                                    style={{
                                        color: 'var(--primary)',
                                    }}
                                >
                                    {`Order ${orderSetIndex + 1}`}
                                </Card.Title>
                                <ListGroup className='px-3'>
                                    {orderSetVal.investigations.map(
                                        orderVal => {
                                            const id = `${orderSetIndex}-${orderVal.id}`;
                                            const active =
                                                result && result.id === id;
                                            return (
                                                <Button
                                                    key={id}
                                                    onClick={() => {
                                                        setResultState({
                                                            id,
                                                            investigation: orderVal,
                                                        });
                                                    }}
                                                    variant={
                                                        active
                                                            ? 'primary'
                                                            : 'outline-primary'
                                                    }
                                                    className='px-0 mb-2'
                                                >
                                                    <OrderItem
                                                        name={orderVal.name}
                                                        cost={orderVal.cost}
                                                        timeEstimated={
                                                            orderVal.time_estimated
                                                        }
                                                    />
                                                </Button>
                                            );
                                        },
                                    )}
                                </ListGroup>
                            </Card>
                        ))}
                    </div>
                    {total && (
                        <OrderItem
                            className='border-bottom align-items-center mx-3 mt-2'
                            name={
                                <span className='font-weight-bold'>Total</span>
                            }
                            cost={total.total_cost}
                            timeEstimated={total.total_time_estimated}
                        />
                    )}
                </Fragment>
            )}
        </div>
    );
};

export default MyOrders;
