import React from 'react';
import usePortal from '../../utils/usePortal';
import { Modal } from 'react-bootstrap';

interface Props {
    show: boolean;
    onClose: () => void;
}

const PortfolioCaseUploadTermsAndConditionsModal: React.FC<Props> = props => {
    const target = usePortal('modal-root');

    return (
        <Modal
            show={props.show}
            onHide={(): void => {
                props.onClose();
            }}
            container={target}
        >
            <Modal.Header closeButton>
                <Modal.Title>Terms and Conditions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Uploaded information may be used by Recourse AI LTD. in order to
                improve the quality and reliability of our Software and Services
                for the benefit of Authorized Users.
            </Modal.Body>
        </Modal>
    );
};

export default PortfolioCaseUploadTermsAndConditionsModal;
