import ExerciseAPI from '../../../components/activities/ExerciseAPI';
import { moocAPI } from '../../../services';
import useAsyncError from '../../../hooks/useAsyncError';
import { useMixpanel } from '../../../components/contexts/MixpanelContext';
import { useMutation, useQueryClient } from '@tanstack/react-query';

// Attempt
export const isAttemptCompleted = (attempt: StudentExerciseAttempt): boolean =>
    !!attempt.completed_datetime;
export const isAttemptInProgress = (attempt: StudentExerciseAttempt): boolean =>
    !isAttemptCompleted(attempt);

// Student activity
export const isStudentActivityCompleted = (sa: StudentActivity): boolean =>
    !!sa.completed_datetime;
export const isStudentActivityInProgress = (sa: StudentActivity): boolean =>
    !isStudentActivityCompleted(sa);

export const hasAttemptInProgress = (
    activity: Activity,
    studentActivity?: StudentActivity | null,
) => {
    const writingInProgress =
        !!activity.writing_assignment &&
        !activity.writing_assignment?.submitted_at;
    const otherExerciseInProgress = studentActivity?.attempts.some(
        isAttemptInProgress,
    );

    return writingInProgress || otherExerciseInProgress;
};

const cancelAttempt = async ({
    course,
    activity,
    attempt,
}: {
    course: Course | undefined;
    activity: Activity;
    attempt: StudentExerciseAttempt;
}) => {
    const exerciseAPI = new ExerciseAPI(activity);
    await exerciseAPI.cancelSession(attempt.exercise_session_id);
    await moocAPI.delete(
        course
            ? `course/${course.id}/activity/${activity.id}/attempt/${attempt.id}/cancel/`
            : `activity/${activity.id}/attempt/${attempt.id}/cancel/`,
    );
};

export const useCancelAttemptMutation = () => {
    const throwAsyncError = useAsyncError();
    const mixpanel = useMixpanel();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: cancelAttempt,
        onMutate: ({ attempt }) =>
            mixpanel?.track('cancel_attempt', { attempt_id: attempt.id }),
        onSuccess: (_, { course, activity, attempt }) => {
            const deleteAttemptFromData = (data: Activity) => {
                const newData = { ...data };
                const index = newData.student_activity!.attempts.findIndex(
                    a => a.id === attempt.id,
                );
                if (index > -1) {
                    newData.student_activity?.attempts.splice(index, 1);
                }
                return newData;
            };

            queryClient.setQueryData(
                [
                    'course',
                    course ? course.id.toString() : undefined,
                    'activity',
                    activity.id.toString(),
                ],
                (oldData?: Activity) => {
                    if (oldData === undefined) return undefined;
                    return deleteAttemptFromData(oldData);
                },
            );

            queryClient.setQueryData(
                [
                    'course',
                    course ? course.id.toString() : undefined,
                    'activities',
                ],
                (oldData?: Activity[]) => {
                    if (oldData === undefined) return undefined;
                    const newData = [...oldData];
                    const activityIndex = newData.findIndex(
                        a => a.id === activity.id,
                    );
                    if (activityIndex > -1) {
                        newData[activityIndex] = deleteAttemptFromData(
                            newData[activityIndex],
                        );
                    }
                    return newData;
                },
            );
        },
        onError: throwAsyncError,
    });
};
