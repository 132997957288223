import React, { useState, useEffect, Fragment } from 'react';
import GroupsView from '../components/portfolio/GroupsView';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { moocAPI } from '../services';
import { Alert } from 'react-bootstrap';
import ROUTES from '../consts/routes';

export interface PortfolioPageProps {
    groups: Group[] | null;
    setGroups: React.Dispatch<React.SetStateAction<Group[] | null>>;
    refetch: () => void;
}

const PortfolioPage: React.FC<PortfolioPageProps> = ({
    groups,
    setGroups,
    refetch,
}) => {
    const location = useLocation();
    const [serverError, setServerError] = useState<string | null>(null);
    const history = useHistory();
    const params = queryString.parse(location.search);

    useEffect(() => {
        if ('group-invitation' in params) {
            moocAPI
                .post('portfolio/groups/join/', {
                    invitation: params['group-invitation'],
                })
                .then(res =>
                    history.push(`/${ROUTES.GROUP_OVERVIEW}/${res.group}`),
                )
                .catch(err => setServerError(err.message));
        }
    }, [history, location.search, params]);

    return (
        <Fragment>
            {serverError && <Alert variant='danger'>{serverError}</Alert>}
            <GroupsView
                groups={groups}
                setGroups={setGroups}
                refetch={refetch}
            />
        </Fragment>
    );
};

export default PortfolioPage;
