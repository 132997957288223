import React, { Fragment } from 'react';
import { InputGroup, Form, Button } from 'react-bootstrap';

interface Props {
    link: string;
    text?: string;
    className?: string;
}

const LinkShare: React.FC<Props> = ({ link, text, className }) => {
    return (
        <Fragment>
            <InputGroup className={className}>
                <Form.Control readOnly defaultValue={link} />
                <InputGroup.Append>
                    <Button
                        style={{
                            borderRadius: '6px',
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                        }}
                        onClick={() => {
                            navigator.clipboard.writeText(link);
                        }}
                    >
                        Copy
                    </Button>
                </InputGroup.Append>
            </InputGroup>
            {text && <Form.Text className='text-muted'>{text}</Form.Text>}
        </Fragment>
    );
};

export default LinkShare;
