import React, { useContext, useEffect, useMemo } from 'react';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ROUTES from '../../../consts/routes';
import StudentFeaturesContext from '../../contexts/StudentFeaturesContext';
import { useMixpanel } from '../../contexts/MixpanelContext';

const AnnouncementAlert: React.FC<{
    id: string;
    heading: React.ReactElement | string;
    body: React.ReactElement;
    cta: { text: string; url: string };
}> = ({ id, heading, body, cta }) => {
    const mixpanel = useMixpanel();

    useEffect(() => {
        mixpanel?.track('announcement_displayed', { announcement: id });
    }, [id, mixpanel]);

    return (
        <Alert id={id} variant='secondary' className='p-4'>
            <Alert.Heading className='mb-4'>{heading}</Alert.Heading>
            <Row className='align-items-center'>
                <Col xs='auto'>{body}</Col>
                <Col className='ml-4 text-center'>
                    <Link to={cta.url}>
                        <Button
                            size='lg'
                            onClick={() => {
                                mixpanel?.track('announcement_cta_clicked', {
                                    announcement: id,
                                });
                            }}
                        >
                            {cta.text}
                        </Button>
                    </Link>
                </Col>
            </Row>
        </Alert>
    );
};

const TrialWelcomeToAccount: React.FC<{
    course: { id: number; title: string };
}> = ({ course }) => {
    const url = `/${ROUTES.COURSES}/${course.id}`;
    return (
        <AnnouncementAlert
            id='trial_welcome_to_account'
            heading='Welcome to your account'
            body={
                <>
                    <p>
                        The <Link to={url}> {course.title} </Link> course will
                        help you familiarize with the platform
                    </p>
                    <p>You can then proceed to simulated human encounters</p>
                </>
            }
            cta={{
                text: 'Get started',
                url,
            }}
        />
    );
};

export const getTrialCountDownMsg = (secondsRemaining: number): string => {
    const trialDaysRemaining = Math.floor(secondsRemaining / 3600 / 24);
    if (trialDaysRemaining > 0) {
        return `Your trial: ${trialDaysRemaining} day${
            trialDaysRemaining === 1 ? '' : 's'
        } left`;
    } else if (secondsRemaining! > 0) {
        return 'Your trial ends today';
    }
    // This widget should not be used if the trial has ended
    else {
        return 'Your trial has ended';
    }
};

const TrialResumeActivity: React.FC<{
    activity: { id: number; title: string; course_id: number };
}> = ({ activity }) => {
    const { trialData } = useContext(StudentFeaturesContext);

    const headingMsg = useMemo(
        () => getTrialCountDownMsg(trialData.seconds_remaining!),
        [trialData.seconds_remaining],
    );

    const url = `/${ROUTES.COURSES}/${activity.course_id}/${ROUTES.ACTIVITY}/${activity.id}`;

    return (
        <AnnouncementAlert
            id='trial_resume_activity'
            heading={headingMsg}
            body={
                <>
                    <p>
                        You started working on{' '}
                        <Link to={url}> {activity.title} </Link>
                    </p>
                    <p>Resume and complete to see your feedback</p>
                </>
            }
            cta={{
                text: 'Open Activity',
                url,
            }}
        />
    );
};

const TrialRevisitActivity: React.FC<{
    activity: { id: number; title: string; course_id: number };
}> = ({ activity }) => {
    const { trialData } = useContext(StudentFeaturesContext);

    const headingMsg = useMemo(
        () => getTrialCountDownMsg(trialData.seconds_remaining!),
        [trialData.seconds_remaining],
    );

    const url = `/${ROUTES.COURSES}/${activity.course_id}/${ROUTES.ACTIVITY}/${activity.id}`;

    return (
        <AnnouncementAlert
            id='trial_revisit_activity'
            heading={headingMsg}
            body={
                <>
                    <p>
                        You&apos;ve explored all the activities! Up for a
                        challenge?
                    </p>
                    <p>
                        Revisit <Link to={url}>{activity.title}</Link> and beat
                        your score
                    </p>
                </>
            }
            cta={{
                text: 'Open Activity',
                url,
            }}
        />
    );
};

const TrialTryActivity: React.FC<{
    activity: { id: number; title: string; course_id: number };
}> = ({ activity }) => {
    const { trialData } = useContext(StudentFeaturesContext);

    const headingMsg = useMemo(
        () => getTrialCountDownMsg(trialData.seconds_remaining!),
        [trialData.seconds_remaining],
    );

    const url = `/${ROUTES.COURSES}/${activity.course_id}/${ROUTES.ACTIVITY}/${activity.id}`;

    return (
        <AnnouncementAlert
            id='trial_try_activity'
            heading={headingMsg}
            body={
                <>
                    <p>Try something new?</p>
                    <p>
                        We think you&apos;d like{' '}
                        <Link to={url}>{activity.title}</Link>
                    </p>
                </>
            }
            cta={{
                text: 'Open Activity',
                url,
            }}
        />
    );
};

const WIDGETS: any = {
    trial_welcome_to_account: TrialWelcomeToAccount,
    trial_resume_activity: TrialResumeActivity,
    trial_try_activity: TrialTryActivity,
    trial_revisit_activity: TrialRevisitActivity,
};

export default WIDGETS;
