import React, { Fragment, Suspense, useEffect, useMemo } from 'react';
import {
    Drawer,
    DrawerContent,
    DrawerOverlay,
    useDisclosure,
    useMediaQuery,
} from '@chakra-ui/react';
import InsightsFeedbackModal from '../../../components/modals/InsightsFeedbackModal';
import { ModalProps } from '../../../components/modals/types';
import ViewWritingModal from '../../../components/modals/ViewWritingModal';
import { useHistory } from 'react-router-dom';
import ROUTES from '../../../consts/routes';
import WritingAssignmentLabel from './WritingAssignmentLabel';
import InstructionModal from './InstructionModal';
import useScrollRef from '../../../hooks/useScrollRef';
import { hasAttemptInProgress, useCancelAttemptMutation } from './utils';
import { popSearchParam } from '../../../utils/historyUtils';
import useExternalIDPRoutePrefix from '../../../hooks/useExternalIDPRoutePrefix';
import Interaction from '../../../pages/Interaction';
import DetailedView from './DetailedView';
import CompactView from './CompactView';
import { isMobile } from 'react-device-detect';
import useStyling from '@recourseai/components/src/theme/useStyling';

const ActivityPageContent = React.lazy(() =>
    import(
        '../../../components/activities/ActivityPageContent/ActivityPageContent'
    ),
);

const ActivityCard: React.FC<{
    activity: Activity;
    studentActivity?: StudentActivity | null;
    index?: number;
    course?: Course;
    isHighlighted?: boolean;
    isActive?: boolean;
    onStartActivityMode?: () => void;
    onCloseActivityMode?: () => void;
}> = ({
    activity,
    studentActivity,
    index,
    course,
    isHighlighted,
    isActive,
    onCloseActivityMode,
    onStartActivityMode,
}) => {
    const history = useHistory();
    const routePrefix = useExternalIDPRoutePrefix();
    const scrollRef = useScrollRef(!!isHighlighted);

    const [screenWidthGreatherThan950] = useMediaQuery('(min-width: 950px)');
    const { isBase } = useStyling();

    const {
        isOpen: isInstructionModalOpen,
        onOpen: openInstructionsModal,
        onClose: closeInstructionsModal,
    } = useDisclosure();

    const startActivity = (skipInstructions = false) => {
        if (!skipInstructions && activity.before_start_instructions) {
            openInstructionsModal();
        } else {
            if (onStartActivityMode) {
                onStartActivityMode();
            }
            history.replace({
                // Use the routeprefix to prevent a redirect from App, which blocks the activity drawer from opening
                pathname: course
                    ? `${routePrefix}/${ROUTES.COURSES}/${course.id}/${ROUTES.ACTIVITY}/${activity.id}`
                    : `${routePrefix}/${ROUTES.ACTIVITY}/${activity.id}`,
                search: history.location.search,
            });
        }
    };

    const closeActivity = () => {
        history.replace({ search: '' }); // Clears no-webgl
        if (onCloseActivityMode) {
            onCloseActivityMode();
        }
    };

    const cancelAttemptMutation = useCancelAttemptMutation();

    const completedAttempts = useMemo(
        () =>
            studentActivity?.attempts.filter(a => !!a.completed_datetime) || [],
        [studentActivity],
    );

    const hasRemainingAttemptsToday =
        !studentActivity ||
        (studentActivity.has_remaining_attempts_today ?? true);

    const hasRemainingTotalAttempts =
        !activity.config.total_attempt_limit ||
        !studentActivity ||
        studentActivity.total_attempts_remaining === null ||
        studentActivity.total_attempts_remaining > 0;

    const _InsightsModal = useMemo(() => {
        const _Modal = (props: ModalProps) => (
            <InsightsFeedbackModal
                attempts={completedAttempts || []}
                activity={activity}
                course={course}
                {...props}
            />
        );
        _Modal.display_name = 'InsightsModal';
        return _Modal;
    }, [activity, completedAttempts, course]);

    const _ViewWritingModal = useMemo(() => {
        const _Modal = (props: ModalProps) => (
            <ViewWritingModal
                title={
                    <WritingAssignmentLabel
                        writingAssignment={activity.writing_assignment}
                    />
                }
                rawContent={activity.writing_assignment?.content}
                {...props}
            />
        );
        _Modal.display_name = 'ViewWritingModal';
        return _Modal;
    }, [activity]);

    useEffect(() => {
        const shouldStartActivity = new URLSearchParams(
            history.location.search,
        ).get('startActivity');
        if (shouldStartActivity && isHighlighted) {
            popSearchParam('startActivity', false, history);
            startActivity(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // TODO remove
    const hasSimliQueryParam = new URLSearchParams(window.location.search).has(
        'faceID',
    );

    const sharedProps = {
        activity,
        scrollRef,
        index,
        isHighlighted,
        screenWidthGreatherThan950,
        studentActivity,
        cancelAttemptMutation,
        startActivity,
        hasRemainingAttemptsToday,
        hasRemainingTotalAttempts,
        course,
        completedAttempts,
        insightsModal: _InsightsModal,
        viewWritingModal: _ViewWritingModal,
    };

    return (
        <Fragment>
            {course && !(isMobile && isBase) ? (
                <DetailedView {...sharedProps} />
            ) : (
                <CompactView {...sharedProps} />
            )}

            <Drawer
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={false}
                closeOnEsc={false}
                placement='bottom'
                isOpen={!!isActive}
                onClose={closeActivity}
                size='full'
            >
                <DrawerOverlay />
                <DrawerContent>
                    <Suspense fallback={null}>
                        {activity.type === 'exercise' &&
                        ['CONVERSATION', 'TUTOR'].includes(
                            activity.config.exercise_type,
                        ) &&
                        (!!activity.config.use_new_interaction_ui ||
                            hasSimliQueryParam) ? (
                            <Interaction
                                activityId={activity.id.toString()}
                                courseId={course?.id.toString()}
                                onClose={closeActivity}
                            />
                        ) : (
                            <ActivityPageContent
                                courseId={course?.id.toString()}
                                activityId={activity.id.toString()}
                                onClose={closeActivity}
                            />
                        )}
                    </Suspense>
                </DrawerContent>
            </Drawer>

            <InstructionModal
                onStart={() => startActivity(true)}
                show={isInstructionModalOpen}
                onClose={closeInstructionsModal}
                startButtonText={
                    hasAttemptInProgress(activity, studentActivity)
                        ? 'Resume'
                        : 'Start'
                }
            >
                <div
                    dangerouslySetInnerHTML={{
                        __html: activity.before_start_instructions!,
                    }}
                />
            </InstructionModal>
        </Fragment>
    );
};

export default ActivityCard;
