import React, { CSSProperties } from 'react';
import WithTooltip from './WithTooltip';

interface Props {
    className?: string;
    style?: CSSProperties;
    shortTextGen?: (text: string) => string;
}

const abbreviateTimeUnits = (timeStr: string): string =>
    timeStr
        .replace(/\s*minutes?/gi, 'm')
        .replace(/\s*hours?/gi, 'h')
        .replace(/\s*days?/gi, 'd')
        .replace(/\s*weeks?/gi, 'w');

const TimeShorthand: React.FC<Props> = ({
    children,
    className,
    style,
    shortTextGen,
}) => {
    const text = children as string;
    const shortText = shortTextGen
        ? shortTextGen(text)
        : abbreviateTimeUnits(text);

    return (
        <WithTooltip hoverMessage={text}>
            <span className={className} style={style}>
                {shortText}
            </span>
        </WithTooltip>
    );
};

export default TimeShorthand;
