import React, { useEffect, useRef, Fragment } from 'react';
import {
    ReactMediaRecorderHookProps,
    useReactMediaRecorder,
} from '../../hooks/useMediaRecorder';
import CentredComponent from '../../hocs/CentredComponent';
import { Button, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause, faVideo, faStop } from '@fortawesome/free-solid-svg-icons';

const LivePreview: React.FC<{ stream: MediaStream; paused?: boolean }> = ({
    stream,
    paused,
}) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (videoRef.current && stream) {
            videoRef.current.srcObject = stream;
        }
    }, [stream]);

    return (
        <div className='w-100 h-100 element-16-9 bg-black'>
            <div className='position-relative w-100 h-100'>
                {paused && (
                    <Row
                        noGutters
                        className='position-absolute w-100 h-100 justify-content-center align-items-center'
                        style={{
                            background: 'rgba(0,0,0,0.6)',
                            color: 'white',
                            fontSize: '1.6rem',
                        }}
                    >
                        Recording is paused
                    </Row>
                )}
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video
                    ref={videoRef}
                    className='w-100 h-100'
                    autoPlay
                    id='live_preview'
                />
            </div>
        </div>
    );
};

const RecordingPreview: React.FC<{ stream: Blob }> = ({ stream }) => {
    return (
        <div className='w-100 h-100 element-16-9 bg-black'>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
                src={URL.createObjectURL(stream)}
                className='w-100 h-100 rounded'
                controls
                id='recording_preview'
            />
        </div>
    );
};

interface Props {
    mediaRecorderProps: ReactMediaRecorderHookProps;
    onError: (err: string) => void;
}

const ScreenRecorder: React.FC<Props> = ({ mediaRecorderProps, onError }) => {
    const {
        error,
        status,
        previewStream,
        mediaBlob,
        startRecording,
        pauseRecording,
        resumeRecording,
        stopRecording,
    } = useReactMediaRecorder(mediaRecorderProps);

    useEffect(() => {
        onError(error);
    }, [error, onError]);

    return (
        <Fragment>
            {previewStream && (
                <CentredComponent
                    className='mx-1 mb-2 container-16-9'
                    component={
                        <LivePreview
                            stream={previewStream}
                            paused={status === 'paused'}
                        />
                    }
                />
            )}
            {mediaBlob && !['recording', 'paused'].includes(status) && (
                <CentredComponent
                    className='mx-1 mb-2 container-16-9'
                    component={<RecordingPreview stream={mediaBlob} />}
                />
            )}
            {['recording', 'paused'].includes(status) && (
                <Row className='justify-content-center p-1'>
                    <Button
                        variant='secondary'
                        className='mx-3 px-4'
                        bsPrefix='btn-brand'
                        onClick={
                            status === 'recording'
                                ? pauseRecording
                                : resumeRecording
                        }
                    >
                        {status === 'recording' ? (
                            <>
                                {' '}
                                <FontAwesomeIcon
                                    icon={faPause}
                                    className='mr-2'
                                />{' '}
                                Pause{' '}
                            </>
                        ) : (
                            <>
                                {' '}
                                <FontAwesomeIcon
                                    icon={faVideo}
                                    className='mr-2'
                                />{' '}
                                Resume{' '}
                            </>
                        )}
                    </Button>
                    <Button
                        variant='danger'
                        className='mx-3 px-4'
                        onClick={stopRecording}
                    >
                        <FontAwesomeIcon icon={faStop} className='mr-2' /> Stop
                    </Button>
                </Row>
            )}
            {!['recording', 'paused'].includes(status) && (
                <CentredComponent
                    component={
                        <Button
                            className={!mediaBlob ? 'p-5' : ''}
                            variant='light'
                            size={!mediaBlob ? 'lg' : undefined}
                            onClick={startRecording}
                        >
                            <FontAwesomeIcon icon={faVideo} className='mx-2' />{' '}
                            {!mediaBlob ? 'Start Recording' : 'Record New'}
                        </Button>
                    }
                />
            )}
        </Fragment>
    );
};

export default ScreenRecorder;
