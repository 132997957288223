import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { getPrettyDateFormat } from '../../utils/dateUtils';
import { Link } from 'react-router-dom';
import ROUTES from '../../consts/routes';

const CaseCard: React.FC<{ portfolioCase: Case }> = ({ portfolioCase }) => {
    return (
        <Link
            to={`/${ROUTES.CASE_OVERVIEW}/${portfolioCase.id}`}
            style={{
                textDecoration: 'none',
                color: 'inherit',
            }}
        >
            <Card
                style={{
                    width: '14rem',
                    borderStyle: 'none',
                    cursor: 'pointer',
                    padding: '1rem',
                    borderRadius: '15px',
                }}
            >
                <Card.Img
                    src={portfolioCase.thumbnail_url}
                    alt=''
                    style={{ height: '9rem' }}
                />
                <Card.Body className='px-1 py-2'>
                    <Card.Title className='mb-1'>
                        {portfolioCase.title}
                    </Card.Title>
                    <Row className='justify-content-between flex-column'>
                        <Col>{portfolioCase.owner_details.name}</Col>
                        <Col
                            className='mb-0 text-muted'
                            style={{ fontSize: '0.8rem' }}
                        >
                            {getPrettyDateFormat(portfolioCase.created_at)}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Link>
    );
};

export default CaseCard;
