export interface PromiseWithResolvers<T> {
    promise: Promise<T>;
    resolve: (value: T | PromiseLike<T>) => void;
    reject: (reason?: any) => void;
}

export const promiseWithResolvers = <T = void>(
    PromiseClass = Promise,
): PromiseWithResolvers<T> => {
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/withResolvers

    let resolve: PromiseWithResolvers<T>['resolve'];
    let reject: PromiseWithResolvers<T>['reject'];
    const promise = new PromiseClass<T>((res, rej) => {
        resolve = res;
        reject = rej;
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return { promise, resolve, reject };
};
