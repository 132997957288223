import React from 'react';
import DashboardPageLayout, {
    HeaderHeight,
} from '../components/layouts/DashboardPageLayout';
import Section, { COURSE_CARD_SPACING } from './CoursesPageV2/Section';
import {
    Box,
    Button,
    Center,
    Flex,
    Heading,
    Img,
    Link,
    Spacer,
    Stack,
    Text,
    VStack,
} from '@chakra-ui/react';
import CourseCard from '../components/courses/CourseCardV2';
import { useCoursesQueries } from './CoursesPageV2';
import { Link as RouterLink } from 'react-router-dom';
import InsightsGraphicSRC from '../images/insights-graphic.jpg';
import ROUTES from '../consts/routes';
import { getCourseCardSize } from '../components/courses/CourseCardV2/utils';

const insightsCourseUrlBuilder = (course: Course) =>
    `/insights/courses/${course.id}`;

const InsightsPage: React.FC = () => {
    const [completedCoursesQuery] = useCoursesQueries(['completed']);
    return (
        <DashboardPageLayout noPadding>
            <Flex
                direction='column'
                bg={'brand.gradient.cold.full'}
                textColor='white'
                w='100%'
                h='150px'
            >
                <Flex py={5} px={10} h={HeaderHeight}>
                    Completed courses
                </Flex>
                <Spacer />
                <Flex px={10}>
                    <Heading fontWeight='normal'>Insights</Heading>
                </Flex>
                <Spacer />
            </Flex>
            <Box paddingY={5} paddingX={10}>
                {completedCoursesQuery.isLoading ||
                completedCoursesQuery.data?.length ? (
                    <Section
                        heading={<Box mt={4}>Completed courses</Box>}
                        isLoaded={completedCoursesQuery.isSuccess}
                        data={completedCoursesQuery.data}
                        skeletonItemSize={getCourseCardSize('completed')}
                        wrapContent
                    >
                        <Flex gap={COURSE_CARD_SPACING} wrap='wrap'>
                            {completedCoursesQuery.data?.length &&
                                completedCoursesQuery?.data.map((course, i) => (
                                    <CourseCard
                                        key={i}
                                        course={course}
                                        variant='completed'
                                        courseUrlBuilder={
                                            insightsCourseUrlBuilder
                                        }
                                    />
                                ))}
                        </Flex>
                    </Section>
                ) : (
                    <Center minH='350px' bg='white' borderRadius='lg'>
                        <Stack
                            spacing={10}
                            direction={['column', null, null, 'row']}
                            p={10}
                        >
                            <Img
                                objectFit='contain'
                                height='220px'
                                src={InsightsGraphicSRC}
                            />
                            <VStack alignItems='start' spacing={8}>
                                <Heading>
                                    You can find your learning insights here
                                </Heading>
                                <Text>
                                    Currently you don&apos;t have any insights.
                                    Please visit{' '}
                                    <Link
                                        as={RouterLink}
                                        to={`/${ROUTES.COURSES}`}
                                    >
                                        Courses
                                    </Link>{' '}
                                    to get started.
                                </Text>
                                <Button
                                    as={RouterLink}
                                    to={`/${ROUTES.COURSES}`}
                                    colorScheme='brand.black'
                                    size='lg'
                                    _hover={{
                                        color: 'white',
                                    }}
                                >
                                    Courses
                                </Button>
                            </VStack>
                        </Stack>
                    </Center>
                )}
            </Box>
        </DashboardPageLayout>
    );
};

export default InsightsPage;
