import React, { useCallback, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { useFormik } from 'formik';
import { Button, ButtonGroup, Col, Form, Row, Spinner } from 'react-bootstrap';
import CentredComponent from '../../hocs/CentredComponent';
import { useMixpanel } from '../contexts/MixpanelContext';

export interface SurveyQuestion {
    id: number;
    slug: string;
    title: string;
    description?: string;
    template: any;
}
interface GenericSurveyFieldTemplate {
    type: string;
    required?: boolean;
    submitOnChange?: boolean;
    labels?: {
        left?: string;
        right?: string;
    };
}
export type SurveyFieldTemplate = GenericSurveyFieldTemplate &
    (
        | {
              type: 'text';
              hint?: string;
          }
        | {
              type: 'horizontal-select';
              items: number | { value: string; variant: string }[];
          }
    );

export interface SurveyQuestionFormProps {
    onSubmit: (formData: any) => Promise<any>;
    onEdit: (responseId: number, formData: any) => Promise<any>;
    onSuccess: () => void;
    onError: () => void;
    surveyTemplate: {
        fields: {
            [name: string]: SurveyFieldTemplate;
        };
        order: {
            name: string;
            requiresPreviousField?: boolean;
        }[];
    };
}

const range = (start: number, end: number): number[] => {
    const rangeArr = [];
    for (let i = start; i < end; ++i) {
        rangeArr.push(i);
    }
    return rangeArr;
};

const isValidTemplateExpression = (toEvaluate: string): boolean => {
    const isExpression =
        toEvaluate.startsWith('exp(') && toEvaluate.endsWith(')');

    if (isExpression) {
        const includesImports =
            toEvaluate.includes('import') || toEvaluate.includes('require(');
        const includesNestedCalls = toEvaluate.match(/\w[.]\w/g);

        return !includesImports && !includesNestedCalls;
    }

    return false;
};

const evalTemplateExpression = (
    templateExpression: string,
    values: any,
): any => {
    // Step 1 replace all variables
    let expressionWithVariables = templateExpression.slice(4, -1);
    [...templateExpression.matchAll(/\b_\w+\b/g)].forEach(variableFound => {
        const fieldName = variableFound[0].slice(1);
        expressionWithVariables = expressionWithVariables.replaceAll(
            variableFound[0],
            values[fieldName],
        );
    });

    // Step 2 evaluate expression
    // eslint-disable-next-line no-eval
    return eval(expressionWithVariables);
};

const SurveyForm: React.FC<SurveyQuestionFormProps> = ({
    onSubmit,
    onEdit,
    onSuccess,
    onError,
    surveyTemplate,
}) => {
    const [responseId, setResponseId] = useState<number | undefined>(undefined);
    const [isSubmitting, setIsSubmitting] = useState<
        'background' | 'foreground' | null
    >(null);

    const submit = useCallback(
        (formData: any, trigger: 'manual' | 'automatic') => {
            let submitFun = onSubmit;
            if (responseId) {
                submitFun = (formData: any): Promise<any> =>
                    onEdit(responseId, formData);
            }

            if (!isSubmitting) {
                const newIsSubmitting =
                    trigger === 'manual' ? 'foreground' : 'background';
                setIsSubmitting(newIsSubmitting);
                submitFun(formData)
                    .then(data => {
                        setResponseId(data.id);
                        if (newIsSubmitting === 'foreground') {
                            onSuccess();
                        }
                    })
                    .catch(e => {
                        Sentry.captureException(e);
                        if (newIsSubmitting === 'foreground') {
                            onError();
                        }
                    })
                    .finally(() => setIsSubmitting(null));
            }
        },
        [responseId, isSubmitting, onSubmit, onEdit, onSuccess, onError],
    );

    const areAllRequiredFieldsNonEmpty = useCallback(
        (values: any): boolean => {
            return Object.entries(surveyTemplate.fields)
                .map(
                    ([name, fieldTemplate]) =>
                        !fieldTemplate.required || !!values[name],
                )
                .reduce(
                    (canBeSubmitted1, canBeSubmitted2) =>
                        canBeSubmitted1 && canBeSubmitted2,
                    true,
                );
        },
        [surveyTemplate],
    );

    const canShowField = useCallback(
        (fieldName: string, orderedIndex: number, fieldValues: any) => {
            if (surveyTemplate.order[orderedIndex].requiresPreviousField) {
                const { name: previousFieldName } = surveyTemplate.order[
                    orderedIndex - 1
                ];
                return !!fieldValues[previousFieldName];
            }
            return true;
        },
        [surveyTemplate],
    );

    const mixpanel = useMixpanel();

    const formik = useFormik({
        initialValues: Object.fromEntries(
            Object.keys(surveyTemplate.fields).map(name => [name, '']),
        ),
        onSubmit: values => {
            mixpanel?.track('platform_feedback_sent');
            submit(values, 'manual');
        },
    });

    return (
        <Form onSubmit={formik.handleSubmit}>
            {surveyTemplate.order
                .filter(({ name }, i) => canShowField(name, i, formik.values))
                .map(({ name: fieldName }) => {
                    const [name, fieldTemplate] = [
                        fieldName,
                        surveyTemplate.fields[fieldName],
                    ];
                    switch (fieldTemplate.type) {
                        case 'text':
                            return (
                                <div className='my-3' key={name}>
                                    {fieldTemplate.labels && (
                                        <Row className='justify-content-between'>
                                            <Col xs='auto'>
                                                {fieldTemplate.labels?.left}
                                            </Col>
                                            <Col xs='auto'>
                                                {fieldTemplate.labels?.right}
                                            </Col>
                                        </Row>
                                    )}
                                    <Form.Control
                                        id={name}
                                        key={name}
                                        as='textarea'
                                        rows={5}
                                        placeholder={
                                            fieldTemplate.hint &&
                                            isValidTemplateExpression(
                                                fieldTemplate.hint,
                                            )
                                                ? evalTemplateExpression(
                                                      fieldTemplate.hint,
                                                      formik.values,
                                                  )
                                                : fieldTemplate.hint
                                        }
                                        onChange={(event): void => {
                                            formik.handleChange(event);

                                            const newValues = {
                                                ...formik.values,
                                                [name]: event.target.value,
                                            };
                                            if (fieldTemplate.submitOnChange) {
                                                submit(newValues, 'automatic');
                                            }
                                        }}
                                    />
                                </div>
                            );
                        case 'horizontal-select': {
                            const options =
                                typeof fieldTemplate.items === 'number'
                                    ? range(0, fieldTemplate.items).map(n => ({
                                          value: n.toString(),
                                          variant: 'outline-primary',
                                      }))
                                    : fieldTemplate.items;
                            return (
                                <CentredComponent
                                    key={name}
                                    className='mx-auto my-3'
                                >
                                    <div>
                                        {fieldTemplate.labels && (
                                            <Row className='justify-content-between'>
                                                <Col xs='auto'>
                                                    {fieldTemplate.labels?.left}
                                                </Col>
                                                <Col xs='auto'>
                                                    {
                                                        fieldTemplate.labels
                                                            ?.right
                                                    }
                                                </Col>
                                            </Row>
                                        )}
                                        <ButtonGroup>
                                            {options.map(
                                                ({ value, variant }) => (
                                                    <Button
                                                        key={value}
                                                        active={
                                                            formik.values[
                                                                name
                                                            ] === value
                                                        }
                                                        variant={variant}
                                                        style={{
                                                            borderRadius: '0px',
                                                        }}
                                                        onClick={(): void => {
                                                            formik.setFieldValue(
                                                                name,
                                                                value,
                                                            );

                                                            const newValues = {
                                                                ...formik.values,
                                                                [name]: value,
                                                            };
                                                            if (
                                                                fieldTemplate.submitOnChange
                                                            ) {
                                                                submit(
                                                                    newValues,
                                                                    'automatic',
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        {value}
                                                    </Button>
                                                ),
                                            )}
                                        </ButtonGroup>
                                    </div>
                                </CentredComponent>
                            );
                        }
                        default:
                            return null;
                    }
                })}
            <Row className='mx-3 justify-content-end'>
                {surveyTemplate.order
                    .map(({ name }, i) => canShowField(name, i, formik.values))
                    .reduce(
                        (showingField1, showingField2) =>
                            showingField1 && showingField2,
                    ) && (
                    <Button
                        className='d-flex align-items-center'
                        type='submit'
                        disabled={
                            isSubmitting === 'foreground' ||
                            !areAllRequiredFieldsNonEmpty(formik.values)
                        }
                    >
                        {isSubmitting === 'foreground' ? (
                            <>
                                <Spinner
                                    as='span'
                                    animation='border'
                                    size='sm'
                                    role='status'
                                    aria-hidden='true'
                                    className='mr-2'
                                />
                                Submitting
                            </>
                        ) : (
                            <>Submit</>
                        )}
                    </Button>
                )}
            </Row>
        </Form>
    );
};

export default SurveyForm;
