import React, { Fragment, Suspense } from 'react';
import CentredComponent from '../../hocs/CentredComponent';
import LoadingSpinner from './LoadingSpinner';

interface Props {
    hasLoaded: boolean;
    label?: string;
}

const RenderWithLoading: React.FC<Props> = ({ hasLoaded, label, children }) => {
    return hasLoaded ? (
        <Fragment>{children}</Fragment>
    ) : (
        <CentredComponent className='mt-4 align-items-center text-primary'>
            <LoadingSpinner />
            {label && <span className='ml-2'>{label}</span>}
        </CentredComponent>
    );
};

export const SuspenseLoading: React.FC = ({ children }) => {
    return (
        <Suspense
            fallback={
                <CentredComponent className='mt-4 align-items-center'>
                    <LoadingSpinner />
                </CentredComponent>
            }
        >
            {children}
        </Suspense>
    );
};

export default RenderWithLoading;
