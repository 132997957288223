import * as React from 'react';
import {
    Heading,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Button,
} from '@chakra-ui/react';
import withFullScreenSupport from '../../hoc/FullScreenModal';
import { ModalWithNoInitialFocus } from '../../atoms/ModalWithNoInitialFocus/ModalWithNoInitialFocus';

interface Props {
    show: boolean;
    onClose: () => void;
    error: string | null;
}

const ModalWithFullscreenSupport = withFullScreenSupport(
    ModalWithNoInitialFocus,
);

const ErrorModal: React.FC<Props> = ({ show, onClose, error }) => (
    <ModalWithFullscreenSupport isOpen={show} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>
                <Heading>Error</Heading>
                <ModalCloseButton />
            </ModalHeader>
            <ModalBody>
                <p>{error ?? 'Something went wrong'}</p>
            </ModalBody>
            <ModalFooter justifyContent='center'>
                <Button onClick={onClose}>OK</Button>
            </ModalFooter>
        </ModalContent>
    </ModalWithFullscreenSupport>
);

export default ErrorModal;
