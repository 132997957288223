import React from 'react';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Flex,
    Table,
    Tbody,
    Td,
    Tr,
} from '@chakra-ui/react';
import { ConversationStage, Item, Section, Subsection } from './types';
import groupBy from 'lodash/groupBy';
import { InteractionStageFeedback } from '../types';

const getItems = (stage: ConversationStage) => {
    return stage.sections
        .flatMap((sec: Section) => sec.subsections)
        .flatMap((subsec: Subsection) => subsec.items);
};

const MissedItemsAccordionItem: React.FC<{
    tag: string;
    items: string[];
    tagPoints: number;
}> = ({ tag, tagPoints, items }) => (
    <AccordionItem>
        <AccordionButton
            bg='brand.black.100'
            _hover={{ bg: 'brand.black.200' }}
        >
            <Flex flex='1' justifyContent='space-between' fontWeight='bold'>
                <Box>{tag}</Box>
            </Flex>
            <AccordionIcon />
        </AccordionButton>
        <AccordionPanel py={0}>
            <Box>
                <Table>
                    <Tbody>
                        {items.map((item, i) => (
                            <Tr key={i}>
                                <Td px={0} py={1} color='red.500' width='65%'>
                                    {item}
                                </Td>
                                <Td px={0} py={2} textAlign='right'>
                                    {tagPoints.toFixed(1)}
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>
        </AccordionPanel>
    </AccordionItem>
);

const MissedItems: React.FC<InteractionStageFeedback<ConversationStage>> = ({
    marking_feedback,
}) => {
    const missedItems = getItems(marking_feedback!).filter(
        (item: Item) => !item.feedback.matched,
    );

    const missedItemsGroupedByTags = groupBy(
        missedItems,
        item => item.assessment_tag,
    );

    const orderedTags = Object.keys(missedItemsGroupedByTags)
        .sort(
            (t1, t2) =>
                missedItemsGroupedByTags[t1][0].assessment_tag_points -
                missedItemsGroupedByTags[t2][0].assessment_tag_points,
        )
        .reverse();

    return (
        <Accordion
            defaultIndex={[...Array(orderedTags.length).keys()]}
            allowMultiple
        >
            {orderedTags.map(tag => (
                <MissedItemsAccordionItem
                    key={tag}
                    tag={tag}
                    items={missedItemsGroupedByTags[tag].map(
                        item => item.title,
                    )}
                    tagPoints={
                        missedItemsGroupedByTags[tag][0].assessment_tag_points
                    }
                />
            ))}
        </Accordion>
    );
};

export default MissedItems;
