import * as React from 'react';
import {
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Image,
    Text,
} from '@chakra-ui/react';
import { ModalWithNoInitialFocus } from '../../atoms/ModalWithNoInitialFocus/ModalWithNoInitialFocus';
import { scrollBarStyles } from '../../theme/consts';

interface Props {
    show: boolean;
    onClose: () => void;
    attachment: AttachmentToDisplay;
}

export default function AttachmentsModal({ show, onClose, attachment }: Props) {
    return (
        <ModalWithNoInitialFocus
            isOpen={show}
            onClose={onClose}
            scrollBehavior='inside'
            size='6xl'
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{attachment?.title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody sx={scrollBarStyles}>
                    <Image src={attachment?.url} />
                    {attachment?.description && (
                        <Text fontSize='xs'>{attachment?.description}</Text>
                    )}
                </ModalBody>
            </ModalContent>
        </ModalWithNoInitialFocus>
    );
}
