export const delayFor = async (ms: number) =>
    new Promise(resolve => setTimeout(resolve, ms));

export const withTimeout = <T>(
    promise?: Promise<T>,
    timeoutMessage = 'Operation timed out',
    ms = 1000,
): Promise<T> | undefined => {
    if (!promise) return;

    const timeout = new Promise<never>((_, reject) =>
        setTimeout(() => reject(new Error(timeoutMessage)), ms),
    );
    return Promise.race([promise, timeout]);
};
