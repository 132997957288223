import { Box, Center, SimpleGrid, Text } from '@chakra-ui/react';
import React, { Fragment } from 'react';
import { InteractionStageFeedback } from '../types';
import { Section, TutorStage } from './types';

const FeedbackSection: React.FC<Section> = ({ title, subsections }) => {
    return (
        <Fragment>
            <Center bgColor='brand.black.200' fontWeight='bold' p={2}>
                {title}
            </Center>
            {subsections.map(subsection => {
                return (
                    <Fragment key={subsection.title}>
                        {subsection.title && (
                            <Center
                                p={1}
                                borderColor='brand.black.300'
                                borderTopWidth='1px'
                                borderBottomWidth='1px'
                            >
                                {subsection.title}
                            </Center>
                        )}
                        <SimpleGrid columns={2} px={1}>
                            {subsection.items.map(item => (
                                <Fragment key={item.title}>
                                    <Text>
                                        {item.feedback.matched &&
                                            item.feedback.user_response.text}
                                    </Text>
                                    <Text
                                        dangerouslySetInnerHTML={{
                                            __html: item.model_answer,
                                        }}
                                    />
                                </Fragment>
                            ))}
                        </SimpleGrid>
                    </Fragment>
                );
            })}
        </Fragment>
    );
};

const TutorFeedback: React.FC<InteractionStageFeedback<TutorStage>> = ({
    marking_feedback,
}) => {
    if (!marking_feedback) return null;
    return (
        <Box>
            <SimpleGrid
                color='white'
                bgColor='black'
                px={4}
                py={2}
                fontWeight='bold'
                fontSize={20}
                columns={2}
                roundedTop='15px'
            >
                <Center>Your answer</Center>
                <Center>Model answer</Center>
            </SimpleGrid>
            {marking_feedback.sections.map(section => {
                return <FeedbackSection key={section.title} {...section} />;
            })}
        </Box>
    );
};

export default TutorFeedback;
