import * as React from 'react';
import { isDesktop, isMobile } from 'react-device-detect';
import AvatarMobileOverlayTemplate from './AvatarMobileOverlayTemplate';
import AvatarDesktopOverlayTemplate from './AvatarDesktopOverlayTemplate';
import { Props } from '../../organisms/Chat/Chat';
const AvatarUIOverlayTemplate = (props: Props) => {
    if (isDesktop) {
        return <AvatarDesktopOverlayTemplate {...props} />;
    } else if (isMobile) {
        return <AvatarMobileOverlayTemplate {...props} />;
    }
    return null;
};

export default AvatarUIOverlayTemplate;
