import React, { CSSProperties } from 'react';
import { Row } from 'react-bootstrap';

interface Props {
    component?: JSX.Element;
    className?: string;
    style?: CSSProperties;
}

const CentredComponent: React.FC<Props> = props => {
    return (
        <Row
            noGutters
            style={{
                justifyContent: 'center',
                alignItems: 'center',
                ...props.style,
            }}
            className={props.className ?? ''}
        >
            {props.component || props.children}
        </Row>
    );
};

export default CentredComponent;
