import React, { useContext, useEffect, useState } from 'react';
import OrganisationRegistrationForm from '../components/forms/OrganisationRegistrationForm';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { authService, keycloak, moocAPI } from '../services';
import RenderWithLoading from '../components/generic/RenderWithLoading';
import useAsyncError from '../hooks/useAsyncError';
import IDPS from '../consts/external_idps';
import StudentFeaturesContext from '../components/contexts/StudentFeaturesContext';
import * as Sentry from '@sentry/browser';
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Button,
    VStack,
} from '@chakra-ui/react';

import AuthPageLayout, {
    RegistrationSubtitle,
    RegistrationTitle,
} from '../components/layouts/AuthPageLayout';
import { KeycloakLoginOptions } from 'keycloak-js';

const RegistrationLimitError = () => {
    const { idpHint } = useParams<{ idpHint: string }>();

    return (
        <VStack w='100%' spacing={4}>
            <Alert
                status='error'
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
                textAlign='center'
                p={4}
            >
                <AlertIcon boxSize='40px' mr={0} />
                <AlertTitle fontSize='lg' mt={5} mb={2}>
                    {!keycloak.authenticated ? (
                        <>
                            The registration limit for your invitation has been
                            reached
                        </>
                    ) : (
                        <>Access denied for {keycloak.profile?.email}</>
                    )}
                </AlertTitle>
                <AlertDescription>
                    {!keycloak.authenticated ? (
                        <p className='mb-0'>
                            If you already have an account use the login below.{' '}
                        </p>
                    ) : (
                        <p className='mb-0'>
                            Your organization has reached the user limit.{' '}
                        </p>
                    )}
                    <p>
                        Please contact your organization admin for further
                        assistance.
                    </p>
                </AlertDescription>
            </Alert>
            {!keycloak.authenticated ? (
                <Button
                    size='lg'
                    colorScheme='brand.black'
                    w='100%'
                    onClick={() => {
                        const keycloakLoginOptions: KeycloakLoginOptions = {};
                        if (IDPS.includes(idpHint)) {
                            keycloakLoginOptions['idpHint'] = idpHint;
                        }
                        keycloak.login(keycloakLoginOptions);
                    }}
                >
                    Login
                </Button>
            ) : (
                <Button
                    size='lg'
                    colorScheme='brand.black'
                    w='100%'
                    onClick={() => {
                        authService.logout();
                    }}
                >
                    Logout
                </Button>
            )}
        </VStack>
    );
};

const OrganisationRegistrationPage: React.FC = () => {
    const location = useLocation();
    const history = useHistory();
    const { idpHint } = useParams<{ idpHint: string }>();

    const { features, setFeatures, setTrialData } = useContext(
        StudentFeaturesContext,
    );
    const [invitation, setInvitation] = useState<string | null>(null);
    const [role, setRole] = useState<OrganisationRole | null>(null);
    const throwAsyncError = useAsyncError();

    useEffect(() => {
        const params = queryString.parse(location.search);
        if (!('invitation' in params)) {
            history.push('/');
        } else {
            moocAPI
                .post(
                    'organisation/invitation/verify/',
                    { invitation: params['invitation'] },
                    true,
                )
                .then(res => {
                    const invitation = params['invitation'];
                    if (typeof invitation === 'string') {
                        setInvitation(invitation);
                        setRole(res);
                    }
                })
                .catch(() => history.push('/'));
        }
    }, [history, location.search]);

    useEffect(() => {
        // Redirect user already who already has role to home
        if (keycloak.authenticated && features.length) {
            history.replace('/');
        }
        if (role && invitation && role.has_remaining_capacity) {
            if (keycloak.authenticated) {
                moocAPI
                    .post('student/assign-role', { invitation })
                    .then(() => {
                        keycloak
                            .loadUserProfile()
                            .catch(e => Sentry.captureException(e));

                        Promise.all([
                            moocAPI.get('products/mooc-features/'),
                            moocAPI.get('student/user'),
                        ])
                            .then(([features, userData]) => {
                                authService.initUserLoggers(userData);
                                setFeatures(features);
                                setTrialData(userData.trial_data);
                            })
                            .catch(throwAsyncError);
                        history.replace('/');
                    })
                    .catch(error => {
                        if (error.name === 'invalid') {
                            history.replace('/');
                        } else {
                            throwAsyncError(error);
                        }
                    });
            } else {
                if (IDPS.includes(idpHint)) {
                    keycloak.login({
                        idpHint: idpHint,
                    });
                }
            }
        }
    }, [
        features,
        history,
        idpHint,
        invitation,
        role,
        setFeatures,
        setTrialData,
        throwAsyncError,
    ]);

    const isInvitationValid = invitation !== null && role !== null;

    let hasLoaded = isInvitationValid;
    if (role?.has_remaining_capacity) {
        // If the user is authenticated we try to assign the role
        hasLoaded =
            hasLoaded && !keycloak.authenticated && !IDPS.includes(idpHint);
    } else {
        // If role has no capacity it doesn't matter (trying to assign the role would fail)
        hasLoaded = hasLoaded && !role?.has_remaining_capacity;
    }

    return (
        <RenderWithLoading hasLoaded={hasLoaded}>
            <AuthPageLayout
                title={RegistrationTitle}
                subTitle={<RegistrationSubtitle />}
            >
                {isInvitationValid && role.has_remaining_capacity && (
                    <OrganisationRegistrationForm
                        role={role}
                        invitation={invitation}
                    />
                )}

                {isInvitationValid && !role.has_remaining_capacity && (
                    <RegistrationLimitError />
                )}
            </AuthPageLayout>
        </RenderWithLoading>
    );
};

export default OrganisationRegistrationPage;
