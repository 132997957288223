import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons';
import {
    faFacebookSquare,
    faTwitterSquare,
    faLinkedin,
    faWhatsappSquare,
} from '@fortawesome/free-brands-svg-icons';
import usePortal from '../../utils/usePortal';
import LinkShare from '../generic/LinkShare';
import config from '../../consts/config';

interface Props {
    show: boolean;
    onClose: () => void;
    course: CourseShare;
}

interface SocialMediaShareProps {
    icon: any;
    name: string;
    href: string;
}

const SocialMediaShare: React.FC<SocialMediaShareProps> = ({
    icon,
    name,
    href,
}: SocialMediaShareProps) => {
    return (
        <a href={href} target='_blank' rel='noopener noreferrer'>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <FontAwesomeIcon icon={icon} size='5x' />
                {name}
            </div>
        </a>
    );
};

const ShareCertificateModal = ({ show, onClose, course }: Props) => {
    const target = usePortal('modal-root');

    return (
        <Modal
            show={show}
            onHide={onClose}
            container={target}
            style={{ minWidth: '80%' }}
            dialogClassName='mw-800-modal-dialog'
        >
            <Modal.Header>
                <Modal.Title>Share certificate</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: '80%', overflow: 'auto' }}>
                <Row style={{ justifyContent: 'space-between' }}>
                    <Col>
                        <SocialMediaShare
                            icon={faFacebookSquare}
                            name='Facebook'
                            href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                        />
                    </Col>
                    <Col>
                        <SocialMediaShare
                            icon={faLinkedin}
                            name='LinkedIn'
                            href={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`}
                        />
                    </Col>
                    <Col>
                        <SocialMediaShare
                            icon={faTwitterSquare}
                            name='Twitter'
                            href={`https://twitter.com/intent/tweet?text=I completed ${
                                course.title
                            }! Check out my certificate&hashtag=${config.REACT_APP_BRANDING_ORGANISATION?.replace(
                                /\\s/g,
                                '',
                            )}&via=recourseai&url=${window.location.href}`}
                        />
                    </Col>
                    <Col>
                        <SocialMediaShare
                            icon={faWhatsappSquare}
                            name='WhatsApp'
                            href={`https://web.whatsapp.com/send?text=I completed ${course.title} on ${config.REACT_APP_BRANDING_ORGANISATION}! Check out my certificate ${window.location.href}`}
                        />
                    </Col>
                    <Col>
                        <SocialMediaShare
                            icon={faEnvelopeSquare}
                            name='Email'
                            href={`mailto:?Subject=I completed ${course.title}&body=I recently completed ${course.title} on ${config.REACT_APP_BRANDING_ORGANISATION}! Check out my certificate here ${window.location.href}`}
                        />
                    </Col>
                </Row>
                <LinkShare link={window.location.href} className='mt-5' />
            </Modal.Body>
        </Modal>
    );
};

export default ShareCertificateModal;
