import * as React from 'react';
import { Fragment, useRef } from 'react';
import {
    Box,
    Flex,
    HStack,
    Spacer,
    useBreakpointValue,
    VStack,
} from '@chakra-ui/react';
import AvatarInfo from '../../molecules/AvatarInfo/AvatarInfo';
import ChatHistory from '../../molecules/ChatHistory/ChatHistory';
import MessageInput from '../../organisms/MessageInput/MessageInput';
import {
    InteractionContextAPI,
    InteractionContextData,
    useInteractionContext,
} from '../../utils/interaction/InteractionContext';
import { getChatStyles, scrollBarStyles } from '../../theme/consts';
import Hint from '../../atoms/Hint/Hint';
import { isDesktop } from 'react-device-detect';
import { useStoreWithArray } from '../../stores';
import useChatScroll from '../../hooks/useChatScroll';

export enum UserMessageSource {
    TEXT = 'text',
    DIALOGUE_CHOICE = 'dialogue_choice',
    BROWSER_RECOGNISED_SPEECH = 'browser_recognised_speech',
    CLOUD_RECOGNISED_SPEECH = 'cloud_recognised_speech',
    HINT = 'hint',
}

export interface Props {
    title: string;
    showChatHistory?: boolean;
    onCitationClick: (citation: Citation) => void;
}

const Chat = ({ title, showChatHistory = true, onCitationClick }: Props) => {
    const { messages, hints } = useInteractionContext(InteractionContextData);
    const { sendMessage, activeStage } = useInteractionContext(
        InteractionContextAPI,
    );

    const containerRef = useRef<HTMLDivElement>(null);
    const innerContainerRef = useRef<HTMLDivElement>(null);

    const isLargeDesktop = useBreakpointValue({ '2xl': true });

    const { isTextMode } = useStoreWithArray(['isTextMode']);
    const styles = getChatStyles(isTextMode);

    const avatarImageSrc = activeStage.interaction_stage.avatar_img || '';

    // Limit the number of hints when the space is limited so that there is no overflow
    const displayHints = hints.slice(
        0,
        isDesktop && (!isTextMode || !isLargeDesktop) ? 3 : hints.length,
    );

    const hasMessages = messages.length > 0;

    useChatScroll(messages, containerRef, innerContainerRef, showChatHistory);

    return (
        <Flex direction='column' {...styles.chatContainer}>
            {hasMessages ? (
                <Fragment>
                    <Spacer />
                    <Box
                        overflowY='auto'
                        ref={containerRef}
                        sx={scrollBarStyles}
                    >
                        {showChatHistory && (
                            <ChatHistory
                                messages={messages}
                                avatarImageSrc={avatarImageSrc}
                                isTextMode={isTextMode}
                                onCitationClick={onCitationClick}
                                innerContainerRef={innerContainerRef}
                            />
                        )}
                    </Box>
                </Fragment>
            ) : (
                <AvatarInfo
                    imageSrc={avatarImageSrc}
                    title={title}
                    textColor={isTextMode ? 'whiteAlpha.900' : 'blackAlpha.900'}
                />
            )}
            <VStack mx='auto' {...styles.historyContainer}>
                <HStack
                    w='100%'
                    mt='25px'
                    overflowX='auto'
                    justifyContent={
                        isTextMode ? { base: 'start', md: 'center' } : 'center'
                    }
                >
                    {displayHints.map(hint => (
                        <Hint
                            key={hint}
                            text={hint}
                            onClick={hint => {
                                sendMessage(hint, {
                                    message_source: UserMessageSource.HINT,
                                });
                            }}
                        />
                    ))}
                </HStack>
                <MessageInput
                    onSubmit={message => {
                        sendMessage(message, {
                            message_source: UserMessageSource.TEXT,
                        });
                    }}
                />
            </VStack>
        </Flex>
    );
};

export default Chat;
