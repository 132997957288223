import React, { Fragment } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory } from 'react-router-dom';

interface Props {
    pageName: string;
    pageUrl: string;
}

const BackToNavButtons: React.FC<Props> = ({ pageName, pageUrl }) => {
    const history = useHistory();

    return (
        <Fragment>
            <FontAwesomeIcon
                icon={faChevronLeft}
                className='mr-2'
                onClick={(): void => history.goBack()}
                style={{
                    cursor: 'pointer',
                    color: 'var(--primary)',
                }}
            />
            <Button
                variant='link'
                as={Link}
                to={pageUrl}
                style={{
                    cursor: 'pointer',
                    paddingLeft: 0,
                    paddingTop: 0,
                    paddingBottom: 5,
                    color: 'var(--primary)',
                    textDecoration: 'none',
                }}
            >
                Back to {pageName}
            </Button>
        </Fragment>
    );
};

export default BackToNavButtons;
