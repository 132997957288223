import React from 'react';
import usePortal from '../../utils/usePortal';
import { Modal, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import CentredComponent from '../../hocs/CentredComponent';
import ROUTES from '../../consts/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileMedical, faFile } from '@fortawesome/free-solid-svg-icons';
import * as queryString from 'querystring';

interface Props {
    show: boolean;
    onClose: () => void;
    caseFormQueryParams?: any;
    required?: boolean;
}

const CaseContentTypeModal: React.FC<Props> = ({
    show,
    onClose,
    caseFormQueryParams,
    required = false,
}) => {
    const target = usePortal('modal-root');
    const history = useHistory();

    return (
        <Modal
            show={show}
            onHide={onClose}
            container={target}
            size='lg'
            backdrop={required ? 'static' : true}
        >
            <Modal.Body className='py-5'>
                <CentredComponent
                    component={
                        <h3 className='h3 mt-3'>
                            What type of case do you want to upload?
                        </h3>
                    }
                />
                <Row className='mt-5 align-items-center justify-content-around'>
                    <style>
                        {`.type-option {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: around;
              border-radius: 10px;
              cursor: pointer;
              padding-top: 0.3rem;
              padding-bottom: 0.3rem;
            }
            .type-option:hover {
              background-color: rgba(0, 0, 0, 0.1);
            }`}
                    </style>
                    <Col
                        xs='auto'
                        className='type-option'
                        onClick={() =>
                            history.push(
                                `/${ROUTES.UPLOAD_CASE}?${queryString.stringify(
                                    {
                                        caseType: 'consultation_case' as Case['case_type'],
                                        ...caseFormQueryParams,
                                    },
                                )}`,
                            )
                        }
                    >
                        <FontAwesomeIcon icon={faFileMedical} size='8x' />
                        <h4 className='h4 pt-4'>Consultation</h4>
                    </Col>
                    <Col
                        xs='auto'
                        className='type-option'
                        onClick={() =>
                            history.push(
                                `/${ROUTES.UPLOAD_CASE}?${queryString.stringify(
                                    {
                                        caseType: 'other_case' as Case['case_type'],
                                        ...caseFormQueryParams,
                                    },
                                )}`,
                            )
                        }
                    >
                        <FontAwesomeIcon icon={faFile} size='8x' />
                        <h4 className='h4 pt-4'>Other</h4>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default CaseContentTypeModal;
