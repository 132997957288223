import React from 'react';
import { Row, Button, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const PageNotFoundPageContent: React.FC = () => {
    const history = useHistory();

    return (
        <Row className='px-4'>
            <Col></Col>
            <Col
                xs={12}
                lg={6}
                style={{
                    paddingTop: 'calc(100vh/3)',
                    paddingBottom: 'calc(100vh/3)',
                }}
            >
                <h3 className='h3'>
                    {"Sorry, the page you're looking for could not be found."}
                </h3>
                <p>
                    Click{' '}
                    <Button
                        onClick={(): void => {
                            history.push('/');
                        }}
                        className='text-primary'
                        variant='link'
                        style={{
                            cursor: 'pointer',
                            padding: 0,
                            verticalAlign: 'top',
                            border: 0,
                        }}
                    >
                        here
                    </Button>{' '}
                    to go the home page.
                </p>
            </Col>
            <Col></Col>
        </Row>
    );
};

export default PageNotFoundPageContent;
