import React from 'react';
import usePortal from '../../utils/usePortal';
import { Modal, ProgressBar } from 'react-bootstrap';
import CentredComponent from '../../hocs/CentredComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

interface Props {
    show: boolean;
    onClose: () => void;
    uploadPercentage: number | undefined;
}

const UploadProgressModal: React.FC<Props> = ({
    show,
    onClose,
    uploadPercentage,
}) => {
    const target = usePortal('modal-root');

    return (
        <Modal
            show={show}
            onHide={onClose}
            container={target}
            size='lg'
            backdrop='static'
        >
            <Modal.Body className='py-5'>
                <CentredComponent
                    className='my-4'
                    component={<h2 className='h2'>Uploading...</h2>}
                />

                <div
                    className='d-flex flex-column px-4 my-4'
                    style={{ width: '100%' }}
                >
                    <ProgressBar animated striped now={uploadPercentage} />
                    <p className='align-self-end mb-0'>{uploadPercentage}%</p>
                </div>

                <CentredComponent>
                    <p style={{ color: 'grey' }}>
                        <FontAwesomeIcon icon={faExclamationCircle} /> Do not
                        refresh or close this page while uploading
                    </p>
                </CentredComponent>
            </Modal.Body>
        </Modal>
    );
};

export default UploadProgressModal;
