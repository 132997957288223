import { convertFromRaw, Editor, EditorState } from 'draft-js';
import React, { ReactElement, useMemo } from 'react';
import { ModalProps } from './types';
import { ModalWithNoInitialFocus } from '@recourseai/components/src/atoms/ModalWithNoInitialFocus/ModalWithNoInitialFocus';
import {
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    ModalBody,
    Heading,
    Center,
} from '@chakra-ui/react';

interface Props extends ModalProps {
    title: string | ReactElement;
    rawContent: WritingActivity['content'];
}

const ViewWritingModal: React.FC<Props> = ({
    title,
    rawContent,
    show,
    onClose,
}) => {
    const content = useMemo(
        () => EditorState.createWithContent(convertFromRaw(rawContent)),
        [rawContent],
    );

    return (
        <ModalWithNoInitialFocus isOpen={show} onClose={onClose} size='3xl'>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader borderBottom='1px solid var(--chakra-colors-brand-black-300)'>
                    <Center>
                        <Heading fontSize='lg'>{title}</Heading>
                    </Center>
                    <ModalCloseButton />
                </ModalHeader>
                <ModalBody
                    style={{
                        maxHeight: '85vh',
                        overflow: 'auto',
                    }}
                    tabIndex={0}
                    aria-label='writing-content'
                    role='group'
                >
                    <Editor
                        editorState={content}
                        readOnly={true}
                        onChange={() => {
                            /* EMPTY */
                        }}
                    />
                </ModalBody>
            </ModalContent>
        </ModalWithNoInitialFocus>
    );
};

export default ViewWritingModal;
