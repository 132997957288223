import React, { Fragment, useState, useRef, useContext } from 'react';
import { Row, Col, Dropdown, Table } from 'react-bootstrap';
import { moocAPI } from '../../services';
import { useHistory } from 'react-router-dom';
import ROUTES from '../../consts/routes';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { getPrettyDateFormat } from '../../utils/dateUtils';
import IconButton from '../generic/IconButton';
import ReactPlayer from 'react-player/file';
import WorkflowAnnotations from './WorkflowAnnotations';
import EditCaseForm from '../forms/EditCaseForm';
import WithTooltip from '../generic/WithTooltip';
import DeleteConfirmationModal from '../modals/DeleteConfirmationModal';
import useAsyncError from '../../hooks/useAsyncError';
import BackToNavButtons from '../generic/BackToNavButtons';
import AllowedActionsContext from '../contexts/AllowedActionsContext';

const CaseOverview: React.FC<{
    portfolioCase: Case;
    setPortfolioCase: (portfolioCase: Case) => void;
}> = ({ portfolioCase, setPortfolioCase }) => {
    const playerRef = useRef<ReactPlayer>(null);
    const throwAsyncError = useAsyncError();
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [currentPlaybackTime, setCurrentPlayBackTime] = useState<number>(0);
    const history = useHistory();
    const userPermissions = useContext(AllowedActionsContext);
    const title_extra = (consultation_type => {
        switch (consultation_type) {
            case 'real':
                return 'Real consultation';
            case 'simulated':
                return 'Simulated consultation';
            default:
                return undefined;
        }
    })(portfolioCase.consultation_type);

    return (
        <Fragment>
            <Row>
                <Col>
                    <BackToNavButtons
                        pageName='teams'
                        pageUrl={`/${ROUTES.PORTFOLIO}`}
                    />
                </Col>
            </Row>
            <Row className='mt-5'>
                <Col md={8} className='d-flex flex-column overflow-auto'>
                    <div
                        className={
                            portfolioCase.file_type === 'video'
                                ? 'container-16-9'
                                : undefined
                        }
                        style={
                            portfolioCase.file_type === 'audio'
                                ? {
                                      height: 100,
                                  }
                                : undefined
                        }
                    >
                        <ReactPlayer
                            ref={playerRef}
                            controls
                            url={portfolioCase.file}
                            onProgress={({ playedSeconds }) =>
                                setCurrentPlayBackTime(
                                    Math.floor(playedSeconds),
                                )
                            }
                            className={
                                portfolioCase.file_type === 'video'
                                    ? 'element-16-9'
                                    : undefined
                            }
                            style={
                                portfolioCase.file_type === 'video'
                                    ? {
                                          backgroundColor: 'black',
                                      }
                                    : undefined
                            }
                            width='100%'
                            height='100%'
                        />
                    </div>
                    {!isEditMode && (
                        <Fragment>
                            <Row className='mt-3 align-items-center'>
                                <Col>
                                    <h3 className='h3 d-inline mr-2'>
                                        {portfolioCase.title}
                                    </h3>
                                    {title_extra ? `(${title_extra})` : null}
                                </Col>
                                <Col className='ml-auto d-flex flex-grow-0'>
                                    {userPermissions.includes('edit_case') && (
                                        <WithTooltip
                                            hoverMessage='Edit'
                                            side='bottom'
                                        >
                                            <IconButton
                                                iconProps={{ icon: faEdit }}
                                                onClick={() =>
                                                    setIsEditMode(true)
                                                }
                                            />
                                        </WithTooltip>
                                    )}
                                    {userPermissions.includes(
                                        'delete_case',
                                    ) && (
                                        <WithTooltip
                                            hoverMessage='Delete'
                                            side='bottom'
                                        >
                                            <IconButton
                                                iconProps={{ icon: faTrash }}
                                                onClick={() =>
                                                    setShowDeleteModal(true)
                                                }
                                            />
                                        </WithTooltip>
                                    )}
                                </Col>
                            </Row>
                            <Row className='pl-2 mt-3'>
                                <Col xs='auto'>
                                    {portfolioCase.owner_details.name}
                                </Col>
                                •
                                <Col xs='auto' className='text-muted'>
                                    {getPrettyDateFormat(
                                        portfolioCase.created_at,
                                    )}
                                </Col>
                            </Row>
                            <Dropdown.Divider
                                style={{ borderTopWidth: '2px' }}
                            />
                            {!!title_extra /* Consultation is eiter real or simulated => it has chief_complaint & diagnosis */ && (
                                <Table className='my-3' size='sm' borderless>
                                    <thead>
                                        <tr>
                                            <th className='text-center'>
                                                Chief complaint
                                            </th>
                                            <th className='text-center'>
                                                Diagnosis
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='text-center'>
                                                {portfolioCase.chief_complaint}
                                            </td>
                                            <td className='text-center'>
                                                {portfolioCase.diagnosis}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            )}
                            <p>{portfolioCase.description}</p>
                            {/* <div dangerouslySetInnerHTML={
                { __html: portfolioCase.description }
              }>
              </div> */}
                        </Fragment>
                    )}
                    {isEditMode && (
                        <EditCaseForm
                            caseId={portfolioCase.id}
                            setCase={setPortfolioCase}
                            caseType={portfolioCase.case_type}
                            titleInitial={portfolioCase.title}
                            descriptionInitial={portfolioCase.description}
                            chiefComplaintInitial={
                                portfolioCase.chief_complaint
                            }
                            diagnosisInitial={portfolioCase.diagnosis}
                            onCancel={() => setIsEditMode(false)}
                        />
                    )}
                </Col>
                <Col md={4}>
                    <WorkflowAnnotations
                        caseId={portfolioCase.id}
                        annotationsInitial={portfolioCase.annotations}
                        seekTo={seconds => {
                            if (playerRef.current) {
                                playerRef.current.seekTo(seconds, 'seconds');
                            }
                        }}
                        currentTime={currentPlaybackTime}
                    />
                </Col>
            </Row>
            <DeleteConfirmationModal
                show={showDeleteModal}
                resourceName={portfolioCase.title}
                message='Deleting a case with all its annotations is irreversible!'
                onClose={() => setShowDeleteModal(false)}
                onDelete={() => {
                    moocAPI
                        .delete(`portfolio/case/${portfolioCase.id}/`)
                        .then(res => {
                            history.push(`/${ROUTES.PORTFOLIO}`);
                        })
                        .catch(err => throwAsyncError(err));
                }}
            />
        </Fragment>
    );
};

export default CaseOverview;
