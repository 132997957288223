import React from 'react';
import { FormikValues } from 'formik';
import { authService, moocAPI } from '../../services';
import BaseRegistrationForm from './BaseRegistrationForm';
import { normalizeEmail } from '../../utils/stringUtils';
import { useHistory } from 'react-router-dom';
import ROUTES from '../../consts/routes';
import { useMixpanel } from '../contexts/MixpanelContext';

interface Props {
    invitation: string;
    role: OrganisationRole;
}

const OrganisationRegistrationForm: React.FC<Props> = ({
    role,
    invitation,
}) => {
    const role_name = role.name.toLowerCase();
    const history = useHistory();
    const mixpanel = useMixpanel();

    const onSubmit = (
        values: FormikValues,
        onError: (errorMessage?: string) => void,
    ): void => {
        authService
            .register(
                moocAPI,
                values.firstName,
                values.lastName,
                normalizeEmail(values.registerEmail),
                values.registerPassword,
                invitation,
            )
            .then(loginSuccessful => {
                mixpanel?.track('organisation_registration');
                if (loginSuccessful) {
                    history.push(ROUTES.HOME);
                }
            })
            .catch((error: Error) => {
                onError(error.toString());
            });
    };

    return <BaseRegistrationForm onSubmit={onSubmit} isPurchasing={false} />;
};

export default OrganisationRegistrationForm;
