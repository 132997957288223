import React, { Fragment, useState, useContext } from 'react';
import { Row, Col, Button, Modal, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faLink,
    faUserFriends,
    faMinus,
} from '@fortawesome/free-solid-svg-icons';
import LinkShare from '../generic/LinkShare';
import IconButton from '../generic/IconButton';
import usePortal from '../../utils/usePortal';
import AllowedActionsContext from '../contexts/AllowedActionsContext';

interface InvitationLinkDisplayProps {
    invitationLink: string;
    onMakePrivate: () => void;
}

const InvitationLinkDisplay: React.FC<InvitationLinkDisplayProps> = ({
    invitationLink,
    onMakePrivate,
}) => {
    const userPermissions = useContext(AllowedActionsContext);
    return (
        <Fragment>
            <Row>
                <Col>
                    <h2 className='h2'>
                        <FontAwesomeIcon icon={faLink} /> Invitation Link
                    </h2>
                </Col>
                {userPermissions.includes('make_private_group') && (
                    <Col>
                        <Button
                            variant='outline-primary'
                            onClick={onMakePrivate}
                        >
                            Make private
                        </Button>
                    </Col>
                )}
            </Row>
            <Row>
                <LinkShare
                    className='mt-3'
                    link={invitationLink}
                    text='Share this with people you wish to add to this group'
                />
            </Row>
        </Fragment>
    );
};

interface ConfirmProps {
    onDecline: () => void;
    onConfirm: () => void;
}

const ConfirmMakePrivate: React.FC<ConfirmProps> = ({
    onDecline,
    onConfirm,
}) => (
    <Fragment>
        <Row>
            <h2 className='h2'>Make group private?</h2>
        </Row>
        <Row className='mt-3'>
            <p className='font-weight-bold text-danger'>
                This action is irreversible
            </p>
            <p>
                Making the group private will remove everyone in the group and
                transfer all cases to its owner&apos;s private space.
            </p>
        </Row>

        <Row className='justify-content-around mt-3'>
            <Button variant='light' onClick={onDecline}>
                No
            </Button>
            <Button variant='danger' onClick={onConfirm}>
                Yes
            </Button>
        </Row>
    </Fragment>
);

const ConfirmLeave: React.FC<ConfirmProps> = ({ onDecline, onConfirm }) => (
    <Fragment>
        <Row>
            <h2 className='h2'>Leave group?</h2>
        </Row>
        <Row className='mt-3'>
            <p className='font-weight-bold text-danger'>
                This action is irreversible
            </p>
            <p>
                Your cases will be transferred to your &apos;Private cases&apos;
                and everyone else will lose access to them.
            </p>
        </Row>

        <Row className='justify-content-around mt-3'>
            <Button variant='light' onClick={onDecline}>
                No
            </Button>
            <Button variant='danger' onClick={onConfirm}>
                Yes
            </Button>
        </Row>
    </Fragment>
);

interface GroupMembersDisplayProps {
    members?: PortfolioGroupMember[];
    onDeleteMember: (memberId: number) => void;
    onLeave: () => void;
}

const GroupMembersDisplay: React.FC<GroupMembersDisplayProps> = ({
    members,
    onDeleteMember,
    onLeave,
}) => {
    const [memberHovered, setMemberHovered] = useState<number | undefined>(
        undefined,
    );
    const userPermissions = useContext(AllowedActionsContext);
    if (members === undefined) return <Fragment />;
    return (
        <Fragment>
            <Row>
                <Col>
                    <h2 className='h2'>
                        <FontAwesomeIcon icon={faUserFriends} /> Group members
                    </h2>
                </Col>
                {userPermissions.includes('leave_group') && (
                    <Col>
                        <Button variant='outline-primary' onClick={onLeave}>
                            Leave
                        </Button>
                    </Col>
                )}
            </Row>
            <Row className='mt-3'>
                <Table hover>
                    <thead className='d-block'>
                        <tr className='d-flex'>
                            <th style={{ flex: '1 0 auto' }}>Name</th>
                            <th style={{ flex: '1 1 auto' }}>Email</th>
                        </tr>
                    </thead>
                    <tbody
                        className='d-block overflow-auto'
                        style={{ maxHeight: 'calc(100vh - 28rem)' }}
                    >
                        {members.map((member, index) => {
                            const isHovered = memberHovered === member.user.id;
                            return (
                                <tr
                                    key={index}
                                    className='d-flex'
                                    style={{ position: 'relative' }}
                                    onMouseEnter={() =>
                                        setMemberHovered(member.user.id)
                                    }
                                    onMouseLeave={() =>
                                        setMemberHovered(undefined)
                                    }
                                >
                                    <td style={{ flex: '1 0 auto' }}>
                                        {member.user.name}
                                    </td>
                                    <td style={{ flex: '1 1 auto' }}>
                                        {member.user.email}
                                    </td>
                                    {userPermissions.includes(
                                        'delete_members',
                                    ) && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: '25%',
                                                right: '0',
                                            }}
                                            className={
                                                isHovered
                                                    ? 'd-flex justify-content-end'
                                                    : 'd-none'
                                            }
                                        >
                                            <IconButton
                                                iconProps={{ icon: faMinus }}
                                                onClick={() =>
                                                    onDeleteMember(
                                                        memberHovered!,
                                                    )
                                                }
                                            />
                                        </div>
                                    )}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Row>
        </Fragment>
    );
};

interface Props extends GroupMembersDisplayProps, InvitationLinkDisplayProps {
    open: boolean;
    onClose: () => void;
}
const PortfolioGroupMembersModal: React.FC<Props> = ({
    open,
    invitationLink,
    members,
    onMakePrivate: onMakePrivateConfirm,
    onLeave: onLeaveConfirm,
    onDeleteMember,
    onClose,
}) => {
    const target = usePortal('modal-root');
    const [upperModal, setUpperModal] = useState<'link' | 'confirm-private'>(
        'link',
    );
    const [secondModal, setSecondModal] = useState<'members' | 'confirm-leave'>(
        'members',
    );
    const userPermissions = useContext(AllowedActionsContext);
    return (
        <Modal
            className='nested-modals'
            show={open}
            onHide={onClose}
            container={target}
        >
            <style>{`
                            .nested-modals .modal-dialog {
            max-width: 800px;
                            }
        `}</style>
            {userPermissions.includes('add_members') && (
                <div className='modal-content px-5 py-4'>
                    {upperModal === 'link' && (
                        <InvitationLinkDisplay
                            invitationLink={invitationLink}
                            onMakePrivate={() =>
                                setUpperModal('confirm-private')
                            }
                        />
                    )}
                    {upperModal === 'confirm-private' && (
                        <ConfirmMakePrivate
                            onConfirm={() => {
                                onMakePrivateConfirm();
                                setUpperModal('link');
                            }}
                            onDecline={() => setUpperModal('link')}
                        />
                    )}
                </div>
            )}

            <div className='modal-content px-5 py-4 mt-4'>
                {secondModal === 'members' && (
                    <GroupMembersDisplay
                        members={members}
                        onDeleteMember={onDeleteMember}
                        onLeave={() => setSecondModal('confirm-leave')}
                    />
                )}
                {secondModal === 'confirm-leave' && (
                    <ConfirmLeave
                        onConfirm={onLeaveConfirm}
                        onDecline={() => setSecondModal('members')}
                    />
                )}
            </div>
        </Modal>
    );
};

export default PortfolioGroupMembersModal;
