import React, { Fragment } from 'react';
import { HStack, Spacer, Text, Tooltip } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt, faClock } from '@fortawesome/free-solid-svg-icons';
import { ActivityIcon } from '../../../components/activities/ActivityIcon';

const ActivityHeading: React.FC<{
    activity: Activity;
    studentActivity?: StudentActivity | null;
}> = ({ activity, studentActivity }) => (
    <Fragment>
        <HStack spacing={1}>
            <ActivityIcon activity={activity} boxSize='25px' />
            <Text fontWeight='bold' mb={0}>
                {activity.title}
            </Text>
        </HStack>
        <Spacer />
        <HStack spacing={3} fontSize='sm' cursor='default'>
            {/*{activity.config.pass_threshold && (*/}
            {/*    <HStack spacing={1}>*/}
            {/*        <FontAwesomeIcon icon={faCheck} />*/}
            {/*        <span>{activity.config.pass_threshold}%</span>*/}
            {/*    </HStack>*/}
            {/*)}*/}
            {activity.config.total_attempt_limit && (
                <Tooltip placement='top' label='Attempts left'>
                    <HStack spacing={1}>
                        <FontAwesomeIcon
                            icon={faBolt}
                            aria-label='Attempts left'
                        />
                        <span>
                            {studentActivity?.total_attempts_remaining ??
                                activity.config.total_attempt_limit}
                            {` / ${activity.config.total_attempt_limit} `}
                        </span>
                    </HStack>
                </Tooltip>
            )}
            <Tooltip placement='top' label='Duration'>
                <HStack spacing={1}>
                    <FontAwesomeIcon icon={faClock} aria-label='Duration' />
                    <span>{activity.estimated_time_minutes}mins</span>
                </HStack>
            </Tooltip>
        </HStack>
    </Fragment>
);

export default ActivityHeading;
