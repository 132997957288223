import React, { Fragment, useState } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import CaseContentTypeModal from '../../modals/CaseContentTypeModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faVideo } from '@fortawesome/free-solid-svg-icons';

interface Props extends ButtonProps {
    caseFormQueryParams?: any;
}
const CreateCaseButton: React.FC<Props> = ({
    caseFormQueryParams,
    children,
    ...btnProps
}) => {
    const [showModal, setShowModal] = useState<boolean>(false);

    return (
        <Fragment>
            <Button
                size='lg'
                className='px-3'
                {...btnProps}
                onClick={() => setShowModal(true)}
            >
                {children}
            </Button>
            <CaseContentTypeModal
                show={showModal}
                caseFormQueryParams={caseFormQueryParams}
                onClose={() => setShowModal(false)}
            />
        </Fragment>
    );
};

const RecordCaseButton: React.FC<Props> = props => (
    <CreateCaseButton
        {...props}
        caseFormQueryParams={{
            ...props.caseFormQueryParams,
            creationMethod: 'RECORD',
        }}
    >
        <FontAwesomeIcon icon={faVideo} color='white' /> Record Case
    </CreateCaseButton>
);

const UploadCaseButton: React.FC<Props> = props => (
    <CreateCaseButton
        {...props}
        caseFormQueryParams={{
            ...props.caseFormQueryParams,
            creationMethod: 'UPLOAD',
        }}
    >
        <FontAwesomeIcon icon={faUpload} color='white' /> Upload Case
    </CreateCaseButton>
);

export default CreateCaseButton;
export { RecordCaseButton, UploadCaseButton };
