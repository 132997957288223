import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import useAsyncError from '../../hooks/useAsyncError';
import queryString from 'query-string';
import { moocAPI } from '../../services';
import CentredComponent from '../../hocs/CentredComponent';
import LoadingSpinner from '../generic/LoadingSpinner';

enum ResponseStatus {
    success,
    error,
    not_yet_processed,
}

const PaymentProcessingPageContent: React.FC = () => {
    const location = useLocation();
    const search = location.search;
    const params = queryString.parse(search);

    const history = useHistory();
    const timeoutTimer = useRef<any>(null);
    const pollInterval = useRef<any>(null);
    const throwAsyncError = useAsyncError();

    const [productId, setProductId] = useState<string | null>(null);
    const [responseStatus, setResponseStatus] = useState<string>();

    const poll = useCallback(() => {
        if (responseStatus === ResponseStatus[ResponseStatus.success]) {
            return;
        }

        if (!productId) {
            return;
        }

        moocAPI
            .get(
                `products/non_organisation_one_off_product/${productId}/purchase/confirmation`,
            )
            .then(res => {
                const status = res['status'];
                if (status === ResponseStatus[ResponseStatus.success]) {
                    const redirectUrl =
                        'redirect' in params
                            ? params['redirect']?.toString()
                            : '/';
                    setTimeout(() => {
                        history.push(redirectUrl ?? '/');
                    }, 1000);
                }

                if (status === ResponseStatus[ResponseStatus.error]) {
                    throwAsyncError(new Error('Order failed to process'));
                }

                setResponseStatus(status);
            })
            .catch(e => {
                throwAsyncError(e);
            });
    }, [history, params, productId, responseStatus, throwAsyncError]);

    useEffect(() => {
        timeoutTimer.current = setTimeout(() => {
            throwAsyncError(new Error('Purchase timed out'));
        }, 30000);

        return (): void => {
            clearTimeout(timeoutTimer.current);
        };
    }, [history, params, throwAsyncError]);

    useEffect(() => {
        if (!('product_id' in params)) {
            throwAsyncError(new Error('Invalid product id'));
        }

        setProductId(params['product_id']?.toString() ?? '');

        poll();
        pollInterval.current = setInterval(poll, 3000);

        return (): void => {
            clearInterval(pollInterval.current);
        };
    }, [location.search, location.state, params, poll, throwAsyncError]);

    if (responseStatus === ResponseStatus[ResponseStatus.success]) {
        return (
            <CentredComponent>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '80vh',
                    }}
                >
                    <p>
                        <b>Order successfully processed.</b>
                        <br />
                        Redirecting...
                    </p>
                </div>
            </CentredComponent>
        );
    }

    return (
        <CentredComponent>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '80vh',
                }}
            >
                <LoadingSpinner />
                <div style={{ paddingTop: 12, paddingLeft: 12 }}>
                    <p>Processing order...</p>
                </div>
            </div>
        </CentredComponent>
    );
};

export default PaymentProcessingPageContent;
