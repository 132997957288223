// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

function withDebugLog<
    T extends { info: () => any; isDebugMode: () => boolean }
>(target: T, key: any, descriptor: { value: (...args: any) => any }) {
    const originalMethod = descriptor.value; // Save the original method

    // Redefine the method with custom behavior
    descriptor.value = function(...args: any) {
        const className = target.constructor.name;

        if (target.isDebugMode.apply(this)) {
            console.log(
                `[${className}, ${new Date().toISOString()}] ${key} <- ${target.info.apply(
                    this,
                )}`,
            );
        }
        const result = originalMethod.apply(this, args);
        if (target.isDebugMode.apply(this)) {
            console.log(
                `[${className}, ${new Date().toISOString()}] ${key} -> ${target.info.apply(
                    this,
                )}`,
            );
        }
        return result;
    };
    return descriptor;
}

export type ExecutableOptions<StateType> = {
    onSchedule?: (state: StateType) => void;
    onAvailable?: (state: StateType) => void;
    onStarted?: (state: StateType) => void;
    onFinished?: (state: StateType) => void;
};

export class Executable<StateType> {
    public status: 'pending' | 'scheduled' | 'available' | 'running' | 'ended' =
        'pending';
    private options: ExecutableOptions<StateType>;
    private state: StateType = {};

    constructor(options: ExecutableOptions<StateType> = {}) {
        this.options = options;
    }

    public schedule() {
        this.status = 'scheduled';
        if (this.options.onSchedule) {
            this.options.onSchedule(this.state);
        }
    }
    public start() {
        this.status = 'running';
        if (this.options.onStarted) {
            this.options.onStarted(this.state);
        }
    }

    public makeAvailable() {
        this.status = 'available';
        if (this.options.onAvailable) {
            this.options.onAvailable(this.state);
        }
    }

    public finish() {
        this.status = 'ended';
        if (this.options.onFinished) {
            this.options.onFinished(this.state);
        }
    }
}

export class ExecutionQueue {
    private queue: Executable[] = [];
    private debug: boolean;

    constructor(debug = false) {
        this.debug = debug;
    }

    @withDebugLog
    public push(...items) {
        this.queue.push(...items);
        const hasScheduledItem = this.queue.some(
            item => item.status === 'scheduled' || item.status === 'available',
        );
        const hasRunningItem = this.queue.some(
            item => item.status === 'running',
        );

        // Avoid debugging inside potential calls
        const debug = this.debug;
        this.debug = false;

        if (!hasScheduledItem) {
            this.scheduleNext();
        }
        if (!hasRunningItem) {
            this.makeAvailable();
        }
        this.debug = debug;
    }

    @withDebugLog
    public scheduleNext() {
        const firstPending = this.queue.find(exec => exec.status === 'pending');
        firstPending?.schedule();
    }

    @withDebugLog
    public makeAvailable() {
        const firstScheduled = this.queue.find(
            exec => exec.status === 'scheduled',
        );
        firstScheduled?.makeAvailable();
    }

    @withDebugLog
    public startAvailable() {
        const firstAvailable = this.queue.find(
            exec => exec.status === 'available',
        );
        firstAvailable?.start();
    }

    @withDebugLog
    public finishRunningTask() {
        const running = this.queue.find(exec => exec.status === 'running');
        if (running) {
            running.finish();
            this.queue.shift();
        }
    }

    @withDebugLog
    public clear() {
        this.queue.forEach(item => item.finish());
        this.queue = [];
    }

    public size() {
        return this.queue.length;
    }

    public info() {
        return JSON.stringify(this.queue.map(e => ({ status: e.status })));
    }

    public isDebugMode() {
        return this.debug;
    }
}
