// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function capitalizeFirstLetter(string) {
    if (!string) {
        return '';
    }

    return string[0].toUpperCase() + string.slice(1);
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function normalizeEmail(email) {
    const emailSplit = email.split('@');
    return emailSplit[0] + '@' + emailSplit[1].toLowerCase();
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function truncateText(text, n_words, ellipsis = true) {
    const truncated = text
        .split(' ')
        .splice(0, n_words)
        .join(' ');
    return ellipsis && truncated.length < text.length
        ? truncated + '...'
        : truncated;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function truncateContinuousText(str, len) {
    if (str.length <= len) {
        return str;
    }
    return str.substr(0, len) + '...';
}

exports.truncateContinuousText = truncateContinuousText;
exports.truncateText = truncateText;
exports.capitalizeFirstLetter = capitalizeFirstLetter;
exports.normalizeEmail = normalizeEmail;
