export const isExercise = (activity: Activity): boolean => {
    return activity.type === 'exercise';
};

export const isVideo = (activity: Activity): boolean => {
    return activity.type === 'video';
};

export const isReading = (activity: Activity): boolean => {
    return activity.type === 'reading';
};

export const isWritingAssignment = (activity: Activity): boolean => {
    return activity.type === 'writing_assignment';
};
