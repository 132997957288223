import React, { Fragment } from 'react';
import {
    Heading,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react';
import StudentActivityFeedbackForm from '../forms/StudentActivityFeedbackForm';
import { ModalProps } from './types';
import { ModalWithNoInitialFocus } from '@recourseai/components/src/atoms/ModalWithNoInitialFocus/ModalWithNoInitialFocus';

interface Props extends ModalProps {
    show: boolean;
    onClose: () => void;
    onSubmit?: () => void;
    activity: Activity;
    context: any;
}

const StudentActivityFeedbackModal: React.FC<Props> = ({
    show,
    onClose,
    onSubmit,
    activity,
    context,
}) => {
    return (
        <Fragment>
            <ModalWithNoInitialFocus
                isOpen={show}
                onClose={onClose}
                closeOnOverlayClick={false}
                size='lg'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <Heading>How was your experience?</Heading>
                        <ModalCloseButton />
                    </ModalHeader>
                    <ModalBody>
                        <StudentActivityFeedbackForm
                            onSkip={onClose}
                            onSubmit={() => {
                                onSubmit && onSubmit();
                                onClose();
                            }}
                            activity={activity}
                            context={context}
                        />
                    </ModalBody>
                </ModalContent>
            </ModalWithNoInitialFocus>
        </Fragment>
    );
};

export default StudentActivityFeedbackModal;
