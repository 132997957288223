import React, { useCallback, useState } from 'react';

const lifoResize = (array: any[], size: number) => {
    return array.slice(-size);
};

const useArray = <T>(
    capacity = Infinity,
): [
    T[],
    React.Dispatch<React.SetStateAction<T[]>>,
    (...elements: T[]) => void,
    () => void,
] => {
    const [queue, _setQueue] = useState<T[]>([]);

    const setQueue: React.Dispatch<React.SetStateAction<T[]>> = useCallback(
        newData => {
            if (typeof newData === 'function') {
                _setQueue(currentQueue => {
                    const newQueue = newData(currentQueue);
                    return lifoResize(newQueue, capacity);
                });
            } else {
                _setQueue(lifoResize(newData, capacity));
            }
        },
        [capacity],
    );

    const add = useCallback(
        (...elements: T[]) => setQueue(current => [...current, ...elements]),
        [setQueue],
    );

    const clear = useCallback(() => _setQueue([]), []);

    return [queue, setQueue, add, clear];
};

export default useArray;
