import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow';
import {
    createInteractionSettingsSlice,
    InteractionSettingsStore,
} from './interaction-settings';
import { createUiSettingsSlice, ModalSetting } from './ui-settings';

type Store = InteractionSettingsStore & ModalSetting;
type StoreMember = keyof Store;

export const useStore = create<Store>((...a) => ({
    ...createInteractionSettingsSlice(...a),
    ...createUiSettingsSlice(...a),
}));

export const useStoreWithArray = <GenericMember extends StoreMember>(
    stateNames: GenericMember[],
): {
    [K in GenericMember]: Store[K];
} => {
    return useStore(
        useShallow(state => {
            return stateNames.reduce(
                (m, key) => ({
                    [key]: state[key],
                    ...m,
                }),
                {} as { [K in GenericMember]: Store[K] },
            );
        }),
    );
};
