import React, { useContext } from 'react';
import { Mixpanel } from 'mixpanel-browser';

const MixpanelContext = React.createContext<Mixpanel | undefined>(undefined);
export default MixpanelContext;

export const MixpanelProvider: React.FC<{ mixpanel?: Mixpanel }> = ({
    mixpanel,
    children,
}) => (
    <MixpanelContext.Provider value={mixpanel}>
        {children}
    </MixpanelContext.Provider>
);

export const useMixpanel = (): Mixpanel | undefined => {
    return useContext(MixpanelContext);
};

export const MixpanelConsumer = MixpanelContext.Consumer;
