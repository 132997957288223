import * as React from 'react';
import { Global } from '@emotion/react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TelegrafRegular from '../fonts/PPTelegraf-Regular.woff2';

const Fonts = () => (
    <Global
        styles={`
          @font-face {
            font-family: 'Telegraf';
            font-style: normal;
            font-weight: 400;
            src: local('Telegraf-Regular'), url(${TelegrafRegular}) format('opentype');
          }
          @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
          `}
    />
);

export default Fonts;
