import { Box, BoxProps, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { getFps } from '../../../../apps/mooc-frontend/src/components/activities/consultation/utils/frameRate';
import * as React from 'react';

const FPSDisplay = (props: BoxProps) => {
    const [fps, setFps] = useState(0);

    useEffect(() => {
        const result = getFps(setFps);
        return () => result.stop();
    }, []);

    return (
        <Box backgroundColor='black' {...props}>
            <Text color='green.400' paddingX={1} paddingY={0} m={0}>
                {fps}
            </Text>
        </Box>
    );
};

export default FPSDisplay;
