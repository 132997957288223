import React from 'react';
import {
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormErrorMessage,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Textarea,
    Text,
} from '@chakra-ui/react';
import { Form, Formik, FormikValues } from 'formik';
import * as Yup from 'yup';
import { checkBoxStyles } from '../../theme/consts';
import { ModalWithNoInitialFocus } from '../../atoms/ModalWithNoInitialFocus/ModalWithNoInitialFocus';

const validationSchema = Yup.object().shape({
    categories: Yup.object().test(
        'at-least-one-checked',
        'At least one category must be selected',
        value => Object.values(value).some(val => val === true),
    ),
    message: Yup.string().nullable(),
});

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (
        type: FeedbackPayload['type'],
        categories: string[],
        message: string,
    ) => Promise<void>;
}
const ACTION_FEEDBACK_CATEGORIES = {
    'inaccurate-not-true': 'Inaccurate (not true)',
    'harmful-or-offensive': 'Harmful or offensive',
    'inaccurate-sources': 'Inaccurate sources',
    'out-of-date': 'Out of date',
    'incomplete-too-short': 'Incomplete (too short)',
    'redundant-too-long': 'Redundant (too long)',
    'not-helpful': 'Not helpful',
    'do-not-like-the-style': 'Do not like the style',
} as const;

type CategoryKey = keyof typeof ACTION_FEEDBACK_CATEGORIES;

const categories = Object.fromEntries(
    Object.keys(ACTION_FEEDBACK_CATEGORIES).map(key => [key, false]),
) as Record<CategoryKey, boolean>;

export default function AgentActionFeedbackModal({
    isOpen,
    onClose,
    onSubmit,
}: Props) {
    const initialValues = {
        message: '',
        categories,
    };

    const formSubmit = async (values: FormikValues) => {
        const selectedCategories = Object.keys(values.categories).filter(
            key => values.categories[key],
        );

        await onSubmit('negative', selectedCategories, values.message);
    };

    return (
        <ModalWithNoInitialFocus isOpen={isOpen} onClose={onClose} size='xl'>
            <ModalOverlay />
            <ModalContent
                backgroundColor='dark.900'
                border='2px solid'
                borderColor='dark.500'
                color='white'
            >
                <ModalHeader>Provide additional feedback</ModalHeader>
                <ModalCloseButton
                    color='dark.400'
                    _hover={{
                        backgroundColor: 'dark.480',
                        border: '1px solid',
                        borderColor: 'dark.430',
                    }}
                />
                <ModalBody>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={formSubmit}
                        validationSchema={validationSchema}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            isValid,
                        }): JSX.Element => {
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <FormControl
                                        isInvalid={!!errors.categories}
                                        mb={2}
                                    >
                                        <Flex
                                            wrap='wrap'
                                            gap='10px'
                                            justifyContent='space-between'
                                        >
                                            {Object.keys(
                                                ACTION_FEEDBACK_CATEGORIES,
                                            ).map(key => (
                                                <Checkbox
                                                    key={key}
                                                    name={`categories.${key}`}
                                                    sx={checkBoxStyles}
                                                    isChecked={
                                                        values.categories[
                                                            key as CategoryKey
                                                        ]
                                                    }
                                                    onChange={handleChange}
                                                >
                                                    <Flex alignItems='center'>
                                                        <Text m='0px'>
                                                            {
                                                                ACTION_FEEDBACK_CATEGORIES[
                                                                    key as CategoryKey
                                                                ]
                                                            }
                                                        </Text>
                                                    </Flex>
                                                </Checkbox>
                                            ))}
                                        </Flex>
                                        <FormErrorMessage>
                                            {errors.categories}
                                        </FormErrorMessage>
                                    </FormControl>

                                    <FormControl
                                        isInvalid={!!errors.message}
                                        mb={2}
                                    >
                                        <Text m='0px'>
                                            How can the response be improved?
                                            (optional)
                                        </Text>
                                        <Textarea
                                            name='message'
                                            backgroundColor='dark.600'
                                            color='whiteAlpha.800'
                                            border='2px solid'
                                            borderColor='dark.500'
                                            _hover={{
                                                borderColor: 'dark.500',
                                            }}
                                            _focus={{
                                                borderColor: 'dark.400',
                                            }}
                                            placeholder='What would the ideal answer have been?'
                                            _placeholder={{
                                                color: 'whiteAlpha.500',
                                            }}
                                            value={values.message}
                                            onChange={handleChange}
                                        />
                                        <FormErrorMessage>
                                            {errors.message}
                                        </FormErrorMessage>
                                    </FormControl>
                                    <Flex justifyContent='flex-end'>
                                        <Button
                                            backgroundColor='white'
                                            color='black'
                                            _hover={{
                                                backgroundColor: 'light.300',
                                            }}
                                            isLoading={isSubmitting}
                                            disabled={!isValid || isSubmitting}
                                            type='submit'
                                        >
                                            Submit
                                        </Button>
                                    </Flex>
                                </Form>
                            );
                        }}
                    </Formik>
                </ModalBody>
            </ModalContent>
        </ModalWithNoInitialFocus>
    );
}
