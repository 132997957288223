import * as React from 'react';
import { Box, IconButton, Tooltip } from '@chakra-ui/react';
import { Icon } from '../../atoms';
import { IconProps } from '../../atoms/Icon/Icon';
import { navbarToggleStyles } from '../../theme/consts';
import IconSlash from '../../atoms/IconSlash/IconSlash';

interface Props {
    name: string;
    icon: IconProps['iconChoice'];
    isToggle: boolean;
    onClick: () => void;
}

export default function NavbarToggle({ name, icon, isToggle, onClick }: Props) {
    return (
        <Box {...navbarToggleStyles.container}>
            <Tooltip label={`Toggle ${name} ${isToggle ? 'off' : 'on'}`}>
                <IconButton
                    aria-label={`Toggle ${name}`}
                    borderRadius='10px'
                    onClick={onClick}
                >
                    <Box position='relative'>
                        <Icon
                            iconChoice={icon}
                            fontAwesomeProps={{ size: 'lg' }}
                        />
                        {!isToggle && <IconSlash />}
                    </Box>
                </IconButton>
            </Tooltip>
        </Box>
    );
}
