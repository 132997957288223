import React from 'react';
import usePortal from '../../utils/usePortal';
import { Modal } from 'react-bootstrap';

interface Props {
    show: boolean;
    onClose: () => void;
}

const PatientIdentifiableInformationModal: React.FC<Props> = props => {
    const target = usePortal('modal-root');

    return (
        <Modal
            show={props.show}
            onHide={(): void => {
                props.onClose();
            }}
            container={target}
        >
            <Modal.Header closeButton>
                <Modal.Title>Patient Identifiable Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Please do not include the following information:
                <ul>
                    <li>
                        Any information that could identify a person, such as
                        <ul>
                            <li>Name</li>
                            <li>
                                Day and Month of birth, admission, discharge or
                                death
                            </li>
                            <li>
                                Age of patient (if older than 89, instead use
                                “90 or older”)
                            </li>
                            <li>Social security/National Insurance number</li>
                            <li>
                                Contact information (eg telephone number or
                                email address)
                            </li>
                            <li>Medical record numbers</li>
                            <li>Hospital ID (or NHS) number</li>
                            <li>Health plan beneficiary numbers</li>
                        </ul>
                    </li>
                    <li>
                        Account numbers
                        <ul>
                            <li>
                                Any ID or code used to re-identify the patient
                            </li>
                        </ul>
                    </li>
                    <li>
                        Any personal information as defined by the General Data
                        Protection Act of 2016 (GDPR), Protected Health
                        Information as defined in 45 CFR 160 and 164 of the
                        Health Insurance Protection and Accountability Act of
                        1996 (HIPAA){' '}
                    </li>
                    <li>
                        For more information please contact your organization’s
                        legal or privacy department
                    </li>
                </ul>
            </Modal.Body>
        </Modal>
    );
};

export default PatientIdentifiableInformationModal;
