import { useEffect, useRef } from 'react';

type ChangedProps<T> = {
    [K in keyof T]?: [T[K], T[K]]; // Tuple of [previousValue, currentValue]
};

/**
 * A debug hook that traces which props are causing re-renders.
 * Only intended for development use.
 * @param props - Component props to trace
 */
export function useTraceUpdate<T extends object>(props: T): void {
    const prev = useRef<T>(props);

    useEffect(() => {
        const changedProps = Object.entries(props).reduce<ChangedProps<T>>(
            (ps, [key, value]) => {
                const k = key as keyof T;
                if (prev.current[k] !== value) {
                    ps[k] = [prev.current[k], value];
                }
                return ps;
            },
            {},
        );

        if (Object.keys(changedProps).length > 0) {
            console.log('[useTraceUpdate]: Changed props:', changedProps);
        }

        prev.current = props;
    }, [props]);
}
