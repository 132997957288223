import React, { useMemo } from 'react';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Flex,
    Heading,
    Table,
    Tbody,
    Td,
    Tr,
} from '@chakra-ui/react';
import { ConversationStage, Item, Section } from './types';
import { InteractionStageFeedback } from '../types';

const FeedbackSection: React.FC<Section> = ({
    title,
    feedback,
    subsections,
}) => {
    const items = useMemo(() => {
        return subsections.reduce(
            (prev, current) => [...prev, ...current.items],
            [] as Item[],
        );
    }, [subsections]);

    return (
        <AccordionItem>
            <AccordionButton
                bg='brand.black.100'
                _hover={{ bg: 'brand.black.200' }}
            >
                <Flex flex='1' justifyContent='space-between' fontWeight='bold'>
                    <Box>{title}</Box>
                    <Box>{feedback.score}%</Box>
                </Flex>
                <AccordionIcon />
            </AccordionButton>
            <AccordionPanel py={0}>
                <Box>
                    <Table>
                        <Tbody>
                            {items.map((item, i) => (
                                <Tr key={i}>
                                    <Td
                                        px={0}
                                        py={1}
                                        color={
                                            item.feedback.matched
                                                ? 'green.500'
                                                : 'red.500'
                                        }
                                        width='65%'
                                    >
                                        {item.title}
                                    </Td>
                                    <Td px={0} py={2} textAlign='right'>
                                        {item.assessment_tag} (
                                        {item.assessment_tag_points.toFixed(1)})
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
            </AccordionPanel>
        </AccordionItem>
    );
};

const ConsultationFeedback: React.FC<InteractionStageFeedback<
    ConversationStage
>> = ({ marking_feedback }) => {
    if (!marking_feedback) return null;

    const { summary, sections } = marking_feedback;
    return (
        <Box>
            <Heading size='md' p={4} bg='black' color='white' roundedTop='15px'>
                <Flex justifyContent='space-between'>
                    <Box>Consultation coverage</Box>
                    <Box>{summary.score}%</Box>
                </Flex>
            </Heading>
            <Box>
                <Accordion defaultIndex={[-1]} allowMultiple>
                    {sections.map(s => (
                        <FeedbackSection key={s.title} {...s} />
                    ))}
                </Accordion>
            </Box>
        </Box>
    );
};

export default ConsultationFeedback;
