import React from 'react';
import { Button, ButtonProps, Popover, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import GroupForm from '../../forms/GroupForm';
import { FormikHelpers, FormikValues } from 'formik';

interface Props extends ButtonProps {
    onFormSubmit: (
        values: FormikValues,
        formikHelpers: FormikHelpers<any>,
    ) => void;
    placement: 'right' | 'left' | 'top' | 'bottom' | any;
}
const AddGroupButton: React.FC<Props> = ({
    onFormSubmit,
    placement,
    ...buttonProps
}) => {
    const groupNameInputPopover = (
        <Popover id='group-name-input-popover'>
            <Popover.Content>
                <GroupForm onSubmit={onFormSubmit} />
            </Popover.Content>
        </Popover>
    );

    return (
        <OverlayTrigger
            trigger='click'
            rootClose
            placement={placement}
            overlay={groupNameInputPopover}
        >
            <Button {...buttonProps}>
                <FontAwesomeIcon icon={faPlus} /> Add group
            </Button>
        </OverlayTrigger>
    );
};

export default AddGroupButton;
