import React, { Fragment, Children } from 'react';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap';

const ActionsDropdown: React.FC = ({ children }) => {
    const childrenArray = Children.toArray(children);
    const firstChild = childrenArray[0];
    let didAnyChildRender = false;
    if (
        firstChild instanceof Object &&
        'type' in firstChild &&
        firstChild.type === Fragment
    ) {
        const innerChildren = Children.toArray(firstChild.props.children);
        for (let i = 0; i < innerChildren.length; i++)
            didAnyChildRender = didAnyChildRender || !!innerChildren[i];
    } else {
        for (let i = 0; i < childrenArray.length; i++)
            didAnyChildRender = didAnyChildRender || !!childrenArray[i];
    }
    return (
        <Fragment>
            {/* Inline style hides dropdown arrow */}
            <style>
                {`
        .dropdown-toggle::after {
            display: none;
        }
        .dropdown-toggle.btn {
          color: inherit;
          background-color: transparent !important;
          border-color: transparent !important;
          box-shadow: unset !important;
        }
        .dropdown-toggle.btn:hover {
          color: var(--primary);
        }
        .show > .dropdown-toggle.btn {
          color: var(--primary);
        }
        .dropdown-item:active {
          color: inherit;
          background-color: transparent;
        }
        `}
            </style>
            <DropdownButton
                as={ButtonGroup}
                title={<FontAwesomeIcon icon={faEllipsisH} />}
                className={didAnyChildRender ? '' : 'd-none'}
                id='edit-delete-dropdown'
                variant='light'
            >
                {children}
            </DropdownButton>
        </Fragment>
    );
};

const Edit: React.FC<{ onEdit: () => void }> = ({ onEdit }) => (
    <Dropdown.Item onClick={onEdit}>Edit</Dropdown.Item>
);
const Delete: React.FC<{ onDelete: () => void }> = ({ onDelete }) => (
    <Dropdown.Item onClick={onDelete} className='text-danger'>
        Delete
    </Dropdown.Item>
);
const SeeMembers: React.FC<{ onSeeMembers: () => void }> = ({
    onSeeMembers,
}) => <Dropdown.Item onClick={onSeeMembers}>See Members</Dropdown.Item>;
const Share: React.FC<{ onShare: () => void }> = ({ onShare }) => (
    <Dropdown.Item onClick={onShare}>Share</Dropdown.Item>
);
const Leave: React.FC<{ onLeave: () => void }> = ({ onLeave }) => (
    <Dropdown.Item onClick={onLeave} className='text-danger'>
        Leave
    </Dropdown.Item>
);

export default ActionsDropdown;
export { Edit, Delete, SeeMembers, Share, Leave };
