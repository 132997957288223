import React from 'react';
import { Row, Col, Alert, Button } from 'react-bootstrap';
import { ContentWrapper } from '../../screens/BasePage';
import { Link } from 'react-router-dom';
import ROUTES from '../../consts/routes';
import { authService } from '../../services';
import config from '../../consts/config';

const InvalidStudentErrorPage: React.FC<{
    fullWidth?: boolean;
    onOpenReportForm?: () => void;
}> = ({ fullWidth, onOpenReportForm }) => {
    return (
        <ContentWrapper fullWidth={fullWidth ?? false}>
            <Row>
                <Col />
                <Col xs={12} lg={6} style={{ paddingTop: 'calc(100vh/3)' }}>
                    <Alert variant='danger'>
                        <Alert.Heading>
                            {
                                'Please contact your organisation admin to get an invitation to join.'
                            }
                        </Alert.Heading>
                        <p className='mb-0'>
                            {`
                                The user exists, but is not enrolled as a student.
                                If this continues to happen, please contact 
                                ${config.REACT_APP_BRANDING_SUPPORT_EMAIL}
                            `}
                        </p>
                        <br />
                        {onOpenReportForm !== undefined && (
                            <p>
                                To quickly fill out an error report, please{' '}
                                <Button
                                    variant='link'
                                    className='mx-0 px-0 my-0 py-0'
                                    onClick={onOpenReportForm}
                                >
                                    click here
                                </Button>
                            </p>
                        )}
                    </Alert>
                </Col>
                <Col />
            </Row>
            <Row>
                <Col />
                <Col xs={12} lg={3} style={{ textAlign: 'center' }}>
                    <Button variant='light' id='dismiss-error'>
                        <Link
                            to={ROUTES.HOME}
                            onClick={() => {
                                authService.logout();
                            }}
                        >
                            Back to login page
                        </Link>
                    </Button>
                </Col>
                <Col />
            </Row>
        </ContentWrapper>
    );
};

export default InvalidStudentErrorPage;
