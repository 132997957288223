import React from 'react';
import { Badge } from 'react-bootstrap';

interface Props {
    tag: Tag;
    selected?: boolean;
    onSelect?: () => void;
    onUnselect?: () => void;
}

const SelectableTag: React.FC<Props> = ({
    tag,
    selected = true,
    onSelect,
    onUnselect,
}) => {
    const style = selected
        ? {
              backgroundColor: tag.colour,
              borderColor: tag.colour,
          }
        : {
              backgroundColor: 'white',
              color: tag.colour,
          };

    return (
        <Badge
            variant='secondary'
            pill
            className='m-1'
            style={{
                fontSize: '0.9rem',
                fontWeight: 'normal',
                cursor: 'pointer',
                border: `1px solid ${tag.colour}`,
                ...style,
            }}
            onClick={selected ? onUnselect : onSelect}
        >
            {tag.name}
        </Badge>
    );
};

export default SelectableTag;
