import React from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ROUTES from '../../../consts/routes';

const CourseInfoCard: React.FC<{
    course: CourseShare;
    hideEnrol?: boolean;
}> = ({ course, hideEnrol }) => {
    return (
        <Card>
            <Card.Header style={{ padding: '2.5rem' }}>
                <Row>
                    {course.provider.logo_url && (
                        <Col
                            md={4}
                            className='d-flex justify-content-center align-items-center p-0'
                        >
                            <img
                                width='100%'
                                src={course.provider.logo_url}
                                alt={course.provider.name}
                            />
                        </Col>
                    )}
                    <Col md={8} className='p-0'>
                        <Link to={`/${ROUTES.COURSES}/${course.id}`}>
                            <h5 className='h5'>
                                <u>{course.title}</u>
                            </h5>
                        </Link>
                        <h6 className='h6'>{course.provider.name}</h6>
                    </Col>
                </Row>
                <Row>
                    <Col md={hideEnrol ? 12 : 9} className='p-0'>
                        <style>
                            {`
                                p {
                                    margin-bottom: 0;
                                    margin-top: 1rem;
                                }
                            `}
                        </style>
                        <p>{course.sub_title}</p>
                    </Col>
                    {!hideEnrol && (
                        <Col md={3} className='p-0'>
                            <Link to={`/${ROUTES.COURSES}/${course.id}`}>
                                <Button block>Enroll</Button>
                            </Link>
                        </Col>
                    )}
                </Row>
            </Card.Header>
        </Card>
    );
};

export default CourseInfoCard;
