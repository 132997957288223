import * as React from 'react';
import { Box, ChakraProvider } from '@chakra-ui/react';
import Chat, { Props as ChatProps } from '../../organisms/Chat/Chat';
import { themeWhiteShadows } from '../../theme';

const TextMobileTemplate: React.FC<ChatProps> = props => {
    return (
        // theme overrides only the outline shadow color
        <ChakraProvider theme={themeWhiteShadows} cssVarsRoot='#textTemplate'>
            <Box
                id='textTemplate'
                h='100%'
                backgroundColor='dark.900'
                pt='75px'
            >
                <Chat {...props} />
            </Box>
        </ChakraProvider>
    );
};

export default TextMobileTemplate;
