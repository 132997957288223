import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
    styles: {
        global: {
            // Based on Chrome's agent stylesheet
            'ol, ul': {
                marginBlockStart: '0',
                marginBlockEnd: '1em',
                marginInlineStart: '0px',
                marginInlineEnd: '0px',
                paddingInlineStart: '40px',
            },
            ol: {
                listStyleType: 'decimal',
            },
            ul: {
                listStyleType: 'disc',
            },
            p: {
                marginBottom: '1rem',
            },
        },
    },
    components: {
        Link: {
            baseStyle: {
                textDecoration: 'underline',
                _hover: {
                    textDecoration: 'none',
                    color: 'inherit',
                },
            },
        },
        Button: {
            baseStyle: {
                borderRadius: '15px',
                _focus: {
                    boxShadow: 'none', // to remove lingering shadows
                },
                _focusVisible: {
                    boxShadow: 'var(--chakra-shadows-box)',
                },
            },
        },
        IconButton: {
            baseStyle: {
                _focus: {
                    boxShadow: 'none',
                },
                _focusVisible: {
                    boxShadow: 'var(--chakra-shadows-box)',
                },
            },
        },
        Alert: {
            parts: ['container'],
            baseStyle: {
                container: {
                    borderRadius: '8px',
                },
            },
        },
        Input: {
            defaultProps: {
                focusBorderColor: 'brand.black.400',
            },
        },
        Heading: {
            baseStyle: {
                fontWeight: 'normal',
            },
        },
        Modal: {
            parts: ['dialog'],
            baseStyle: {
                dialog: {
                    marginTop: '2.5rem',
                    marginBottom: '2.5rem',
                    maxHeight: 'calc(100% - 5rem)',
                },
            },
        },
        Drawer: {
            parts: ['dialog'],
            sizes: {
                // By default, it's 100vh which on mobile includes the address bar
                // resulting in the top part of the drawer being hidden behind the address bar
                full: {
                    dialog: {
                        h: '100%',
                        maxH: '100%',
                    },
                },
            },
        },
    },
    fonts: {
        heading: 'Telegraf, sans-serif',
        body: 'Roboto, sans-serif',
    },
    colors: {
        brand: {
            gradient: {
                full:
                    'linear-gradient(315deg, #FECB89 0.96%, #FCBB45 10.34%, #F9865D 24.4%, #F75274 40.02%, #883F91 59.29%, #0312A9 75.96%, #0C1562 94.19%)',
                cold: {
                    full:
                        'linear-gradient(315deg, #F65275 9.82%, #883F91 40.02%, #0814A8 66.07%, #0C1562 92.63%)',
                    dark:
                        'linear-gradient(315deg, #883F91 37.42%, #0814A8 100.96%)',
                    light:
                        'linear-gradient(315deg, #F65275 9.82%, #883F91 84.29%)',
                },
                warm: {
                    full:
                        'linear-gradient(315deg, #FECB89 0.96%, #FCBB45 10.34%, #F9865D 45.75%, #F75274 82.21%)',
                    dark:
                        'linear-gradient(315deg, #F9865D 28.04%, #F75274 96.79%)',
                    light:
                        'linear-gradient(315deg, #FECB89 6.69%, #FCBB45 100.96%)',
                },
            },
            black: {
                '50': '#F8F8F8',
                '100': '#F3F3F3',
                '200': '#E5E5E5',
                '300': '#B1B1B1',
                '400': '#6B6B6B',
                '500': '#000000',
                '600': '#000000',
                '700': '#000000',
                '800': '#000000',
                '900': '#000000',
            },
        },
        dark: {
            '900': '#202222',
            '700': '#242A30',
            '600': '#2B2B2B',
            '500': '#3D3F40',
            '480': '#676767',
            '450': '#7E7E7E',
            '430': '#9B9B9B',
            '400': '#A0A0A0',
        },
        light: {
            '300': '#C1C1C1',
        },
    },
    shadows: {
        outline: '0 0 0 3px var(--chakra-colors-blackAlpha-600)',
        box: '0 0 0 3px var(--chakra-colors-blackAlpha-800)',
    },
});

export const themeWhiteShadows = extendTheme(
    {
        shadows: {
            box: '0 0 0 3px var(--chakra-colors-whiteAlpha-800)',
        },
    },
    theme,
);
