import React from 'react';
import BasePage from './BasePage';
import CertificatePageContent from '../components/courses/certificates/CertificatePageContent';

const CertificatePage: React.FC = props => {
    return (
        <BasePage {...props}>
            <CertificatePageContent />
        </BasePage>
    );
};

export default CertificatePage;
