import { ChakraProvider } from '@chakra-ui/react';
import * as React from 'react';
import { theme } from '../theme/theme';
import Fonts from '../theme/fonts';

export const ThemedChakraProvider: React.FC = ({ children }) => (
    <ChakraProvider theme={theme}>
        <Fonts />
        {children}
    </ChakraProvider>
);
