import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import ErrorPage from './ErrorPage';
import InvalidStudentErrorPage from './InvalidStudentErrorPage';
import { keycloak } from '../../services';

interface State {
    error: Error | null;
    eventId?: string;
}

interface Props {
    fullWidth?: boolean;
}

class GlobalBoundaryError extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            error: null,
            eventId: undefined,
        };
    }

    static getDerivedStateFromError(error: Error): { [key: string]: Error } {
        return { error: error };
    }

    componentDidCatch(error: any, errorInfo: any): void {
        Sentry.withScope(scope => {
            scope.setExtras(errorInfo);
            scope.setTag('error_page_displayed', 'yes');
            const eventId = Sentry.captureException(error);
            this.setState({ eventId });
        });
    }

    render(): JSX.Element {
        if (this.state.error) {
            const Page =
                this.state.error.name === 'permission_denied'
                    ? InvalidStudentErrorPage
                    : ErrorPage;
            return (
                <Page
                    fullWidth={this.props.fullWidth}
                    onOpenReportForm={() =>
                        Sentry.showReportDialog({
                            eventId: this.state.eventId,
                            user: keycloak.profile
                                ? {
                                      email: keycloak.profile.email,
                                      name: `${keycloak.profile.firstName} ${keycloak.profile.lastName}`,
                                  }
                                : undefined,
                        })
                    }
                    errorCleanUp={() => this.setState({ error: null })}
                />
            );
        }

        return <>{this.props.children}</>;
    }
}

export default GlobalBoundaryError;
