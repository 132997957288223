import config from './config';

export const INVALID_LOGIN_CREDENTIALS =
    'Invalid login credentials, please try again.';
export const INVALID_REGISTRATION_CREDENTIALS =
    'Something went wrong registering these details, please try again';
export const UNKNOWN_ERROR = `An unknown error has occurred. If this continues to happen, please contact ${config.REACT_APP_BRANDING_SUPPORT_EMAIL}`;
export const GROUP_NAME_ALREADY_EXISTS =
    'A group with this name exists already';
export const CASE_TITLE_ALREADY_EXISTS =
    'You already have a case with that title';
export const EMAIL_ALREADY_IN_USE = 'This email is already assigned to a user';

export const API_SERVICE_NOT_AVAILABLE =
    'There seems to be an error with the network. Please try again in a few minutes';

export const UNRECOGNISED_API_ERROR = 'Unrecognized server error';
