// const BOX_SHADOW = '2px 2px 5px 2px var(--chakra-colors-brand-black-200)';
import { CourseCardProps } from './index';

const BOX_SHADOW_SPREAD = 0;

const px = (val: number) => `${val}px`;

export const getCourseCardSize = (variant: CourseCardProps['variant']) => {
    const sizes: any = {
        width: 350,
    };

    switch (variant) {
        case 'locked':
            sizes['height'] = 300;
            break;
        case 'completed':
            sizes['height'] = 300;
            break;
        case 'in-progress':
            sizes['height'] = 330;
            break;
        case 'un-enrolled':
            sizes['height'] = 365;
            break;
    }

    Object.keys(sizes).forEach(key => {
        sizes[key] = px(sizes[key] + 2 * BOX_SHADOW_SPREAD);
    });

    return sizes;
};
