import React, { useState } from 'react';
import { Col, Button, Form, Alert } from 'react-bootstrap';
import { Formik, FormikValues } from 'formik';
import { moocAPI } from '../../services';
import PersonalInformationWarning from '../portfolio/PersonalInformationWarning';

interface Props {
    caseId: number;
    caseType: Case['case_type'];
    titleInitial: string;
    descriptionInitial: string;
    chiefComplaintInitial?: string;
    diagnosisInitial?: string;
    setCase: (portfolioCase: any) => void;
    onCancel: () => void;
}
const EditCaseForm: React.FC<Props> = ({
    caseId,
    caseType,
    titleInitial,
    descriptionInitial,
    chiefComplaintInitial,
    diagnosisInitial,
    onCancel,
    setCase,
}) => {
    const [serverError, setServerError] = useState<string | null>(null);

    function getInitialValues(
        caseType: Case['case_type'],
        title: string,
        description: string,
        chiefComplaint?: string,
        diagnosis?: string,
    ): FormikValues {
        if (caseType === 'consultation_case') {
            return {
                caseType: caseType,
                title: title,
                description: description,
                chiefComplaint: chiefComplaint,
                diagnosis: diagnosis,
            };
        }

        return {
            caseType: caseType,
            title: title,
            description: description,
        };
    }

    const validate = (values: {
        [name: string]: string;
    }): { [name: string]: string | null | undefined } => {
        const errors: {
            title?: null | string;
            description?: null | string;
            chiefComplaint?: null | string;
            diagnosis?: null | string;
        } = {};

        if (!values.title) {
            errors.title = 'Title required';
        } else if (values.title.length > 100) {
            errors.title = 'Title should be less than 100 characters';
        }

        if (values.caseType === 'consultation_case') {
            if (!values.chiefComplaint) {
                errors.chiefComplaint =
                    'Please provide a chief complaint for your case';
            } else if (values.chiefComplaint.length > 128) {
                errors.chiefComplaint =
                    'Please make sure the chief complaint is concise (less than 128 characters)';
            }
            if (!values.diagnosis) {
                errors.diagnosis = 'Please provide a diagnosis for your case';
            } else if (values.diagnosis.length > 128) {
                errors.diagnosis =
                    'Please make sure the diagnosis is concise (less than 128 characters)';
            }
        }

        return errors;
    };

    const onSubmit = (values: FormikValues): void => {
        values['chief_complaint'] = values['chiefComplaint'];
        delete values['chiefComplaint'];

        moocAPI
            .patch(`portfolio/case/${caseId}/`, values)
            .then(res => {
                setCase(res);
                onCancel();
            })
            .catch(error => setServerError(error));
    };
    return (
        <div className='mt-4'>
            {serverError && (
                <Alert variant='danger'>{serverError.toString()}</Alert>
            )}
            <Formik
                initialValues={getInitialValues(
                    caseType,
                    titleInitial,
                    descriptionInitial,
                    chiefComplaintInitial,
                    diagnosisInitial,
                )}
                onSubmit={onSubmit}
                validate={validate}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                }): JSX.Element => (
                    <Form noValidate onSubmit={handleSubmit} className='mt-2'>
                        <Form.Group controlId='title'>
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type='text'
                                name='title'
                                placeholder='Case title'
                                required
                                isInvalid={touched.title && !!errors.title}
                                value={values.title}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <Form.Control.Feedback type='invalid'>
                                {errors.title}
                            </Form.Control.Feedback>
                        </Form.Group>
                        {caseType === 'consultation_case' && (
                            <Form.Row>
                                <Col>
                                    <Form.Group controlId='chiefComplaint'>
                                        <Form.Label>Chief Complaint</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='chiefComplaint'
                                            placeholder='Chief Complaint'
                                            required
                                            isInvalid={
                                                touched.chiefComplaint &&
                                                !!errors.chiefComplaint
                                            }
                                            value={values.chiefComplaint}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.chiefComplaint}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group
                                        controlId='diagnosis'
                                        className='ml-1'
                                    >
                                        <Form.Label>Diagnosis</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='diagnosis'
                                            placeholder='Diagnosis'
                                            required
                                            isInvalid={
                                                touched.diagnosis &&
                                                !!errors.diagnosis
                                            }
                                            value={values.diagnosis}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.diagnosis}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                        )}
                        <Form.Group controlId='description'>
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                name='description'
                                as='textarea'
                                rows={Math.max(
                                    5,
                                    descriptionInitial
                                        ? descriptionInitial.split(' ').length /
                                              11
                                        : 0,
                                )}
                                isInvalid={
                                    touched.description && !!errors.description
                                }
                                value={values.description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <Form.Text className='text-muted'>
                                <PersonalInformationWarning />
                            </Form.Text>
                        </Form.Group>

                        <Form.Row className='mt-auto justify-content-end'>
                            <Col className='flex-grow-0'>
                                <Button
                                    variant='outline-primary'
                                    size='lg'
                                    className='px-4'
                                    style={{ borderRadius: '20px' }}
                                    onClick={onCancel}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col className='flex-grow-0'>
                                <Button
                                    variant='primary'
                                    size='lg'
                                    className='px-4'
                                    type='submit'
                                >
                                    Save
                                </Button>
                            </Col>
                        </Form.Row>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default EditCaseForm;
