import React from 'react';
import LabeledDate from '../../../components/generic/LabeledDate';

const WritingAssignmentLabel: React.FC<{
    writingAssignment: Activity['writing_assignment'];
}> = ({ writingAssignment }) => {
    return writingAssignment?.submitted_at ? (
        <LabeledDate
            date={writingAssignment.submitted_at}
            label={'Submitted:'}
        />
    ) : writingAssignment?.saved_draft_at ? (
        <LabeledDate
            date={writingAssignment.saved_draft_at}
            label={'Last Draft Saved:'}
        />
    ) : null;
};

export default WritingAssignmentLabel;
