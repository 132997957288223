import React from 'react';
import { Row, Col, Alert, Button } from 'react-bootstrap';
import { ContentWrapper } from '../../screens/BasePage';
import { UNKNOWN_ERROR } from '../../consts/errors';
import { Link, useHistory } from 'react-router-dom';
import ROUTES from '../../consts/routes';

const ErrorPage: React.FC<{
    fullWidth?: boolean;
    errorCleanUp?: () => void;
    onOpenReportForm?: () => void;
}> = ({ fullWidth, onOpenReportForm, errorCleanUp }) => {
    const history = useHistory();
    return (
        <ContentWrapper fullWidth={fullWidth ?? false}>
            <Row>
                <Col />
                <Col xs={12} lg={6} style={{ paddingTop: 'calc(100vh/3)' }}>
                    <Alert variant='danger'>
                        <Alert.Heading>
                            {"Something's gone wrong."}
                        </Alert.Heading>
                        <p className='mb-0'>{UNKNOWN_ERROR}</p>
                        <br />
                        {onOpenReportForm !== undefined && (
                            <p>
                                To quickly fill out an error report, please{' '}
                                <Button
                                    variant='link'
                                    className='mx-0 px-0 my-0 py-0'
                                    onClick={onOpenReportForm}
                                >
                                    click here
                                </Button>
                            </p>
                        )}
                    </Alert>
                </Col>
                <Col />
            </Row>
            <Row>
                <Col />
                <Col xs={12} lg={3} style={{ textAlign: 'center' }}>
                    <Button
                        variant='light'
                        id='dismiss-error'
                        as={Link}
                        to={ROUTES.HOME}
                        onClick={() => {
                            if (window.location.pathname === ROUTES.HOME) {
                                history.go(0);
                            } else if (errorCleanUp) {
                                errorCleanUp();
                            }
                        }}
                    >
                        Back to home page
                    </Button>
                </Col>
                <Col />
            </Row>
        </ContentWrapper>
    );
};

export default ErrorPage;
