import Keycloak, { KeycloakConfig } from 'keycloak-js';
import { logger } from '../logger';

const IDPS = ['wmed'];

export class AuthenticationService {
    keycloak: Keycloak;
    authenticated: boolean;
    initialized: boolean;

    constructor(keycloakConfig: KeycloakConfig) {
        this.keycloak = new Keycloak(keycloakConfig);

        this.authenticated = false;
        this.initialized = false;

        this.keycloak.onAuthSuccess = this.onAuthSuccess.bind(this);
    }

    async onAuthSuccess() {
        await this.keycloak.loadUserProfile();
        logger.debug(this.keycloak.profile);
    }

    async init(): Promise<void> {
        this.initialized = true;
        this.authenticated = await this.keycloak.init({
            // Tokens may be saved in localstorage soon after registration
            token: localStorage.getItem('access_token') || undefined,
            refreshToken: localStorage.getItem('refresh_token') || undefined,
            onLoad: 'check-sso',
            checkLoginIframe: false,
        });
    }

    login(idpHint = ''): void {
        if (!this.keycloak.authenticated) {
            this.keycloak.login({
                redirectUri: window.location.href,
                idpHint: IDPS.includes(idpHint) ? idpHint : undefined,
            });
        }
    }

    logout(): void {
        // Remove tokens that were saved upon registration
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');

        let redirectUri = window.location.origin;
        const possibleIDPHint = window.location.pathname.split('/')[1];
        if (IDPS.includes(possibleIDPHint)) {
            redirectUri += `/${possibleIDPHint}`;
        }
        this.keycloak.logout({ redirectUri });
    }
}
