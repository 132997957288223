import React, { Fragment, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Nav, Navbar, NavItem } from 'react-bootstrap';
import { keycloak } from '../../services';
import ROUTES from '../../consts/routes';
import SettingsDropDown from './SettingsDropDown';
import Logo from '../../images/logo_black_350.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircle,
    faLightbulb,
    faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import WithTooltip from './WithTooltip';
import UserFeedbackModal from '../modals/UserFeedbackModal';
import config from '../../consts/config';

interface Props {
    title?: string;
    headerLink?: string;
    height: number;
}

const FeedbackBtn: React.FC = () => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const onModalClose = useCallback(() => setShowModal(false), [setShowModal]);

    return (
        <NavItem
            style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: 15,
            }}
        >
            <Button
                variant='outline-primary'
                className='py-1 d-none d-sm-inline-block'
                size='sm'
                onClick={() => setShowModal(true)}
            >
                <FontAwesomeIcon
                    className='mr-2 fa-flip-horizontal'
                    icon={faLightbulb}
                />
                Leave us feedback
            </Button>
            <div className='d-sm-none' role='button'>
                <WithTooltip hoverMessage='Leave us feedback' side='bottom'>
                    <FontAwesomeIcon
                        className='mr-2 fa-flip-horizontal'
                        size='lg'
                        onClick={() => setShowModal(true)}
                        icon={faLightbulb}
                    />
                </WithTooltip>
            </div>
            <UserFeedbackModal show={showModal} onClose={onModalClose} />
        </NavItem>
    );
};

const Header: React.FC<Props> = ({ title, headerLink, height }) => {
    return (
        <Fragment>
            <Navbar
                bg='white'
                className='py-1 px-0 border-bottom'
                style={{
                    zIndex: 500,
                }}
            >
                <Container
                    style={{
                        height: height,
                        paddingLeft: '15px',
                        paddingRight: '15px',
                    }}
                    fluid='xl'
                >
                    <Navbar.Brand className='h-100'>
                        <Link
                            className='navbar-brand mr-0 mt-0 py-0 h-100 d-flex align-items-center'
                            to={ROUTES.HOME}
                        >
                            <img
                                src={config.REACT_APP_BRANDING_LOGO || Logo}
                                alt={config.REACT_APP_BRANDING_TITLE}
                                style={{ maxHeight: '25px' }}
                            />
                        </Link>
                        <style>
                            {`.navbar .fa-circle {
            font-size: .5rem;
            line-height: 10px;
            margin-bottom: 4px;
        }`}
                        </style>
                        {title && headerLink ? (
                            <Link
                                to={{
                                    pathname: headerLink,
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faCircle}
                                    className='mr-2'
                                />
                                <p
                                    style={{
                                        color: 'var(--primary)',
                                        display: 'inline-block',
                                        marginTop: 22,
                                    }}
                                    className='ml-1 mb-0 pb-1 small navbar-brand pt-0'
                                >
                                    {title + ' '}
                                </p>
                            </Link>
                        ) : (
                            title && (
                                <Fragment>
                                    <FontAwesomeIcon
                                        icon={faCircle}
                                        className='mr-2'
                                    />
                                    <p
                                        style={{
                                            color: 'var(--primary)',
                                            display: 'inline-block',
                                        }}
                                        className='ml-2 mb-0 small navbar-brand'
                                    >
                                        {title}{' '}
                                    </p>
                                </Fragment>
                            )
                        )}
                    </Navbar.Brand>
                    <Nav
                        className='justify-content-end'
                        activeKey={ROUTES.HOME}
                    >
                        {keycloak.authenticated && <FeedbackBtn />}
                        <NavItem
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginRight: 15,
                            }}
                        >
                            <WithTooltip
                                side='bottom'
                                fontSize={12}
                                hoverMessage='Help'
                            >
                                <a
                                    href={
                                        process.env
                                            .REACT_APP_BRANDING_ONBOARDING_URL
                                    }
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    style={{ color: 'var(--primary)' }}
                                    title='Help'
                                >
                                    <FontAwesomeIcon
                                        size='lg'
                                        icon={faQuestionCircle}
                                    />
                                </a>
                            </WithTooltip>
                        </NavItem>
                        {keycloak.authenticated && <SettingsDropDown />}
                    </Nav>
                </Container>
            </Navbar>
        </Fragment>
    );
};

export default Header;
