import React, { Fragment } from 'react';
import { Form, Row } from 'react-bootstrap';

interface Props {
    content: string;
    charLimit?: number;
    className?: string;
}

const getCharactersLeft = (content: string, limit: number): number => {
    const charsLeft = limit - content.length;
    return Math.max(charsLeft, 0);
};

const CharacterCounter: React.FC<Props> = props => {
    return (
        <Fragment>
            {props.children}
            <Form.Text className={props.className || 'text-muted'}>
                <Row noGutters className='justify-content-end mb-1'>
                    {props.charLimit
                        ? `${getCharactersLeft(
                              props.content,
                              props.charLimit,
                          )} characters left`
                        : `${props.content.length} characters`}
                </Row>
            </Form.Text>
        </Fragment>
    );
};

export default CharacterCounter;
