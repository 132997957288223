import React, { Fragment, useState } from 'react';
import { Button, Col } from 'react-bootstrap';
import LoadingSpinner from '../generic/LoadingSpinner';
import { ModalProps } from './types';
import {
    ModalHeader,
    ModalBody,
    ModalOverlay,
    Heading,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
} from '@chakra-ui/react';
import { ModalWithNoInitialFocus } from '@recourseai/components/src/atoms/ModalWithNoInitialFocus/ModalWithNoInitialFocus';

interface Props extends ModalProps {
    onViewFeedback: () => Promise<any>;
    onClose: () => Promise<any>;
}

const ResumeCancelledAttemptModal: React.FC<Props> = ({
    show,
    onViewFeedback,
    onClose,
}) => {
    const [isInitialising, setIsInitialising] = useState(false);

    return (
        <ModalWithNoInitialFocus
            isOpen={show}
            size='lg'
            onClose={onClose}
            closeOnOverlayClick={!isInitialising}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Heading fontWeight='normal'>Activity complete</Heading>
                    {!isInitialising && <ModalCloseButton />}
                </ModalHeader>
                <ModalBody>
                    You have already completed this attempt, so the session
                    cannot be resumed. Please click below to see your feedback.
                </ModalBody>
                <ModalFooter justifyContent='center'>
                    {isInitialising ? (
                        <LoadingSpinner />
                    ) : (
                        <Fragment>
                            <Col xs='auto' className='mx-2'>
                                <Button
                                    variant='outline-primary'
                                    onClick={() => {
                                        setIsInitialising(true);
                                        onClose().finally(() =>
                                            setIsInitialising(false),
                                        );
                                    }}
                                >
                                    Close
                                </Button>
                            </Col>
                            <Col xs='auto' className='mx-2'>
                                <Button
                                    variant='primary'
                                    onClick={() => {
                                        setIsInitialising(true);
                                        onViewFeedback().finally(() => {
                                            setIsInitialising(false);
                                        });
                                    }}
                                >
                                    View Feedback
                                </Button>
                            </Col>
                        </Fragment>
                    )}
                </ModalFooter>
            </ModalContent>
        </ModalWithNoInitialFocus>
    );
};

export default ResumeCancelledAttemptModal;
