import React, { Fragment, CSSProperties } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import WithTooltip from '../../../generic/WithTooltip';
import AgentActionFeedbackModal from '@recourseai/components/src/molecules/AgentActionFeedbackModal/AgentActionFeedbackModal';
import useFeedbackSubmit from '@recourseai/components/src/hooks/useFeedbackSubmit';

interface Props {
    actionId: number;
    style?: CSSProperties;
    className?: string;
    showButton: boolean;
    onOpen?: () => void;
}

const SendFeedback: React.FC<Props> = ({
    actionId,
    style,
    className,
    showButton,
    onOpen,
}) => {
    const {
        handleFeedbackSubmit,
        isModalOpen,
        setIsModalOpen,
    } = useFeedbackSubmit(actionId);

    return (
        <Fragment>
            {showButton && (
                <WithTooltip hoverMessage={'Submit feedback for response'}>
                    {({ ref, ...triggerHandler }) => (
                        <Button
                            variant='link'
                            {...triggerHandler}
                            className={className}
                            style={style}
                            onClick={() => {
                                if (onOpen) {
                                    onOpen();
                                }
                                setIsModalOpen(true);
                            }}
                        >
                            <span ref={ref}>
                                <FontAwesomeIcon
                                    icon={faExclamationCircle}
                                    color={'var(--secondary)'}
                                />
                            </span>
                        </Button>
                    )}
                </WithTooltip>
            )}
            <AgentActionFeedbackModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSubmit={handleFeedbackSubmit}
            />
        </Fragment>
    );
};

export default SendFeedback;
