import React, { Fragment, useEffect, useRef, useState } from 'react';
import { SimliClient } from 'simli-client';
import { SimliClientConfig } from 'simli-client/dist/SimliClient';
import {
    InteractionContextAPI,
    useInteractionContext,
} from '../../utils/interaction/InteractionContext';
import { Box, Center } from '@chakra-ui/react';
import { promiseWithResolvers } from '../../../../core/src/utils/promise';
import GreenScreenVideo from './GreenScreenVideo';
import LoadingSpinner from '../../../../../apps/mooc-frontend/src/components/generic/LoadingSpinner';
import { useStoreWithArray } from '../../stores';

type SimliEvent = 'connected' | 'disconnected' | 'failed';
const SIMLI_EVENTS = ['connected', 'disconnected', 'failed'] as SimliEvent[];

export interface Props {
    simliConfig: Omit<SimliClientConfig, 'audioRef' | 'videoRef'>;
    eventHandler: (event: SimliEvent, simliClient: SimliClient) => void;
    backgroundSrc?: string;
    useGreenScreenRemoval?: boolean;
}

const DEFAULT_BACKGROUND_COLOR = '#EEEEEE';

async function convertMp3ToPCM16(mp3Url: string) {
    const start = performance.now();

    // Fetch the MP3 file
    const response = await fetch(mp3Url);
    const arrayBuffer = await response.arrayBuffer();

    // Create audio context
    const audioContext = new (window.AudioContext ||
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.webkitAudioContext)();

    // Decode the audio data
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

    // Create an offline context for resampling
    const offlineContext = new OfflineAudioContext(
        1,
        audioBuffer.duration * 16000,
        16000,
    );

    // Create a buffer source
    const source = offlineContext.createBufferSource();
    source.buffer = audioBuffer;

    // Connect the source to the offline context destination
    source.connect(offlineContext.destination);

    // Start the source and render
    source.start(0);
    const renderedBuffer = await offlineContext.startRendering();

    // Get the PCM data as a Float32Array
    const channelData = renderedBuffer.getChannelData(0);

    // Convert Float32Array to Int16Array (PCM16)
    const pcm16 = new Int16Array(channelData.length);
    for (let i = 0; i < channelData.length; i++) {
        const s = Math.max(-1, Math.min(1, channelData[i]));
        pcm16[i] = s < 0 ? s * 0x8000 : s * 0x7fff;
    }

    // Convert Int16Array to Uint16Array
    const uint8Array = new Uint8Array(pcm16.buffer);

    console.log('convertMp3ToPCM16: ', performance.now() - start);
    return uint8Array;
}

const simliClient = new SimliClient();

const AvatarWrapperSimli: React.FC<Props> = ({
    simliConfig,
    backgroundSrc,
    eventHandler,
    useGreenScreenRemoval,
}) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const audioRef = useRef<HTMLAudioElement>(null);

    const [connected, setConnected] = useState(false);

    const { audioListeners } = useStoreWithArray(['audioListeners']);

    const {
        addActionProcessor,
        setShouldSkipTtsSynthesis,
    } = useInteractionContext(InteractionContextAPI);

    useEffect(() => {
        setShouldSkipTtsSynthesis(false);
        const toggleAudio = (on: boolean) => {
            if (audioRef.current && videoRef.current) {
                // Audio is played from both of them
                videoRef.current.muted = !on;
                audioRef.current.muted = !on;
            }
        };
        audioListeners.add(toggleAudio);

        return () => {
            audioListeners.delete(toggleAudio);
        };
    }, [audioListeners, setShouldSkipTtsSynthesis]);

    useEffect(() => {
        simliClient.Initialize({ ...simliConfig, videoRef, audioRef });

        simliClient.on('connected', () => {
            const audioData = new Uint8Array(6000).fill(0);
            simliClient.sendAudioData(audioData);
            setConnected(true);
        });

        SIMLI_EVENTS.forEach(simliEvent => {
            simliClient.on(simliEvent, () =>
                eventHandler(simliEvent, simliClient),
            );
        });

        addActionProcessor('audio', (action, activeStage) => {
            const { promise, resolve } = promiseWithResolvers();
            convertMp3ToPCM16(action.payload.auditory.url).then(audioData => {
                resolve();
                simliClient.sendAudioData(audioData);
            });
            return { promise, stop: simliClient.ClearBuffer };
        });

        simliClient.start();
        return () => {
            simliClient.close();
        };
    }, [addActionProcessor, eventHandler, simliConfig]);

    return (
        <Fragment>
            {backgroundSrc && !backgroundSrc.startsWith('#') ? (
                <Box
                    w='100%'
                    h='100%'
                    backgroundImage={backgroundSrc}
                    filter={`blur(${useGreenScreenRemoval ? '10px' : '30px'})`}
                    backgroundSize='cover'
                    backgroundPosition='bottom'
                />
            ) : (
                <Box
                    w='100%'
                    h='100%'
                    filter={`blur(${useGreenScreenRemoval ? '10px' : '30px'})`}
                    backgroundColor={backgroundSrc || DEFAULT_BACKGROUND_COLOR}
                />
            )}
            <Box
                h='100%'
                w={{ base: '55%', xl: '65%' }}
                position='fixed'
                bottom='0px'
            >
                <Center h='100%'>
                    {!connected && <LoadingSpinner />}
                    {useGreenScreenRemoval ? (
                        <GreenScreenVideo
                            ref={videoRef}
                            autoPlay
                            playsInline
                            style={{
                                height: '100%',
                                width: 'auto',
                                display: connected ? 'block' : 'none',
                            }}
                        />
                    ) : (
                        /* eslint-disable-next-line jsx-a11y/media-has-caption */
                        <video
                            ref={videoRef}
                            autoPlay
                            playsInline
                            style={{
                                display: connected ? 'block' : 'none',
                                width: '60%',
                                height: 'auto',
                            }}
                        />
                    )}
                </Center>
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <audio ref={audioRef} autoPlay></audio>
            </Box>
        </Fragment>
    );
};

export default AvatarWrapperSimli;
