import React, { Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import CaseCard from './CaseCard';
import { FormikHelpers, FormikValues } from 'formik';
import {
    EditableGroupName,
    ActionsMenu,
    ActionsMenuProps,
} from './GroupOverview';

interface Props {
    group: Group;
    isEditMode: boolean;
    onCancelEdit: () => void;
    onSubmit: (values: FormikValues, formikHelpers: FormikHelpers<any>) => void;
    onEdit: () => void;
    onDelete: () => void;
}

const GroupRow: React.FC<Props & ActionsMenuProps> = ({
    group,
    isEditMode,
    onCancelEdit,
    onSubmit,
    ...other
}) => {
    return (
        <Fragment>
            <Row className={isEditMode ? 'my-3' : 'my-1'}>
                <Col xs='auto' style={{ flexGrow: isEditMode ? 1 : 0 }}>
                    <EditableGroupName
                        group={group}
                        isLink={true}
                        isEditMode={isEditMode}
                        onSubmit={onSubmit}
                        onCancel={onCancelEdit}
                    />
                </Col>
                <Col>
                    {!isEditMode && <ActionsMenu group={group} {...other} />}
                </Col>
            </Row>
            <Row className='mb-4 flex-nowrap overflow-auto'>
                {group.cases.map((portfolioCase, index) => (
                    <Col xs='auto' key={index}>
                        <CaseCard portfolioCase={portfolioCase} />
                    </Col>
                ))}
            </Row>
        </Fragment>
    );
};

export default GroupRow;
