import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'fast-text-encoding/text';

import * as React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './custom.scss';
import * as Sentry from '@sentry/browser';
import mixpanel from 'mixpanel-browser';
import { BrowserRouter as Router } from 'react-router-dom';
import GlobalBoundaryError from './components/generic/GlobalBoundaryError';
import { MixpanelProvider } from './components/contexts/MixpanelContext';
import { ChakraProvider } from '@chakra-ui/react';
import { theme, Fonts } from '@recourseai/components';
import * as FullStory from '@fullstory/browser';
import SentryFullStory from '@sentry/fullstory';
import config from './consts/config';
import { install } from 'resize-observer';
import { logger } from '@recourseai/core';

// import KcApp from './KcApp';
// import { kcContext } from './KcApp/kcContext';
// import {getKcContext} from 'keycloakify';
// Uncomment to test the login page for development.
// export const { kcContext } = getKcContext({
//     mockPageId: 'login.ftl',
// });

const env = config.REACT_APP_ENV;

const ENVIRONMENTS_WITH_FULLSTORY = ['production', 'beta'];
const ORG_ID = config.REACT_APP_FULLSTORY_ORG_ID;
if (env && ORG_ID) {
    FullStory.init({
        orgId: ORG_ID,
        devMode: !ENVIRONMENTS_WITH_FULLSTORY.includes(env),
    });
}

const sentryIntegrations = [];
if (config.REACT_APP_SENTRY_ORGANISATION_SLUG && FullStory.isInitialized()) {
    sentryIntegrations.push(
        new SentryFullStory(config.REACT_APP_SENTRY_ORGANISATION_SLUG, {
            client: FullStory,
        }),
    );
}

if (env !== 'local') {
    Sentry.init({
        dsn: config.REACT_APP_SENTRY_DNS,
        environment: env ?? 'production',
        debug: env === 'staging',
        release: config.REACT_APP_SENTRY_RELEASE,
        integrations: sentryIntegrations,
    });
}

mixpanel.init(config.REACT_APP_MIXPANEL_PROJECT_TOKEN!, {
    debug: env !== 'production',
    ip: false,
});

if (env === 'local') {
    mixpanel.disable();
    mixpanel.track = (event: string, properties: any): void => {
        logger.debug(performance.now(), 'Mixpanel event');
        logger.debug(' ', event, JSON.stringify(properties));
    };
}

// Pollyfill for older browsers
if (!window.ResizeObserver) {
    install();
}

ReactDOM.render(
    <ChakraProvider theme={theme} cssVarsRoot='#root'>
        <Fonts />
        {/* If building for keycloak uncomment line below and Kc imports and comment everything else for minimal bundle size*/}
        {/*<KcApp kcContext={kcContext!} />*/}
        <Router>
            <MixpanelProvider mixpanel={mixpanel}>
                <GlobalBoundaryError>
                    <App />
                </GlobalBoundaryError>
            </MixpanelProvider>
        </Router>
    </ChakraProvider>,
    document.getElementById('root'),
);

HTMLCollection.prototype.forEach = function(func: any) {
    return Array.from(this).forEach(func);
};
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
