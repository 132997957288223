import { StateCreator } from 'zustand';

export type ModalSetting = {
    currentModal:
        | 'attachment'
        | 'finish'
        | 'feedback'
        | 'activity feedback'
        | 'error'
        | null;
    errorModalMessage: string;
    setCurrentModal: (modalType: ModalSetting['currentModal']) => void;
    setErrorMessage: (errorMessage: string) => void;
    closeModal: () => void;
};

export const createUiSettingsSlice: StateCreator<ModalSetting> = set => ({
    currentModal: null,
    errorModalMessage: '',
    setCurrentModal: modalType => set({ currentModal: modalType }),
    setErrorMessage: errorMessage => set({ errorModalMessage: errorMessage }),
    closeModal: () => set({ currentModal: null }),
});
