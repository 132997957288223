import {
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import { useMixpanel } from '../../components/contexts/MixpanelContext';
import useAsyncError from '../../hooks/useAsyncError';
import { useStripe } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';
import { moocAPI } from '../../services';
import ROUTES from '../../consts/routes';
import { useEffect, useState } from 'react';
import usePrevious from '../../hooks/usePrevious';
import mixpanel from 'mixpanel-browser';
import { cloneDeep } from 'lodash';

export const useCourse = (
    courseId: string,
    queryOptions?: any,
): {
    course: Course | undefined;
    isLoading: boolean;
    enrollMutation: UseMutationResult<any, unknown, Course, unknown>;
} => {
    const queryClient = useQueryClient();
    const mixpanel = useMixpanel();
    const throwAsyncError = useAsyncError();
    const stripe = useStripe();
    const history = useHistory();

    const { data: course, isLoading } = useQuery<Course>(
        ['course', courseId],
        () => moocAPI.get(`course/${courseId}/`),
        {
            onSuccess: () =>
                mixpanel?.track('course_overview', {
                    course_id: courseId,
                }),
            onError: throwAsyncError,
            ...queryOptions,
        },
    );

    const enrollPurchaseMutation = useMutation({
        mutationFn: (course: Course) => {
            const product_id = course?.product_information.one_off_purchase?.id;
            return moocAPI.post('products/checkout/', {
                non_organisation_one_off_product_id: product_id,
                success_url: `${window.location.origin}/${ROUTES.PAYMENT_PROCESSING}?product_id=${product_id}&redirect=${window.location.pathname}&payment_success=true`,
                cancel_url: `${window.location.href}?payment_success=false`,
            });
        },
        onSuccess: (checkoutSession, course) => {
            const product_id = course?.product_information.one_off_purchase?.id;
            mixpanel?.track('payment_started', {
                product_id: product_id,
            });
            stripe?.redirectToCheckout({
                sessionId: checkoutSession.id,
            });
        },
        onError: throwAsyncError,
    });

    const standardEnrollMutation = useMutation({
        mutationFn: (course: Course) =>
            moocAPI.post(`course/${course.id}/enroll/`),
        onSuccess: (studentCourse, course) => {
            mixpanel?.track('course_enroll', {
                course_id: courseId.toString(),
            });

            queryClient.setQueryData<Course>(
                ['course', courseId],
                unEnrolledCourse => {
                    if (unEnrolledCourse) {
                        return {
                            ...unEnrolledCourse,
                            student_course: studentCourse,
                        };
                    }
                },
            );

            queryClient.refetchQueries(['courses', 'in-progress'], {
                exact: true,
            });
            queryClient.refetchQueries(['courses', 'available'], {
                exact: true,
            });

            history.push(
                `/${ROUTES.COURSES}/${courseId}/${ROUTES.ACTIVITY}/${course.sections[0].activities[0].id}`,
            );
        },
        onError: (error, course) => {
            throwAsyncError(error);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (error['message'] === 'user_already_enrolled') {
                queryClient.refetchQueries(['courses', course.id]);
                return;
            }
            throwAsyncError(error);
        },
    });

    let enrollMutation = standardEnrollMutation;
    if (
        !course?.product_information.on_subscription_plan &&
        course?.product_information.one_off_purchase?.product !== null
    ) {
        enrollMutation = enrollPurchaseMutation;
    }

    return {
        course,
        isLoading,
        enrollMutation,
    };
};

export const useCourseJustCompleted = (
    course: Course | undefined,
    onComplete: () => void,
): boolean => {
    const [courseJustCompleted, setCourseJustCompleted] = useState(false);
    const previousCourse = usePrevious(course);

    useEffect(() => {
        if (course?.id !== previousCourse?.id) {
            setCourseJustCompleted(false);
            return;
        }

        const coursePreviouslyCompleted = !!previousCourse?.student_course
            ?.completed_datetime;
        if (!course || !course.student_course || coursePreviouslyCompleted) {
            return;
        }

        const courseComplete =
            course.student_course.completed_datetime !== null;
        if (courseComplete && !coursePreviouslyCompleted) {
            mixpanel.track('course_complete', {
                course_id: course.id,
            });
            setCourseJustCompleted(true);
            onComplete();
        }
    }, [course, previousCourse, onComplete]);

    return courseJustCompleted;
};

export const useActivities = (
    courseId?: string,
    queryOptions?: any,
): {
    activities: Activity[] | undefined;
    isLoading: boolean;
    isSuccess: boolean;
} => {
    const throwAsyncError = useAsyncError();
    const queryClient = useQueryClient();

    const { data: activities, isLoading, isSuccess } = useQuery<Activity[]>(
        ['course', courseId, 'activities'],
        () =>
            moocAPI.get(
                courseId
                    ? `course/${courseId}/activities/`
                    : 'standalone-activities',
            ),
        {
            onSuccess: activities => {
                activities.forEach(a => {
                    queryClient.setQueryData(
                        ['course', courseId, 'activity', a.id.toString()],
                        cloneDeep(a),
                    );
                });
            },
            onError: throwAsyncError,
            ...queryOptions,
        },
    );

    return { activities, isLoading, isSuccess };
};
