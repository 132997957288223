// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function findLocalItems(query) {
    let i;
    const results = [];

    for (i in localStorage) {
        // eslint-disable-next-line no-prototype-builtins
        if (localStorage.hasOwnProperty(i)) {
            if (i.match(query) || (!query && typeof i === 'string')) {
                const value = JSON.parse(localStorage.getItem(i));
                results.push({ key: i, val: value });
            }
        }
    }
    return results;
}

export default findLocalItems;
