import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import {
    FontAwesomeIcon,
    FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';

interface IconButtonProps {
    iconProps: FontAwesomeIconProps;
    buttonProps?: ButtonProps;
    className?: string;
    onClick?: () => void;
}

const IconButton: React.FC<IconButtonProps> = ({
    iconProps,
    buttonProps,
    onClick,
    className = '',
    ...other
}) => (
    <Button
        {...buttonProps}
        {...other}
        variant='light'
        className={`border-0 rounded-circle mx-1 py-0 ${className}`}
        bsPrefix='btn-brand'
        onClick={onClick}
    >
        <FontAwesomeIcon {...iconProps} fixedWidth />
    </Button>
);

export default IconButton;
