import React, { Fragment, useEffect, useState } from 'react';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { Mixpanel } from 'mixpanel-browser';
import GroupOverview from './GroupOverview';
import CentredComponent from '../../hocs/CentredComponent';
import LoadingSpinner from '../generic/LoadingSpinner';
import { moocAPI } from '../../services';
import useAsyncError from '../../hooks/useAsyncError';
import ROUTES from '../../consts/routes';
import AllowedActionsContext from '../contexts/AllowedActionsContext';

const GroupOverviewPageContent: React.FC<{ mixpanel?: Mixpanel }> = ({
    mixpanel,
}) => {
    const { groupId } = useParams<{ groupId: string }>();
    const history = useHistory();
    const throwError = useAsyncError();

    const [group, setGroup] = useState<Group | null>(null);
    const [notFound, setNotFound] = useState<boolean>(false);

    useEffect(() => {
        if (!group) {
            moocAPI
                .get(`portfolio/group/${groupId}/`)
                .then(res => {
                    if (res === null) {
                        setNotFound(true);
                    } else {
                        setGroup(res);
                    }
                })
                .catch(error => {
                    throwError(error);
                });
        }
    }, [group, groupId, history, mixpanel, throwError]);

    if (notFound) {
        return <Redirect to={'/' + ROUTES.PAGE_NOT_FOUND} />;
    }

    if (!group) {
        return <CentredComponent component={<LoadingSpinner />} />;
    }

    return (
        <Fragment>
            <AllowedActionsContext.Provider value={group.user_permissions}>
                <GroupOverview group={group} setGroup={setGroup} />
            </AllowedActionsContext.Provider>
        </Fragment>
    );
};

export default GroupOverviewPageContent;
