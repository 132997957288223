import React from 'react';
import BasePage from './BasePage';
import PaymentProcessingPageContent from '../components/payments/PaymentProcessingPageContent';

const PaymentProcessingPage: React.FC = props => {
    return (
        <BasePage {...props}>
            <PaymentProcessingPageContent />
        </BasePage>
    );
};

export default PaymentProcessingPage;
