import React, { forwardRef, useEffect, useState } from 'react';
import {
    Box,
    BoxProps,
    Button,
    Collapse,
    useDisclosure,
} from '@chakra-ui/react';

interface Props extends BoxProps {
    children: React.ReactNode;
    noOfLines: number;
    // Only supports heights using 'em' units
    lineHeight: string;
}

const ExpandableText = forwardRef<HTMLDivElement, Props>(
    ({ children, noOfLines, lineHeight, ...rest }, ref) => {
        const inputRef = React.useRef<HTMLInputElement>(null);
        const [isTextClamped, setIsTextClamped] = useState(false);
        const { isOpen, onToggle } = useDisclosure();

        useEffect(() => {
            const updateTextClamped = () => {
                setIsTextClamped(
                    (inputRef.current!.scrollHeight as number) >
                        (inputRef.current!.clientHeight as number),
                );
            };
            // We need to delay this until after the components render
            // so, we use javascript's wonderful event loop
            // setTimeout will execute after everything that is already queued finishes
            setTimeout(updateTextClamped, 0);
            window.addEventListener('resize', updateTextClamped);
            return () =>
                window.removeEventListener('resize', updateTextClamped);
        }, []);

        return (
            <Box ref={ref} {...rest}>
                <Collapse
                    ref={inputRef}
                    startingHeight={`${noOfLines * parseFloat(lineHeight) +
                        0.2}em`}
                    in={isOpen}
                >
                    {children}
                </Collapse>
                <Button
                    display={isTextClamped ? 'block' : 'none'}
                    size='sm'
                    variant='link'
                    onClick={onToggle}
                >
                    {isOpen ? 'Show less' : 'Read more'}
                </Button>
            </Box>
        );
    },
);
ExpandableText.displayName = 'ExpandableText';

export default ExpandableText;
