import {
    InteractionContextAPI,
    InteractionContextStatus,
    useInteractionContext,
} from '../../utils/interaction/InteractionContext';
import useRefOfState from '../../../../core/src/hooks/useRefOfState';
import { useCallback, useRef } from 'react';
import { useStore } from '../../stores';
import { UserMessageSource } from '../../organisms/Chat/Chat';

const useASR = () => {
    const {
        awaitingResponse,
        isAgentBusy,
        isTranscribing,
    } = useInteractionContext(InteractionContextStatus);

    const isAgentBusyRef = useRefOfState(isAgentBusy);
    const isTranscribingRef = useRefOfState(isTranscribing);
    const awaitingResponseRef = useRefOfState(awaitingResponse);
    const detectedLanguageCodesRef = useRef<Set<string>>(new Set());
    const { sendMessage } = useInteractionContext(InteractionContextAPI);

    const asrBuffer = useRef('');

    const resetBuffer = useCallback(() => {
        asrBuffer.current = '';
        detectedLanguageCodesRef.current.clear();
    }, []);

    const onMessageRecognised = useCallback(
        (text: string, languageCode: string, shouldBuffer = false) => {
            const shouldIgnore =
                awaitingResponseRef.current || isAgentBusyRef.current;

            // isTranscribing is true when the avatar is transcribing audio
            // awaitingResponse is also true when the avatar is transcribing audio
            // in such a case, we should not ignore the message
            if (shouldIgnore && !isTranscribingRef.current) {
                console.debug(
                    'Skipped recognised speech because avatar was busy',
                    { text },
                );
                return;
            }

            const isMicOn = useStore.getState().isMicOn;
            if (isMicOn || shouldBuffer) {
                asrBuffer.current += text + ' ';
                detectedLanguageCodesRef.current.add(languageCode);
            } else {
                sendMessage(text, {
                    message_source: UserMessageSource.CLOUD_RECOGNISED_SPEECH,
                    detected_language_codes: [languageCode],
                });
            }
            console.log('buffer', asrBuffer.current);
        },
        [awaitingResponseRef, isAgentBusyRef, isTranscribingRef, sendMessage],
    );

    const sendBuffer = useCallback(
        (micOn: boolean, meta: Partial<UserMessageMeta> = {}) => {
            if (!micOn && asrBuffer.current.length) {
                sendMessage(asrBuffer.current, {
                    message_source: UserMessageSource.CLOUD_RECOGNISED_SPEECH,
                    detected_language_codes: Array.from(
                        detectedLanguageCodesRef.current,
                    ),
                    ...meta,
                });

                resetBuffer();
            }
        },
        [sendMessage, detectedLanguageCodesRef, resetBuffer],
    );

    return {
        onMessageRecognised,
        resetBuffer,
        sendBuffer,
    };
};

export default useASR;
