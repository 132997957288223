// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const doesTokenExpireSoon = (parsedToken, hours = 2) => {
    if (!parsedToken.exp) {
        return false;
    }

    const now = new Date();
    const soon = new Date();
    soon.setHours(now.getHours() + hours);
    return parsedToken.exp < soon.getTime() / 1000;
};

exports.doesTokenExpireSoon = doesTokenExpireSoon;
