import APIService from './APIService';

export default class SpeechRecognitionApi extends APIService {
    generateTicket = async () => {
        const { ticket } = await this.get('ws-ticket');
        return ticket;
    };

    generateAuthenticatedWebsocketUrl = async () => {
        const url = new URL(this.absoluteUrl('transcribe'));
        url.protocol = url.protocol === 'https:' ? 'wss:' : 'ws:';

        const ticket = await this.generateTicket();
        url.searchParams.set('ticket', ticket);

        return url.href;
    };
}
