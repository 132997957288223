export const isRemoteAsr = (transcriber_config: TranscriberConfig) =>
    transcriber_config.api === 'cloud';

export const isRapportAsr = (transcriber_config: TranscriberConfig) =>
    transcriber_config.api === 'rapport';

export const isStoppedEvent = (
    event: WebsocketEvent,
): event is StoppedTranscriptionResponse => event.type === 'stopped';

export const isStartedEvent = (
    event: WebsocketEvent,
): event is StartedTranscriptionResponse => event.type === 'started';

export const isConfiguredEvent = (
    event: WebsocketEvent,
): event is ConfigureTranscriptionResponse => event.type === 'configured';

export const isSpeechTranscriptEvent = (
    event: WebsocketEvent,
): event is SpeechTranscript =>
    event.type === 'speech' && event.name === 'speech_transcript';

export const isWebsocketEvent = (
    event: Record<string, any>,
): event is WebsocketEvent =>
    ['speech', 'stopped', 'started', 'configured'].includes(event.type);

export const parseWebsocketEvent = (rawEvent: string) => {
    try {
        const event = JSON.parse(rawEvent);
        if (isWebsocketEvent(event)) {
            return event;
        }
    } catch (err) {
        /* empty */
    }
};
