import { useDisclosure } from '@chakra-ui/react';
import React, { Fragment, JSXElementConstructor, ReactElement } from 'react';

interface ModalOpenerProp {
    Modal: React.ElementType;
    children: ReactElement<
        any & { onClick?: (e: React.MouseEvent) => void },
        JSXElementConstructor<any>
    >;
}

const ModalOpener: React.FC<ModalOpenerProp> = ({ Modal, children }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const Opener = React.cloneElement(children, {
        onClick: (e: React.MouseEvent) => {
            if (children.props.onClick) {
                children.props.onClick(e);
            }
            onOpen();
        },
    });

    return (
        <Fragment>
            {Opener}
            <Modal show={isOpen} onClose={onClose} />
        </Fragment>
    );
};

export default ModalOpener;
