import React, { Fragment, useEffect, useState } from 'react';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { Mixpanel } from 'mixpanel-browser';
import CaseOverview from './CaseOverview';
import CentredComponent from '../../hocs/CentredComponent';
import LoadingSpinner from '../generic/LoadingSpinner';
import { moocAPI } from '../../services';
import useAsyncError from '../../hooks/useAsyncError';
import ROUTES from '../../consts/routes';
import AllowedActionsContext from '../contexts/AllowedActionsContext';

const CaseOverviewPageContent: React.FC<{ mixpanel?: Mixpanel }> = ({
    mixpanel,
}) => {
    const { caseId } = useParams<{ caseId: string }>();
    const history = useHistory();
    const throwError = useAsyncError();

    const [portfolioCase, setPortfolioCase] = useState<Case | null>(null);
    const [notFound, setNotFound] = useState<boolean>(false);

    useEffect(() => {
        if (!portfolioCase) {
            moocAPI
                .get(`portfolio/case/${caseId}/`)
                .then(res => {
                    if (res === null) {
                        setNotFound(true);
                    } else {
                        setPortfolioCase(res);
                    }
                })
                .catch(error => {
                    throwError(error);
                });
        }
    }, [portfolioCase, caseId, history, mixpanel, throwError]);

    if (notFound) {
        return <Redirect to={'/' + ROUTES.PAGE_NOT_FOUND} />;
    }

    if (!portfolioCase) {
        return <CentredComponent component={<LoadingSpinner />} />;
    }

    return (
        <Fragment>
            <AllowedActionsContext.Provider
                value={portfolioCase.user_permissions}
            >
                <CaseOverview
                    portfolioCase={portfolioCase}
                    setPortfolioCase={setPortfolioCase}
                />
            </AllowedActionsContext.Provider>
        </Fragment>
    );
};

export default CaseOverviewPageContent;
