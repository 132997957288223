import APIService from '../../services/APIService';
import { Session } from './ActivityContent';

export default class ExerciseAPI extends APIService {
    activity: Activity;

    constructor(activity: Activity) {
        super(activity.config.exercise_api_route);
        this.activity = activity;
    }

    startSession(): Promise<any> {
        const userId: string | null = localStorage.getItem('user_id');
        return this.post(
            'sessions/',
            userId ? { context: { user_id: userId } } : undefined,
        );
    }

    sessionDetails(sessionId: string): Promise<Session> {
        return this.get(`sessions/${sessionId}/`);
    }

    completeSession(sessionId: string): Promise<any> {
        const sessionUrl = `sessions/${sessionId}/complete/`;
        if (this.activity.config.exercise_type === 'INVESTIGATION') {
            return this.put(sessionUrl);
        }
        return this.patch(sessionUrl);
    }

    cancelSession(sessionId: string): Promise<any> {
        const sessionUrl = `sessions/${sessionId}/cancel/`;
        if (this.activity.config.exercise_type === 'INVESTIGATION') {
            return this.put(sessionUrl);
        }
        return this.post(sessionUrl);
    }
}
