import React, { useState, Fragment } from 'react';
import usePortal from '../../utils/usePortal';
import { Modal, Button, Form } from 'react-bootstrap';

interface Props {
    show: boolean;
    resourceName: string;
    message: string;
    validationText?: string;
    onClose: () => void;
    onDelete: () => void;
}

const DeleteConfirmationModal: React.FC<Props> = ({
    show,
    resourceName,
    validationText,
    message,
    onClose,
    onDelete,
}) => {
    const target = usePortal('modal-root');
    const [userInput, setUserInput] = useState<string>('');
    const [isInvalid, setIsInvalid] = useState<boolean>(false);

    const validateDelete = () => {
        if (!validationText || userInput === validationText) {
            setIsInvalid(false);
            onDelete();
            onClose();
        } else {
            setIsInvalid(true);
        }
    };

    return (
        <Modal show={show} onHide={onClose} container={target}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Are you sure you want to delete {resourceName}?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message && <p>{message}</p>}
                {validationText && (
                    <Fragment>
                        <p>
                            Please type &quot;{validationText}&quot; below to
                            confirm
                        </p>
                        <Form.Group>
                            <Form.Control
                                type='text'
                                onChange={event =>
                                    setUserInput(event.target.value)
                                }
                                isInvalid={isInvalid}
                            />
                            <Form.Control.Feedback type='invalid'>
                                Your input does not match &quot;{validationText}
                                &quot;
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Fragment>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant='light' onClick={onClose}>
                    Close
                </Button>
                <Button variant='danger' onClick={validateDelete}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteConfirmationModal;
