import { theme } from './theme';

export const scrollBarStyles = {
    '::-webkit-scrollbar': {
        width: '8px',
    },
    '::-webkit-scrollbar-thumb': {
        background: 'gray.600',
        borderRadius: '8px',
    },
    '::-webkit-scrollbar-thumb:hover': {
        background: 'gray.600',
    },
    '::-webkit-scrollbar-track': {
        background: 'gray.200',
        borderRadius: '8px',
    },
};

export const navbarToggleStyles = {
    container: {
        backgroundColor: 'dark.700',
        border: '1px solid',
        borderColor: 'dark.500',
        borderRadius: '10px',
        padding: '5px',
    },
};

export const checkBoxStyles = {
    m: '0px',
    spacing: '20px',
    w: '48%',
    h: '50px',
    pl: '20px',
    borderRadius: 'full',
    _hover: {
        backgroundColor: 'dark.600',
        transition: 'all 500ms',
    },
    _checked: {
        backgroundColor: 'white',
        color: 'black',
        transition: 'all 500ms',
    },
    "span[class*='checkbox__control']:not([data-disabled])": {
        width: '30px',
        height: '30px',
        border: '2px solid',
        borderColor: 'dark.500',
        borderRadius: 'full',
        boxShadow: 'none',
        _checked: {
            bg: 'black',
        },
    },
};

export const getChatStyles = (isTextMode: boolean) => {
    const chatStyles = {
        textMode: {
            chatContainer: {
                h: '100%',
                paddingBottom: '10px',
            },
            historyContainer: {
                w: ['90%', '70%', '70%', '60%', '50%', '45%'],
            },
            avatarMessage: {
                backgroundColor: 'transparent',
                p: '0px 20px 0px 0px',
                // To center the first line with the avatar circle on the left
                // 2.25rem = font-size (1rem) * line-height (1.5)
                // 32px    = height of the avatar circle
                mt: 'calc(2.25rem - 32px)',
            },
            userMessage: {
                backgroundColor: theme.colors.dark['500'],
            },
        },
        avatar: {
            chatContainer: {
                minH: 0,
                flex: 1,
            },
            historyContainer: {
                w: '90%',
            },
            avatarMessage: {
                backgroundColor: theme.colors.dark['500'],
                p: '10px',
            },
            userMessage: {
                backgroundColor: theme.colors.dark['450'],
            },
        },
    };

    return isTextMode ? chatStyles.textMode : chatStyles.avatar;
};
