import React from 'react';
import {
    Badge,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
} from '@chakra-ui/react';
import { ConversationStage } from './types';
import ConsultationFeedback from './ConsultationFeedback';
import MissedItems from './MissedItems';
import { InteractionStageFeedback } from '../types';

type ConsultationFeedbackType = InteractionStageFeedback<ConversationStage>;

const ConsultationFeedbackModalContent: React.FC<ConsultationFeedbackType> = ({
    stage,
    marking_feedback,
    events,
}) => {
    const missedItemsCnt = marking_feedback?.summary.missing_items.length;

    return (
        <Tabs colorScheme='brand.black'>
            <TabList>
                <Tab>Overall</Tab>
                {missedItemsCnt && (
                    <Tab>
                        Missed items
                        <Badge mx={1} colorScheme='red' variant='solid'>
                            {missedItemsCnt}
                        </Badge>
                    </Tab>
                )}
            </TabList>
            <TabPanels>
                <TabPanel>
                    <ConsultationFeedback
                        stage={stage}
                        marking_feedback={marking_feedback}
                        events={events}
                    />
                </TabPanel>
                {missedItemsCnt && (
                    <TabPanel>
                        <MissedItems
                            stage={stage}
                            marking_feedback={marking_feedback}
                            events={events}
                        />
                    </TabPanel>
                )}
            </TabPanels>
        </Tabs>
    );
};

export default ConsultationFeedbackModalContent;
