import React from 'react';
import { FormikValues } from 'formik';
import ROUTES from '../../consts/routes';
import { useHistory, useLocation } from 'react-router-dom';
import BaseRegistrationForm from './BaseRegistrationForm';
import { normalizeEmail } from '../../utils/stringUtils';
import { moocAPI, authService } from '../../services';
import { useStripe } from '@stripe/react-stripe-js';
import queryString from 'query-string';
import { useMixpanel } from '../contexts/MixpanelContext';
import {
    Alert,
    AlertDescription,
    AlertTitle,
    Box,
    Flex,
    Spacer,
} from '@chakra-ui/react';

const B2CPurchaseRegistrationForm: React.FC<{
    product: OneOffProductInformation;
}> = ({ product }) => {
    const mixpanel = useMixpanel();
    const history = useHistory();
    const location = useLocation();
    const stripe = useStripe();

    const onSubmit = (
        values: FormikValues,
        onError: (errorMessage?: string) => void,
    ): void => {
        authService
            .register(
                moocAPI,
                values.firstName,
                values.lastName,
                normalizeEmail(values.registerEmail),
                values.registerPassword,
            )
            .then(success => {
                if (success) {
                    mixpanel?.track('registration', { isPurchasing: true });
                    const params = queryString.parse(location.search);
                    const success_url =
                        'redirect' in params
                            ? params['redirect']?.toString()
                            : ROUTES.COURSES_ALL;

                    return moocAPI
                        .post('products/checkout/', {
                            non_organisation_one_off_product_id: product.id,
                            success_url: `${window.location.origin}/${ROUTES.PAYMENT_PROCESSING}?product_id=${product.id}&redirect=${success_url}&payment_success=true`,
                            cancel_url: `${window.location.origin}/${success_url}?payment_success=false`,
                        })
                        .then(checkoutSession => {
                            mixpanel?.track('payment_started', {
                                product_id: product.id,
                            });
                            stripe?.redirectToCheckout({
                                sessionId: checkoutSession.id,
                            });
                        })
                        .catch(() => {
                            history.push(ROUTES.HOME);
                        });
                } else {
                    onError();
                }
            })
            .catch((error: Error) => {
                onError(error.toString());
            });
    };

    return (
        <Box>
            <Alert colorScheme='blackAlpha' flexDirection='column' mb={4}>
                <AlertTitle
                    w='100%'
                    textAlign='center'
                    borderBottom='2px'
                    py={2}
                    mb={2}
                >
                    Please register to proceed with your payment
                </AlertTitle>
                <AlertDescription w='100%'>
                    <Flex>
                        <Box>{product.name}</Box>
                        <Spacer />
                        <Box>£{(product.price / 100).toFixed(2)}</Box>
                    </Flex>
                </AlertDescription>
            </Alert>
            <BaseRegistrationForm onSubmit={onSubmit} isPurchasing />
        </Box>
    );
};

export default B2CPurchaseRegistrationForm;
