import { useStoreWithArray, useStore } from '../../stores';
import {
    InteractionContextAPI,
    useInteractionContext,
} from '../../utils/interaction/InteractionContext';
import useASR from './useASR';
import useRapport from '../../organisms/AvatarWrapper/useRapport';
import { useCallback, useEffect } from 'react';
import {
    AVATAR_SESSION_COULD_NOT_INITIALISE_MODAL_TEMPLATE,
    AVATAR_SESSION_DISCONNECTED_TIMEOUT_MODAL_TEMPLATE,
    AVATAR_SESSION_DISCONNECTED_TTS_FAILED_MODAL_TEMPLATE,
    AvatarModalName,
} from '../../../../../apps/mooc-frontend/src/components/activities/consultation/components/RapportModalTemplates';
import config from '../../../../../apps/mooc-frontend/src/consts/config';

const VIDEO_CLIP_PATH =
    'polygon(50% 0px, 80% 10%, 100% 35%, 100% 100%, 0% 100%, 0px 35%, 20% 10%)';

if (window.shouldWaitRapportDisconnect === undefined) {
    window.shouldWaitRapportDisconnect = false;
}

const useRapportOrchestrator = (
    rapportComponentId: string,
    avatar_config: RapportAvatarConfig,
    setModal: (modal: AvatarModalName) => void,
    isRapportAsr: boolean,
) => {
    const { micListeners, audioListeners } = useStoreWithArray([
        'micListeners',
        'audioListeners',
    ]);

    const { addActionProcessor, removeActionProcessor } = useInteractionContext(
        InteractionContextAPI,
    );

    const { onMessageRecognised, sendBuffer } = useASR();

    const {
        initialiseRapport: _initialiseRapport,
        speakProcessor,
        applyMask,
        status,
        toggleAvatarAudio,
        listen,
        disconnect,
    } = useRapport(rapportComponentId, avatar_config);

    const initListeners = useCallback(() => {
        if (isRapportAsr) {
            micListeners.add(listen);
            micListeners.add(sendBuffer);
        }

        audioListeners.add(toggleAvatarAudio);
        const onTtsTimeout = () => {
            disconnect();
            setModal(
                AVATAR_SESSION_DISCONNECTED_TTS_FAILED_MODAL_TEMPLATE.code,
            );
        };
        addActionProcessor('audio', (action, activeStage) => {
            return speakProcessor(
                action,
                activeStage,
                parseInt(config.REACT_APP_RAPPORT_SPEAK_TIMEOUT_SECONDS!),
                onTtsTimeout,
            );
        });
    }, [
        isRapportAsr,
        addActionProcessor,
        audioListeners,
        disconnect,
        listen,
        micListeners,
        sendBuffer,
        setModal,
        speakProcessor,
        toggleAvatarAudio,
    ]);

    const cleanupListeners = useCallback(() => {
        removeActionProcessor('audio');
        audioListeners.delete(toggleAvatarAudio);
        if (isRapportAsr) {
            micListeners.delete(listen);
            micListeners.delete(sendBuffer);
        }
    }, [
        isRapportAsr,
        audioListeners,
        listen,
        micListeners,
        removeActionProcessor,
        sendBuffer,
        toggleAvatarAudio,
    ]);

    const initRapport = useCallback(
        (onConnect?: () => void, onError?: (err: any) => void) => {
            const isMicOn = isRapportAsr ? useStore.getState().isMicOn : false;

            _initialiseRapport(
                () => {
                    onConnect && onConnect();
                    applyMask(VIDEO_CLIP_PATH);
                    initListeners();
                },
                err => {
                    onError && onError(err);
                    if (!!err.code && err.code.includes('TIMEOUT')) {
                        setModal(
                            AVATAR_SESSION_DISCONNECTED_TIMEOUT_MODAL_TEMPLATE.code,
                        );
                    } else {
                        setModal(
                            AVATAR_SESSION_COULD_NOT_INITIALISE_MODAL_TEMPLATE.code,
                        );
                    }
                    cleanupListeners();
                },
                onMessageRecognised,
                {
                    micRequired: isRapportAsr,
                    micMuted: !isMicOn,
                },
            );
        },
        // These are all methods and are expected not to change
        [
            isRapportAsr,
            _initialiseRapport,
            applyMask,
            cleanupListeners,
            initListeners,
            setModal,
            onMessageRecognised,
        ],
    );

    useEffect(() => {
        console.debug('Avatar wrapper load: initialising rapport');
        let isMounted = true;
        const manageConnection = () => {
            isMounted && initRapport();
        };

        // This covers two cases
        // 1. Switching between text and avatar where the disconnect does not complete before going back to avatar mode
        // 2. During development when reloading causes this effect to clean-up and run again instantly
        if (window.shouldWaitRapportDisconnect) {
            window.rapportDisconnectedPromise = manageConnection;
        } else {
            manageConnection();
        }

        return () => {
            console.debug('Avatar wrapper un-load: disconnecting rapport');
            cleanupListeners();
            isMounted = false;

            window.shouldWaitRapportDisconnect = true;
            disconnect()
                .then(() => {
                    window.shouldWaitRapportDisconnect = false;
                    if (window.rapportDisconnectedPromise) {
                        window.rapportDisconnectedPromise();
                        window.rapportDisconnectedPromise = undefined;
                    }
                })
                .catch(e => {
                    console.error(e);
                    window.shouldWaitRapportDisconnect = false;
                });
        };
    }, [cleanupListeners, disconnect, initRapport]);

    return { status, initRapport, initListeners, cleanupListeners };
};

export default useRapportOrchestrator;
