import React from 'react';
import {
    Box,
    Button,
    Center,
    Collapse,
    ColorProps,
    Flex,
    HStack,
    SimpleGrid,
    Spacer,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import { Subtheme } from './types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheck,
    faChevronDown,
    faCircle,
    faTimes,
    IconDefinition,
} from '@fortawesome/free-solid-svg-icons';

const getSubthemeColor = (score: number): ColorProps['color'] => {
    if (score === 100) return 'green.500';
    if (score < 70) return 'red.500';
    return 'yellow.500';
};

const getSubthemeIcon = (score: number): IconDefinition => {
    if (score === 100) return faCheck;
    if (score < 70) return faTimes;
    return faCircle;
};

const ChoiceBox: React.FC<{
    borderColor?: ColorProps['color'];
    heading?: string;
    text: string;
}> = ({ borderColor, heading, text }) => {
    return (
        <Center
            p={2}
            flexDirection='column'
            borderRadius={8}
            borderWidth={borderColor ? '2px' : undefined}
            borderColor={borderColor}
        >
            {heading && <Text color={borderColor}>{heading}</Text>}
            <Text>{text}</Text>
        </Center>
    );
};

const FeedbackSubtheme: React.FC<Subtheme> = ({
    title,
    explanation,
    feedback,
}) => {
    const { isOpen, onToggle } = useDisclosure();

    return (
        <Box borderBottomWidth='2px' borderColor='brand.black.200' py={2}>
            <Flex px={4} py={2} alignItems='center'>
                <Flex
                    flexGrow={1}
                    mr={4}
                    color={getSubthemeColor(feedback.score)}
                >
                    <HStack>
                        <FontAwesomeIcon
                            icon={getSubthemeIcon(feedback.score)}
                        />
                        <Text>{title}</Text>
                    </HStack>
                    <Spacer />
                    <Box>
                        {feedback.awarded_points} / {feedback.available_points}
                    </Box>
                </Flex>
                <Button
                    variant='ghost'
                    colorScheme='brand.black'
                    rightIcon={
                        <FontAwesomeIcon
                            icon={faChevronDown}
                            style={{
                                transition: 'transform 0.2s ease 0s',
                                transform: isOpen
                                    ? 'rotate(-180deg)'
                                    : undefined,
                            }}
                        />
                    }
                    onClick={onToggle}
                >
                    See {isOpen ? 'less' : 'more'}
                </Button>
            </Flex>

            <Collapse in={isOpen}>
                <Text>{explanation}</Text>
                <SimpleGrid minChildWidth='180px' spacing='10px'>
                    <ChoiceBox
                        text={feedback.user_response}
                        borderColor={getSubthemeColor(feedback.score)}
                        heading={'Your choice'}
                    />
                    {feedback.choice_alternatives?.map(alternative => (
                        <ChoiceBox
                            key={alternative.text}
                            text={alternative.text}
                            borderColor={
                                alternative.is_optimal
                                    ? getSubthemeColor(feedback.score)
                                    : undefined
                            }
                            heading={
                                alternative.is_optimal
                                    ? 'Better choice'
                                    : undefined
                            }
                        />
                    ))}
                </SimpleGrid>
            </Collapse>
        </Box>
    );
};

export default FeedbackSubtheme;
