import { useCallback, useRef } from 'react';

interface WebSocketOptions {
    generateWebsocketUrl: () => Promise<string>;
    retryAttempts?: number;
    retryDelay?: number;
    onMessage: (event: string) => void;
    onError?: (event: Error) => void;
}

export const useWebSocket = ({
    generateWebsocketUrl,
    retryAttempts = 3,
    retryDelay = 1000,
    onMessage,
    onError,
}: WebSocketOptions) => {
    const retriesRef = useRef(0);
    const shouldRetryRef = useRef(false);
    const wsRef = useRef<WebSocket | null>(null);
    const reconnectTimeoutRef = useRef<NodeJS.Timeout>();

    const connect = useCallback(
        async (
            onStateChanged: (
                connected: boolean,
                isNewConnection: boolean,
            ) => void,
        ) => {
            console.log('[useWebsocket]: connect called...');
            shouldRetryRef.current = true;
            if (wsRef.current?.readyState === WebSocket.OPEN) {
                console.log('[useWebsocket]: connection already open');
                onStateChanged(true, false);
                return;
            }

            try {
                const url = await generateWebsocketUrl();
                wsRef.current = new WebSocket(url);

                wsRef.current.onopen = () => {
                    console.log(
                        '[useWebsocket]: connection successfully opened',
                    );
                    retriesRef.current = 0; // Reset retry counter on successful connection
                    onStateChanged(true, true);
                };

                wsRef.current.onclose = e => {
                    const didCloseNormally =
                        [1000].includes(e.code) && e.wasClean;
                    const shouldRetry =
                        shouldRetryRef.current && !didCloseNormally;
                    console.log('[useWebsocket]: connection closed', e);

                    if (shouldRetry) {
                        // Try to reconnect if we haven't exceeded retry attempts
                        if (retriesRef.current < retryAttempts) {
                            retriesRef.current += 1;
                            reconnectTimeoutRef.current = setTimeout(() => {
                                console.log(
                                    `[useWebsocket]: reconnecting... attempt=${retriesRef.current}`,
                                );
                                connect(onStateChanged);
                            }, retryDelay * retriesRef.current); // Exponential backoff
                        } else {
                            retriesRef.current = 0;
                            const error = new Error(
                                `WebSocket closed abnormally. Code: ${e.code}, Reason: ${e.reason}`,
                            );
                            onError?.(error);
                            onStateChanged(false, false);
                        }
                    } else {
                        retriesRef.current = 0;
                        onStateChanged(false, false);
                    }
                };

                wsRef.current.onerror = event =>
                    console.log('[useWebsocket]: on error', event);

                wsRef.current.onmessage = ({ data }) => onMessage(data);
            } catch (err) {
                const error =
                    err instanceof Error
                        ? err
                        : new Error(`Unknown WebSocket error: ${err}`);
                console.error('WebSocket connection error:', error);
                onError?.(error);
                onStateChanged(false, false);
            }
        },
        [
            onError,
            onMessage,
            retryDelay,
            retryAttempts,
            shouldRetryRef,
            generateWebsocketUrl,
        ],
    );

    const disconnect = useCallback(() => {
        console.log('[useWebsocket]: disconnect called...');

        // Clear any pending reconnection attempts
        shouldRetryRef.current = false;
        if (reconnectTimeoutRef.current) {
            clearTimeout(reconnectTimeoutRef.current);
        }

        if (wsRef.current) {
            wsRef.current.close(1000);
            wsRef.current = null;
        }
    }, [shouldRetryRef]);

    const send = useCallback((data: string | ArrayBuffer | Blob) => {
        if (wsRef.current?.readyState === WebSocket.OPEN) {
            wsRef.current.send(data);
            return true;
        }
        return false;
    }, []);

    const sendEvent = useCallback(
        (event: TranscriptionRequest) => send(JSON.stringify(event)),
        [send],
    );

    return {
        send,
        sendEvent,
        connect,
        disconnect,
    };
};
