import { Formik, FormikValues } from 'formik';
import React, { Fragment, useCallback, useContext, useState } from 'react';
import {
    Button,
    Carousel,
    Col,
    Container,
    Form,
    Row,
    Spinner,
} from 'react-bootstrap';
import { ErrorsCodes, moocAPI } from '../../services';
import {
    ACTIVITY_FEEDBACK_NEGATIVE_FORM_PLACEHOLDER,
    ACTIVITY_FEEDBACK_NEGATIVE_FORM_TITLE,
    ACTIVITY_FEEDBACK_NEGATIVE_LABEL,
    ACTIVITY_FEEDBACK_POSITIVE_FORM_PLACEHOLDER,
    ACTIVITY_FEEDBACK_POSITIVE_FORM_TITLE,
    ACTIVITY_FEEDBACK_POSITIVE_LABEL,
} from '../../consts/messages';
import CharacterCounter from '../../hocs/CharacterCounter';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CentredComponent from '../../hocs/CentredComponent';
import { useQueryCache } from '../contexts/QueryCache';
import SurveyForm, { SurveyQuestion } from './SurveyForm';
import StudentFeaturesContext from '../contexts/StudentFeaturesContext';
import * as Sentry from '@sentry/browser';
import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { isExercise } from '../../utils/activityUtils';

interface Props {
    onSkip: () => void;
    onSubmit: () => void;
    activity: Activity;
    context: any;
}

const options = [
    {
        icon: faThumbsUp,
        textLabel: ACTIVITY_FEEDBACK_POSITIVE_LABEL,
        formTitle: ACTIVITY_FEEDBACK_POSITIVE_FORM_TITLE,
        formPlaceholder: ACTIVITY_FEEDBACK_POSITIVE_FORM_PLACEHOLDER,
        enumValue: 'positive',
    },
    {
        icon: faThumbsDown,
        textLabel: ACTIVITY_FEEDBACK_NEGATIVE_LABEL,
        formTitle: ACTIVITY_FEEDBACK_NEGATIVE_FORM_TITLE,
        formPlaceholder: ACTIVITY_FEEDBACK_NEGATIVE_FORM_PLACEHOLDER,
        enumValue: 'negative',
    },
];

const initialValues: {
    choiceFeedback: number | null;
    textFeedback: string;
} = {
    choiceFeedback: null,
    textFeedback: '',
};

const useNPSQuestion = () => {
    const { data: surveyQuestions } = useQueryCache<SurveyQuestion[]>(
        'survey/questions/',
        { refreshOnMount: true },
    );
    if (surveyQuestions) return surveyQuestions.find(sq => sq.slug === 'nps');
    else return undefined;
};

const StudentActivityFeedbackForm: React.FC<Props> = ({
    onSkip,
    onSubmit,
    activity,
    context,
}) => {
    const [studentActivityFeedbackId, setStudentActivityFeedbackId] = useState<
        number | null
    >(null);
    const [disableClick, setDisableClick] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const { trialData } = useContext(StudentFeaturesContext);

    const onSubmitError = (error: any): void => {
        if (error.name === ErrorsCodes.ACTION_ALREADY_PERFORMED) {
            onSubmit();
            return;
        }
        Sentry.captureException(error);
        onSkip();
    };

    const handleSubmit = (values: FormikValues) => {
        setIsSubmitting(true);
        moocAPI
            .patch(
                `activity/${activity.id}/feedback/${studentActivityFeedbackId}/`,
                {
                    rating: options[values.choiceFeedback].enumValue,
                    text: values.textFeedback,
                    partial: false,
                    context,
                },
            )
            .then(() => {
                onSubmit();
            })
            .catch(onSubmitError)
            .finally(() => setIsSubmitting(false));
    };

    const onChoice = (choice: number): void => {
        setDisableClick(true);
        if (studentActivityFeedbackId === null) {
            moocAPI
                .post(`activity/${activity.id}/feedback/create/`, {
                    rating: options[choice].enumValue,
                    text: '',
                    partial: true,
                    context,
                })
                .then(res => {
                    setStudentActivityFeedbackId(res.id);
                })
                .catch(onSubmitError)
                .finally(() => {
                    setDisableClick(false);
                });
        } else {
            moocAPI
                .patch(
                    `activity/${activity.id}/feedback/${studentActivityFeedbackId}/`,
                    {
                        rating: options[choice].enumValue,
                        text: '',
                        partial: true,
                        context,
                    },
                )
                .catch(onSubmitError)
                .finally(() => {
                    setDisableClick(false);
                });
        }
    };

    const npsQuestion = useNPSQuestion();
    const [npsSelected, setNpsSelected] = useState(false);
    const onSubmitNPS = useCallback(
        (values: any) => {
            setNpsSelected(true);
            return moocAPI.post(
                `survey/responses/?question=${npsQuestion?.id}`,
                {
                    content: values,
                },
            );
        },
        [npsQuestion],
    );
    const onEditNPS = useCallback(
        (responseId: number, values: any) =>
            moocAPI.patch(`survey/responses/${responseId}/`, {
                content: values,
            }),
        [],
    );

    return (
        <Fragment>
            <style>{`
                [type=radio] {
                    opacity: 0;
                    width: 0;
                    height: 0;
                }

                .form-check-inline {
                    margin-left: 0px;
                }

                [type=radio] + label {
                    cursor: pointer;
                }
                
                [type=radio] + label .row-image {
                    border-radius: 20px;
                }
                
                [type=radio]:checked + label .row-image {
                    border: none;
                    background-color: #d3d3d3;
                    transition: .2s;
                }
                
                .carousel-control-prev {
                    top: -5px;
                    left: -20px;
                    bottom: unset;
                    width: 40px;
                    height: 40px;
                    
                }
                
                .carousel-control-next {
                    top: -5px;
                    right: -10px;
                    bottom: unset;
                    width: 40px;
                    height: 40px;
                    
                }
            `}</style>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                {({
                    values,
                    handleSubmit,
                    setFieldValue,
                    handleChange,
                }): JSX.Element => (
                    <Fragment>
                        <Form
                            noValidate
                            onSubmit={handleSubmit}
                            id='activity-feedback-form'
                        />
                        <Carousel
                            controls={values.choiceFeedback !== null}
                            prevIcon={
                                activeIndex ? (
                                    <FontAwesomeIcon
                                        size='lg'
                                        color='black'
                                        icon={faChevronLeft}
                                    />
                                ) : null
                            }
                            nextIcon={
                                values.choiceFeedback !== null &&
                                activeIndex === 0 ? (
                                    <FontAwesomeIcon
                                        size='lg'
                                        color='black'
                                        icon={faChevronRight}
                                    />
                                ) : null
                            }
                            indicators={false}
                            interval={null}
                            activeIndex={activeIndex}
                            onSelect={(eventKey: number) =>
                                setActiveIndex(eventKey)
                            }
                        >
                            <Carousel.Item>
                                <Container>
                                    <Form.Group controlId='choiceFeedback'>
                                        <Row className='justify-content-around flex-nowrap'>
                                            {options.map((val, index) => (
                                                <Col key={index} xs={4}>
                                                    <Form.Check
                                                        disabled={disableClick}
                                                        className='px-2'
                                                        id={`${val.textLabel}-choice-feedback-radio`}
                                                        form='activity-feedback-form'
                                                    >
                                                        <Form.Check.Input
                                                            type='radio'
                                                            name='choiceFeedback'
                                                            onChange={() => {
                                                                setFieldValue(
                                                                    'choiceFeedback',
                                                                    index,
                                                                    false,
                                                                );
                                                                // have to explicitly compare with null because 0 is an option
                                                                onChoice(index);
                                                            }}
                                                        />
                                                        <Form.Check.Label className='w-100'>
                                                            <Row
                                                                className='py-4 row-image align-items-center justify-content-around'
                                                                onTransitionEnd={() => {
                                                                    if (
                                                                        values.choiceFeedback !==
                                                                        null
                                                                    ) {
                                                                        setActiveIndex(
                                                                            1,
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    size='6x'
                                                                    icon={
                                                                        val.icon
                                                                    }
                                                                />
                                                            </Row>
                                                            <Row
                                                                className='mt-3 align-items-center justify-content-around'
                                                                style={{
                                                                    fontSize:
                                                                        '1.2rem',
                                                                }}
                                                            >
                                                                {val.textLabel}
                                                            </Row>
                                                        </Form.Check.Label>
                                                    </Form.Check>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Form.Group>
                                </Container>
                            </Carousel.Item>
                            <Carousel.Item>
                                <Container>
                                    {values.choiceFeedback !== null &&
                                        (options[values.choiceFeedback]
                                            .textLabel ===
                                            ACTIVITY_FEEDBACK_POSITIVE_LABEL &&
                                        npsQuestion &&
                                        trialData.is_trial &&
                                        isExercise(activity) &&
                                        [
                                            'DIALOGUE_CHOICES',
                                            'CONVERSATION',
                                        ].includes(
                                            activity.config.exercise_type,
                                        ) ? (
                                            <Fragment>
                                                <CentredComponent
                                                    className='mb-3'
                                                    style={{
                                                        fontSize: '1.2rem',
                                                    }}
                                                >
                                                    {npsQuestion.title}
                                                </CentredComponent>
                                                <SurveyForm
                                                    surveyTemplate={
                                                        npsQuestion.template
                                                    }
                                                    onSubmit={onSubmitNPS}
                                                    onEdit={onEditNPS}
                                                    onSuccess={onSubmit}
                                                    onError={onSkip}
                                                />
                                                {!npsSelected && (
                                                    <Row
                                                        noGutters
                                                        className='justify-content-end align-items-center mt-3'
                                                    >
                                                        <Col
                                                            xs='auto'
                                                            className='mr-2'
                                                        >
                                                            Need more time to
                                                            explore?
                                                        </Col>
                                                        <Col xs='auto'>
                                                            <Button
                                                                size='sm'
                                                                variant='outline-primary'
                                                                onClick={onSkip}
                                                            >
                                                                Ask me later
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                )}
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                <CentredComponent
                                                    className='mb-3'
                                                    style={{
                                                        fontSize: '1.2rem',
                                                    }}
                                                >
                                                    {
                                                        options[
                                                            values
                                                                .choiceFeedback
                                                        ].formTitle
                                                    }
                                                </CentredComponent>
                                                <Form.Group>
                                                    <CharacterCounter
                                                        content={
                                                            values.textFeedback
                                                        }
                                                        charLimit={500}
                                                    >
                                                        <Form.Control
                                                            name='textFeedback'
                                                            as='textarea'
                                                            form='activity-feedback-form'
                                                            value={
                                                                values.textFeedback
                                                            }
                                                            maxLength={500}
                                                            rows={3}
                                                            placeholder={
                                                                options[
                                                                    values
                                                                        .choiceFeedback
                                                                ]
                                                                    .formPlaceholder
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                    </CharacterCounter>
                                                </Form.Group>
                                                <Row
                                                    noGutters
                                                    className='justify-content-end'
                                                >
                                                    <Button
                                                        type='submit'
                                                        className='d-flex align-items-center'
                                                        form='activity-feedback-form'
                                                        disabled={isSubmitting}
                                                    >
                                                        {isSubmitting ? (
                                                            <>
                                                                <Spinner
                                                                    as='span'
                                                                    animation='border'
                                                                    size='sm'
                                                                    role='status'
                                                                    aria-hidden='true'
                                                                    className='mr-2'
                                                                />
                                                                Submitting
                                                            </>
                                                        ) : (
                                                            <>Submit</>
                                                        )}
                                                    </Button>
                                                </Row>
                                            </Fragment>
                                        ))}
                                </Container>
                            </Carousel.Item>
                        </Carousel>
                    </Fragment>
                )}
            </Formik>
        </Fragment>
    );
};

export default StudentActivityFeedbackForm;
