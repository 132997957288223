import React, { Fragment } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { truncateContinuousText } from '../../utils/stringUtils';

interface Props {
    options: DropzoneOptions;
    file: File | null;
}

const FileUpload: React.FC<Props> = ({ options, file }) => {
    const { getRootProps, getInputProps, open } = useDropzone(options);
    return (
        <Fragment>
            <input {...getInputProps()} />

            <div {...getRootProps()}>
                <Row className='justify-content-center mb-2'>
                    <FontAwesomeIcon
                        icon={faFileUpload}
                        size='8x'
                        color='white'
                    />
                </Row>
                {file && (
                    <Row className='justify-content-center mb-2 px-5'>
                        <p
                            style={{
                                color: 'white',
                                fontSize: '1.2rem',
                            }}
                        >
                            {truncateContinuousText(file.name, 30)}
                        </p>
                    </Row>
                )}
                <Row className='justify-content-center mt-2 mb-5'>
                    <style>
                        {`
                  .btn-outline-light:hover {
                    color: var(--primary)
                  }
                `}
                    </style>
                    <Button
                        size='lg'
                        className='px-5'
                        variant='outline-light'
                        style={{ borderRadius: '10px' }}
                        onClick={open}
                    >
                        Choose File
                    </Button>
                </Row>

                <Row className='justify-content-center'>
                    <p style={{ color: 'white', fontSize: '1.6rem' }}>
                        Or <b>drag and drop</b> on this surface
                    </p>
                </Row>
            </div>
        </Fragment>
    );
};

export default FileUpload;
