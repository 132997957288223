import { useCallback, useState } from 'react';
import { flushSync } from 'react-dom';

const useSyncQueue = <T>(
    initial?: T[],
): [T, (elem: T) => void, () => void, T[]] => {
    const [queue, setQueue] = useState<T[]>(initial || []);

    const push = useCallback((elem: T): void => {
        return flushSync(() => setQueue(q => [...q, elem]));
    }, []);

    const pop = useCallback((): void => {
        flushSync(() => setQueue(q => q.slice(1)));
    }, []);

    return [queue[0], push, pop, queue];
};

export default useSyncQueue;
