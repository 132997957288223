import React from 'react';
import getHumanReadableFromDate from '../../utils/dateUtils';
import { chakra, Text } from '@chakra-ui/react';

const LabeledDate: React.FC<{
    date: string;
    label: string;
    dateFormatter?: (date: string) => string;
}> = ({ date, label, dateFormatter = getHumanReadableFromDate }) => (
    <Text mb={0}>
        {label}{' '}
        <chakra.span fontWeight='bold'>{dateFormatter(date)}</chakra.span>
    </Text>
);

export default LabeledDate;
