import * as React from 'react';
import { Fragment, useState } from 'react';
import { HStack, Stack, Text } from '@chakra-ui/react';
import CitationButton from '../CitationButton/CitationButton';
import { getChatStyles } from '../../theme/consts';
import FeedbackButton from '../FeedbackButton/FeedbackButton';
import AgentActionFeedbackModal from '../../molecules/AgentActionFeedbackModal/AgentActionFeedbackModal';
import useFeedbackSubmit from '../../hooks/useFeedbackSubmit';

interface Props {
    actionId: number;
    showFeedbackButtons: boolean;
    text: AvatarText;
    onCitationClick: (citation: Citation) => void;
    isTextMode: boolean;
}

const AvatarMessage: React.FC<Props> = ({
    actionId,
    showFeedbackButtons,
    text,
    onCitationClick,
    isTextMode,
}) => {
    const [submitted, setSubmitted] = useState<FeedbackPayload['type'] | null>(
        null,
    );
    const [messageHover, setMessageHover] = useState(false);

    const styles = getChatStyles(isTextMode);

    const {
        handleFeedbackSubmit,
        isModalOpen,
        setIsModalOpen,
    } = useFeedbackSubmit(actionId, setSubmitted);

    return (
        <Fragment>
            <AgentActionFeedbackModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSubmit={handleFeedbackSubmit}
            />
            <Stack
                spacing={0}
                onMouseEnter={() => setMessageHover(true)}
                onMouseLeave={() => setMessageHover(false)}
            >
                <Text
                    borderRadius='10px'
                    m='0'
                    color='white'
                    wordBreak='break-word'
                    {...styles.avatarMessage}
                >
                    {text.map((item, i) => {
                        if (typeof item === 'string') {
                            return <Fragment key={i}>{item}</Fragment>;
                        } else {
                            return (
                                <CitationButton
                                    key={i}
                                    citationId={item.id}
                                    onClick={() => {
                                        onCitationClick(item);
                                    }}
                                />
                            );
                        }
                    })}
                </Text>
                <HStack h='40px' m='0px' justifyContent='flex-start'>
                    {(showFeedbackButtons || submitted || messageHover) && (
                        <Fragment>
                            <FeedbackButton
                                type='negative'
                                isSubmitted={submitted}
                                onSubmit={() => setIsModalOpen(true)}
                            />
                            <FeedbackButton
                                type='positive'
                                isSubmitted={submitted}
                                onSubmit={() =>
                                    handleFeedbackSubmit('positive')
                                }
                            />
                        </Fragment>
                    )}
                </HStack>
            </Stack>
        </Fragment>
    );
};

export default AvatarMessage;
