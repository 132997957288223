import React, {
    Fragment,
    JSXElementConstructor,
    ReactElement,
    ReactNode,
    useEffect,
    useMemo,
} from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { DOMContainer } from 'react-overlays/useWaitForDOMRef';
import { OverlayInjectedProps } from 'react-bootstrap/Overlay';
import { v4 as uuid } from 'uuid';
import fscreen from 'fscreen';
import { OverlayTriggerRenderProps } from 'react-bootstrap/OverlayTrigger';
import { isMobile } from 'react-device-detect';

interface Props {
    hoverMessage: string;
    side?: any;
    hoverDisabled?: boolean;
    fontSize?: number;
    show?: boolean;
    children:
        | ReactElement<any, string | JSXElementConstructor<any>>
        | ((props: OverlayTriggerRenderProps) => ReactNode);
    container?: DOMContainer;
}

const TooltipDisplay = React.forwardRef<
    HTMLDivElement,
    { fontSize?: number } & Omit<OverlayInjectedProps, 'ref'>
>(({ fontSize, popper, children, ...props }, ref) => {
    const id = useMemo(uuid, []);
    const style = {
        ...props.style,
        fontSize: fontSize,
    };

    useEffect(() => {
        popper.scheduleUpdate();
    }, [children, popper]);

    return (
        <Tooltip ref={ref} id={id} {...props} style={style}>
            {children}
        </Tooltip>
    );
});
TooltipDisplay.displayName = 'TooltipDisplay';

const WithTooltip: React.FC<Props> = ({
    hoverMessage,
    hoverDisabled,
    show,
    side,
    fontSize,
    children,
    container,
}) => {
    if (hoverDisabled || isMobile) {
        return <Fragment>{children}</Fragment>;
    }

    return (
        <OverlayTrigger
            // Although the container prop will not be found in the docs, the OverlayTrigger passes any unknown props
            // further down to the Overlay
            container={
                container ||
                (fscreen.fullscreenElement
                    ? (fscreen.fullscreenElement as HTMLElement)
                    : undefined)
            }
            show={show}
            placement={side ?? 'top'}
            overlay={
                <TooltipDisplay fontSize={fontSize}>
                    {hoverMessage}
                </TooltipDisplay>
            }
        >
            {children}
        </OverlayTrigger>
    );
};

export default WithTooltip;
