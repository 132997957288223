import { useEffect, useRef } from 'react';

const useScrollRef = (isEnabled: boolean) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isEnabled) {
            // I'm not a big fan of timeouts, but if the scroll is called instantly it does not work
            const t = setTimeout(() => {
                if (ref.current) {
                    ref.current?.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
            return () => clearTimeout(t);
        }
    }, [ref, isEnabled]);

    return ref;
};

export default useScrollRef;
