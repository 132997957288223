import React, { Fragment, useState, useEffect, useContext } from 'react';
import CentredComponent from '../../hocs/CentredComponent';
import { Row, Button, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ActionsDropdown, { Edit, Delete } from './actions/ActionsDropdown';
import { getReadableTimeFromSeconds } from '../../utils/timeUtils';
import CaseAnnotationForm from '../forms/CaseAnnotationForm';
import { moocAPI } from '../../services';
import useAsyncError from '../../hooks/useAsyncError';
import SelectableNoteTag from './SelectableNoteTag';
import AllowedActionsContext from '../contexts/AllowedActionsContext';
import { useQuery } from '@tanstack/react-query';

export const AnnotationTypeName: Record<Annotation['type'], string> = {
    grading_item: 'Feedback item',
    note: 'Note',
};

const AnnotationAuthor: React.FC<{ author: string }> = ({ author }) => {
    if (author) {
        return (
            <p
                style={{ fontSize: '0.8rem' }}
                className='text-right mb-1 mt-2 mr-3'
            >
                {author}
            </p>
        );
    } else {
        return <Fragment />;
    }
};
const AnnotationDisplay: React.FC<{
    annotation: any;
    highlighted: boolean;
    seekTo: (seconds: number) => void;
    onEdit: () => void;
    onDelete: () => void;
}> = ({ annotation, highlighted = false, seekTo, onEdit, onDelete }) => {
    const userPermissions = useContext(AllowedActionsContext);
    const { data: user } = useQuery(['user-data'], () =>
        moocAPI.get('student/user'),
    );

    const getAnnotationContent = (annotation: Annotation) => (
        <Fragment>
            {annotation.tag && <SelectableNoteTag tag={annotation.tag} />}
            <p
                className='mt-2 mb-0'
                style={{
                    fontSize:
                        annotation.type === 'note' ? '0.85rem' : '0.95rem',
                    fontWeight:
                        annotation.type === 'grading_item' ? 'bold' : 'initial',
                }}
            >
                {annotation.content}
            </p>
            <AnnotationAuthor author={annotation.owner_details.name} />
        </Fragment>
    );

    return (
        <Row
            noGutters
            className={`border ${
                highlighted ? 'border-primary border-3' : ''
            } rounded align-items-center m-1 py-1`}
            style={{ cursor: 'pointer' }}
            onClick={() => seekTo(annotation.timestamp)}
        >
            <Col className='flex-grow-0 px-3 text-muted'>
                {getReadableTimeFromSeconds(annotation.timestamp)}
            </Col>
            <Col className='pr-1'>{getAnnotationContent(annotation)}</Col>
            <Col
                className='flex-grow-0'
                onClick={(e: React.MouseEvent) => e.stopPropagation()}
            >
                <ActionsDropdown>
                    {user?.id === annotation.owner_details.id && (
                        <Edit onEdit={onEdit} />
                    )}
                    {(user?.id === annotation.owner_details.id ||
                        userPermissions.includes('delete_annotations')) && (
                        <Delete onDelete={onDelete} />
                    )}
                </ActionsDropdown>
            </Col>
        </Row>
    );
};

interface Props {
    caseId: number;
    annotationsInitial: Annotation[];
    seekTo: (seconds: number) => void;
    currentTime: number;
}

const WorkflowAnnotations: React.FC<Props> = ({
    caseId,
    annotationsInitial,
    seekTo,
    currentTime,
}) => {
    const [annotations, setAnnotations] = useState<Annotation[]>(
        annotationsInitial,
    );
    const [annotationFormData, setAnnotationFormData] = useState<
        Annotation | undefined
    >(undefined);
    const [tags, setTags] = useState<Tag[] | null>(null);
    const [activeTimestamp, setActiveTimestamp] = useState(0);
    const throwAsyncError = useAsyncError();

    const createAnnotationClickHandler = (
        annotationType: Annotation['type'],
    ) => {
        setAnnotationFormData({
            type: annotationType,
            timestamp: currentTime,
            content: '',
            owner_details: {
                name: '',
                id: 0,
            },
            tag: undefined,
        });
    };

    const userPermissions = useContext(AllowedActionsContext);

    useEffect(() => {
        let noActiveTimestamp = true;
        for (let i = annotations.length - 1; i >= 0; i--) {
            if (annotations[i].timestamp <= currentTime) {
                setActiveTimestamp(annotations[i].timestamp);
                noActiveTimestamp = false;
                break;
            }
        }
        if (noActiveTimestamp) setActiveTimestamp(-1);
    }, [currentTime, annotations]);

    useEffect(() => {
        moocAPI
            .get('portfolio/cases/annotations/tags/')
            .then(setTags)
            .catch(throwAsyncError);
    }, [throwAsyncError]);

    return (
        <Fragment>
            <CentredComponent
                className='my-3'
                component={<h2 className='h2 text-uppercase'> Workflow </h2>}
            />
            {userPermissions.includes('add_annotations') && (
                <Row className='justify-content-center my-3'>
                    {Object.keys(AnnotationTypeName).map(type => (
                        <Button
                            key={type}
                            variant={
                                annotationFormData?.type === type
                                    ? 'primary'
                                    : 'outline-primary'
                            }
                            className='rounded-pill mx-3 my-1'
                            onClick={() =>
                                createAnnotationClickHandler(
                                    type as Annotation['type'],
                                )
                            }
                        >
                            <FontAwesomeIcon icon={faPlus} />{' '}
                            {AnnotationTypeName[type as Annotation['type']]}
                        </Button>
                    ))}
                </Row>
            )}
            {annotationFormData && (
                <CaseAnnotationForm
                    caseId={caseId}
                    annotationData={annotationFormData}
                    tags={tags?.filter(
                        t => t.for_annotation_type === annotationFormData?.type,
                    )}
                    setAnnotations={setAnnotations}
                    onCancel={() => {
                        setAnnotationFormData(undefined);
                    }}
                    currentTime={currentTime}
                />
            )}
            <div style={{ height: 'calc(100vh - 15rem)', overflowY: 'auto' }}>
                {annotations.map((annotation, index) => (
                    <Fragment key={index}>
                        {' '}
                        <AnnotationDisplay
                            annotation={annotation}
                            highlighted={
                                annotation.timestamp === activeTimestamp
                            }
                            seekTo={seekTo}
                            onEdit={() => {
                                setAnnotationFormData(annotation);
                                setAnnotations(old =>
                                    old.filter(a => a.id !== annotation.id),
                                );
                            }}
                            onDelete={() => {
                                moocAPI
                                    .delete(
                                        `portfolio/case/${caseId}/annotation/${annotation.id}/`,
                                    )
                                    .then(() =>
                                        setAnnotations(old =>
                                            old.filter(
                                                a => a.id !== annotation.id,
                                            ),
                                        ),
                                    );
                            }}
                        />
                    </Fragment>
                ))}
            </div>
        </Fragment>
    );
};

export default WorkflowAnnotations;
