import { Box, Flex, Heading } from '@chakra-ui/react';
import React, { Fragment } from 'react';
import { DialogueChoicesStage, Section } from './types';
import { InteractionStageFeedback } from '../types';
import FeedbackSubtheme from './DialogueChoicesFeedbackSubtheme';

const FeedbackSection: React.FC<Section> = ({ feedback, title, themes }) => {
    return (
        <Box>
            <Flex
                justifyContent='space-between'
                color='white'
                bgColor='brand.black.400'
                px={4}
                py={2}
                fontWeight='bold'
            >
                <Box>{title}</Box>
                <Box>
                    {feedback.score}% ({feedback.awarded_points} out of{' '}
                    {feedback.available_points})
                </Box>
            </Flex>
            <Box>
                {themes
                    .filter(theme => theme.feedback.available_points > 0)
                    .map(theme => {
                        const hasMatchedSubthemes = theme.subthemes.some(
                            subtheme => !!subtheme.feedback.user_response,
                        );
                        return (
                            <Fragment key={theme.title}>
                                <Flex
                                    justifyContent='space-between'
                                    bgColor='brand.black.200'
                                    px={4}
                                    py={2}
                                    fontWeight='bold'
                                >
                                    <Box>{theme.title}</Box>
                                    {hasMatchedSubthemes ? (
                                        <Box>
                                            {theme.feedback.score}% (
                                            {theme.feedback.awarded_points} out
                                            of {theme.feedback.available_points}
                                            )
                                        </Box>
                                    ) : (
                                        <Box>Not covered</Box>
                                    )}
                                </Flex>
                                {theme.subthemes
                                    .filter(
                                        subtheme =>
                                            subtheme.feedback.available_points >
                                                0 &&
                                            !!subtheme.feedback.user_response,
                                    )
                                    .map(subtheme => (
                                        <FeedbackSubtheme
                                            key={subtheme.title}
                                            {...subtheme}
                                        />
                                    ))}
                            </Fragment>
                        );
                    })}
            </Box>
        </Box>
    );
};

const DialogueChoicesFeedback: React.FC<InteractionStageFeedback<
    DialogueChoicesStage
>> = ({ marking_feedback }) => {
    if (!marking_feedback) return null;

    const { summary, sections } = marking_feedback;
    return (
        <Box>
            <Heading size='md' p={4} bg='black' color='white' roundedTop='15px'>
                <Flex justifyContent='space-between'>
                    <Box>Your performance</Box>
                    <Box>{summary.score}%</Box>
                </Flex>
            </Heading>
            <Box>
                {sections.map(s => (
                    <FeedbackSection key={s.title} {...s} />
                ))}
            </Box>
        </Box>
    );
};

export default DialogueChoicesFeedback;
