import React from 'react';
import { Card } from 'react-bootstrap';
import config from '../../../consts/config';

const CompletionConfirmationCard: React.FC<{
    studentCourse: CertificateStudentCourse;
}> = ({ studentCourse }) => {
    return (
        <Card
            style={{
                backgroundColor: 'var(--primary)',
            }}
            text='white'
        >
            <Card.Body style={{ padding: '2.5rem' }}>
                <Card.Title style={{ marginBottom: '1.5rem' }}>
                    <h3 className='h3'>
                        Completed by <b>{studentCourse.student_name}</b>
                    </h3>
                </Card.Title>
                <Card.Subtitle style={{ marginBottom: '2rem' }}>
                    <h6 className='h6'>
                        {studentCourse.completion_date} <br />
                        Grade: {studentCourse.score}%
                    </h6>
                </Card.Subtitle>
                <Card.Text>
                    {config.REACT_APP_BRANDING_ORGANISATION} certifies
                    successful completion of <u>{studentCourse.course.title}</u>{' '}
                    by {studentCourse.course.provider.name}
                </Card.Text>
            </Card.Body>
        </Card>
    );
};

export default CompletionConfirmationCard;
