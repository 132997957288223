import React, { Fragment, useState } from 'react';
import { Formik, FormikValues, FormikErrors } from 'formik';
import { Form, Alert } from 'react-bootstrap';
import { SubmitButton } from './UserDetailsForm';
import useAsyncError from '../../hooks/useAsyncError';

interface Props {
    onSubmit: (values: FormikValues) => Promise<any>;
}

const UserPasswordForm: React.FC<Props> = ({ onSubmit }) => {
    const [startedEditing, setStartedEditing] = useState<boolean>(false);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

    const throwAsyncError = useAsyncError();

    const validate = (values: {
        [name: string]: string;
    }): { [name: string]: string | null | undefined } => {
        const errors: {
            password?: null | string;
            password_two?: null | string;
            current_password?: null | string;
        } = {};

        if (
            values.password === '' &&
            values.password_two === '' &&
            startedEditing
        ) {
            setStartedEditing(false);
        }
        if (
            (values.password !== '' || values.password_two !== '') &&
            !startedEditing
        ) {
            setStartedEditing(true);
        }

        if (values.password !== '' || values.password_two !== '') {
            if (!values.password) {
                errors.password = 'Password required';
            } else if (values.password !== values.password_two) {
                errors.password = 'Passwords must match';
            } else if (values.password.length < 8) {
                errors.password = 'Password must be more than 8 characters';
            } else if (values.password.length > 72) {
                errors.password = 'Password must be less than 72 characters';
            } else if (
                !/[!@#£$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(values.password)
            ) {
                errors.password =
                    'Password must contain at least one special character';
            }
            if (values.current_password === '') {
                errors.current_password =
                    'Please confirm your current password';
            } else if (values.current_password === values.password_two) {
                errors.current_password =
                    'New password cannot be the same as your current password';
            }
        }

        return errors;
    };

    const submit = (
        values: FormikValues,
        {
            setErrors,
            setSubmitting,
            resetForm,
        }: {
            setErrors: (errors: FormikErrors<FormikValues>) => void;
            setSubmitting: (val: boolean) => void;
            resetForm: () => void;
        },
    ): void => {
        setSubmitting(true);
        onSubmit(values)
            .then(() => {
                setShowConfirmation(true);
                setTimeout(() => setShowConfirmation(false), 3500);
                resetForm();
                setStartedEditing(false);
            })
            .catch(error => {
                if (error['message'].match(/.*current.*password.*/gi)) {
                    setErrors({
                        current_password: error['message'],
                    });
                } else {
                    throwAsyncError(error);
                }
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const intitialValues = {
        current_password: '',
        password: '',
        password_two: '',
    };

    return (
        <Formik
            initialValues={intitialValues}
            validate={validate}
            onSubmit={submit}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
            }): any => (
                <Form noValidate onSubmit={handleSubmit}>
                    <h4 className='h4'>Change password</h4>
                    {showConfirmation && (
                        <Alert variant='success'>
                            Password successfully updated
                        </Alert>
                    )}
                    <Fragment>
                        <Form.Group controlId='password'>
                            <Form.Label>New password</Form.Label>
                            <Form.Control
                                type='password'
                                placeholder='Password'
                                required
                                autoComplete={'new-password'}
                                isInvalid={
                                    touched.password && !!errors.password
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                            />
                            <Form.Control.Feedback type='invalid'>
                                {errors.password}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='password_two'>
                            <Form.Label>Confirm new password</Form.Label>
                            <Form.Control
                                type='password'
                                placeholder='Confirm password'
                                required
                                isInvalid={
                                    touched.password &&
                                    !!errors.password &&
                                    !!errors.password_two
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password_two}
                            />
                            <Form.Control.Feedback type='invalid'>
                                {errors.password_two}
                            </Form.Control.Feedback>
                        </Form.Group>
                        {values.password !== '' &&
                            values.password === values.password_two &&
                            !errors.password &&
                            !errors.password_two && (
                                <Form.Group
                                    controlId='current_password'
                                    className='mb-0'
                                >
                                    <Form.Label>
                                        Confirm current password
                                    </Form.Label>
                                    <Form.Control
                                        type='password'
                                        placeholder='Current password'
                                        required
                                        isInvalid={
                                            touched.current_password &&
                                            !!errors.current_password
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.current_password}
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.current_password}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            )}
                        <SubmitButton
                            title={'Update password'}
                            disabled={isSubmitting || !startedEditing}
                            isSubmitting={isSubmitting}
                        />
                    </Fragment>
                </Form>
            )}
        </Formik>
    );
};

export default UserPasswordForm;
