import { createContext } from 'react';

export interface TrialData {
    is_trial: boolean;
    expired?: boolean;
    seconds_remaining?: number;
}

export type MoocFeature = 'courses' | 'portfolio';

const StudentFeaturesContext = createContext<{
    features: MoocFeature[];
    setFeatures: any;
    trialData: TrialData;
    setTrialData: any;
}>({
    features: [],
    setFeatures: () => {
        /*EMPTY*/
    },
    trialData: {
        is_trial: false,
    },
    setTrialData: () => {
        /* EMPTY */
    },
});

export default StudentFeaturesContext;
