import {
    Heading,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react';
import React, { Fragment, useState } from 'react';
import { Button, Col } from 'react-bootstrap';
import CentredComponent from '../../hocs/CentredComponent';
import LoadingSpinner from '../generic/LoadingSpinner';
import { ModalProps } from './types';
import { ModalWithNoInitialFocus } from '@recourseai/components/src/atoms/ModalWithNoInitialFocus/ModalWithNoInitialFocus';

interface Props extends ModalProps {
    show: boolean;
    onStartNew: () => Promise<any>;
    onClose: () => Promise<any>;
}

const ResumeCancelledAttemptModal: React.FC<Props> = ({
    show,
    onStartNew,
    onClose,
}) => {
    const [isInitialising, setIsInitialising] = useState(false);

    return (
        <ModalWithNoInitialFocus
            isOpen={show}
            onClose={onClose}
            closeOnOverlayClick={!isInitialising}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Heading>You have already cancelled this attempt</Heading>
                    {!isInitialising && <ModalCloseButton />}
                </ModalHeader>
                <ModalBody>
                    You have already cancelled this attempt and it can not be
                    resumed.
                    <CentredComponent className='mt-4'>
                        {isInitialising ? (
                            <LoadingSpinner />
                        ) : (
                            <Fragment>
                                <Col xs='auto' className='mx-2'>
                                    <Button
                                        variant='outline-primary'
                                        onClick={() => {
                                            setIsInitialising(true);
                                            onClose().finally(() =>
                                                setIsInitialising(false),
                                            );
                                        }}
                                    >
                                        Close
                                    </Button>
                                </Col>
                                <Col xs='auto' className='mx-2'>
                                    <Button
                                        variant='primary'
                                        onClick={() => {
                                            setIsInitialising(true);
                                            onStartNew().finally(() => {
                                                setIsInitialising(false);
                                            });
                                        }}
                                    >
                                        Start New Attempt
                                    </Button>
                                </Col>
                            </Fragment>
                        )}
                    </CentredComponent>
                </ModalBody>
            </ModalContent>
        </ModalWithNoInitialFocus>
    );
};

export default ResumeCancelledAttemptModal;
