import { faCircle, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties, Fragment, FunctionComponent } from 'react';
import { Card, ProgressBar, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ROUTES from '../../consts/routes';
import { ReactComponent as DifficultyIconOne } from '../../images/difficulty-1.svg';
import { ReactComponent as DifficultyIconTwo } from '../../images/difficulty-2.svg';
import { ReactComponent as DifficultyIconThree } from '../../images/difficulty-3.svg';
import { capitalizeFirstLetter } from '../../utils/stringUtils';

const IconAndText = ({
    text,
    Icon,
}: {
    text: string | number;
    Icon?: FunctionComponent;
}): JSX.Element => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
            }}
        >
            {Icon && (
                <div style={{ display: 'flex' }}>
                    <Icon />
                </div>
            )}
            <Card.Subtitle
                className='small mb-0 mt-0 ml-1'
                style={{ fontSize: 11 }}
            >
                {text}
            </Card.Subtitle>
        </div>
    );
};

const CardInfo = ({
    value,
    Icon,
    label,
    showIcon = true,
}: {
    value: number | string;
    Icon?: FunctionComponent;
    label?: string;
    showIcon?: boolean;
}): JSX.Element => {
    return (
        <Fragment>
            {label ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                    }}
                >
                    {showIcon && (
                        <span className='fa-layers fa-fw'>
                            <FontAwesomeIcon
                                icon={faCircle}
                                color={'grey'}
                                className='mr-2'
                            />
                            <span
                                className='fa-layers-text fa-inverse'
                                data-fa-transform='shrink-12'
                                style={{ fontSize: 10, marginLeft: 10 }}
                            >
                                {value}
                            </span>
                        </span>
                    )}
                    <Card.Subtitle
                        className='small mb-0 mt-0'
                        style={{ fontSize: 10 }}
                    >
                        {label}
                    </Card.Subtitle>
                </div>
            ) : (
                <IconAndText text={value} Icon={Icon} />
            )}
        </Fragment>
    );
};

const DifficultyIcon = (difficulty: number): JSX.Element => {
    const defaultStyles = {
        height: 16,
        width: 16,
        display: 'flex',
        color: 'blue',
    };

    switch (difficulty) {
        case 1:
            return <DifficultyIconOne fill={'#fff'} style={defaultStyles} />;
        case 2:
            return (
                <DifficultyIconTwo
                    fill='red'
                    color={'red'}
                    style={defaultStyles}
                />
            );
        case 3:
            return (
                <DifficultyIconThree
                    fill='red'
                    color='green'
                    style={defaultStyles}
                />
            );
    }

    throw new Error('Invalid difficulty type');
};

const CourseCardRibbon: React.FC = ({ children }) => {
    return (
        <div
            className='py-1 pl-2 pr-3'
            style={{
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                position: 'absolute',
                zIndex: 100,
                top: 20,
                right: 0,
                borderTopLeftRadius: 20,
                borderBottomLeftRadius: 20,
            }}
        >
            {children}
        </div>
    );
};

const userHasEnrolledOnCourse = (course: Course): boolean => {
    return course.student_course != null;
};

const EnrollmentInformation: React.FC<{ course: Course }> = ({ course }) => {
    if (!userHasEnrolledOnCourse(course)) {
        return null;
    }

    if (course.student_course?.completed_datetime !== null) {
        return (
            <CourseCardRibbon>
                <span style={{ fontWeight: 500, fontSize: '.8rem' }}>
                    Completed
                </span>
            </CourseCardRibbon>
        );
    }

    return (
        <CourseCardRibbon>
            <span style={{ fontWeight: 500, fontSize: '.8rem' }}>
                In progress
            </span>
        </CourseCardRibbon>
    );
};

const CourseProgress: React.FC<{ course: Course; [key: string]: any }> = ({
    course,
    ...props
}) => {
    if (userHasEnrolledOnCourse(course)) {
        return (
            <ProgressBar
                className='mx-0'
                {...props}
                now={course.student_course?.course_progress_percentage || 0}
            />
        );
    }

    return null;
};

const CourseInfo: React.FC<{ course: Course }> = ({ course }) => (
    <Fragment>
        <CardInfo
            value={course.difficulty.name}
            Icon={(): JSX.Element => DifficultyIcon(course.difficulty.id)}
        />
        <div
            style={{
                fontSize: '0.3rem',
                lineHeight: 5,
                color: 'var(--secondary)',
            }}
        >
            <FontAwesomeIcon icon={faCircle} size='xs' className='mx-2' />
        </div>
        <CardInfo
            value={course.number_of_required_activities}
            showIcon={false}
            label={
                course.number_of_required_activities === 1
                    ? `${course.number_of_required_activities} Activity`
                    : `${course.number_of_required_activities} Activities`
            }
        />
    </Fragment>
);

const CourseCard: React.FC<{
    course: Course;
    isLocked?: boolean;
    size?: 'sm' | 'md';
    className?: string;
    style?: CSSProperties;
}> = ({ course, isLocked, size = 'md', className, style }) => {
    const cardBorderRadius = 10;

    return (
        <Link
            to={!isLocked ? `/${ROUTES.COURSES}/${course.id}` : {}}
            style={{
                cursor: isLocked ? 'default' : 'pointer',
                textDecoration: 'none',
                color: 'inherit',
            }}
        >
            <Card
                style={{
                    maxWidth: size === 'sm' ? 260 : 330,
                    minWidth: size === 'sm' ? 220 : 290,
                    // boxShadow: '7px 7px 15px -13px rgba(0,0,0,0.7)',
                    borderRadius: cardBorderRadius,
                    ...style,
                }}
                className={`border ${className}`}
            >
                <EnrollmentInformation course={course} />
                {isLocked && (
                    <CourseCardRibbon>
                        <FontAwesomeIcon icon={faLock} />
                    </CourseCardRibbon>
                )}
                <Card.Img
                    variant='top'
                    src={course.image_url}
                    style={{
                        aspectRatio: '3 / 2',
                        borderTopLeftRadius: cardBorderRadius,
                        borderTopRightRadius: cardBorderRadius,
                    }}
                />
                {isLocked && (
                    <div
                        className='h-100 w-100'
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            backgroundColor: 'rgba(256, 256, 256, 0.4)',
                            borderRadius: cardBorderRadius,
                        }}
                    />
                )}
                <Row className='mt-0 mx-0'>
                    <CourseProgress
                        style={{
                            height: 4,
                            width: '100%',
                            borderRadius: 0,
                            backgroundColor: '#0000001a',
                        }}
                        course={course}
                    />
                </Row>
                <Card.Body className='py-1 px-2 d-flex flex-column'>
                    <Card.Title
                        style={{
                            fontSize: 19,
                            minHeight: 20,
                            fontWeight: 'bold',
                            flexShrink: 0,
                        }}
                        className='mb-1 mt-2 pb-0'
                    >
                        {capitalizeFirstLetter(course.title.substring(0, 90))}
                    </Card.Title>
                    <Card.Subtitle
                        className='small mb-2 mt-0 pt-0 text-muted'
                        style={{ fontSize: 11, flexShrink: 0 }}
                    >
                        By {course.provider.name}
                    </Card.Subtitle>
                    {!isLocked && course.sub_title && (
                        <Card.Text className='my-2' style={{ fontSize: 14 }}>
                            {capitalizeFirstLetter(
                                course.sub_title.substring(0, 128),
                            )}
                        </Card.Text>
                    )}
                    <Row className='mt-auto mb-2 mx-0'>
                        <CourseInfo course={course} />
                    </Row>
                </Card.Body>
            </Card>
        </Link>
    );
};

export default CourseCard;
