export const HINT_END_CALL =
    'Click the end call button to complete the activity';

export const SPEECH_RECOGNITION_START_HINT =
    'Hold spacebar or click the microphone button to speak.';
export const SPEECH_RECOGNITION_START_HINT_SHORT = 'Hold spacebar to speak.';
export const SPEECH_RECOGNITION_RUNNING = 'Speak now.';
export const SPEECH_RECOGNITION_CLICK_STOP_HINT =
    'Click the stop button to send.';
export const SPEECH_RECOGNITION_SPACEBAR_STOP_HINT =
    'Release spacebar to send.';

export const MICROPHONE_PERMISSION_DENIED =
    'Permission to access your microphone was denied. You can change this from your browser settings, or use the right sidebar to interact via text.';
export const MICROPHONE_PERMISSION_DENIED_MOBILE =
    'Permission to access your microphone was denied. You can change this from your browser settings, or use the bottom field to interact via text.';
export const MICROPHONE_PERMISSION_BLOCKED =
    'Permission to use your microphone is blocked. You can change this from your browser settings.';
export const MICROPHONE_ALLOW_PERMISSION =
    'Click "Allow" in your browser above to enable the microphone';

export const MICROPHONE_ENABLE_MIC_PERMISSION =
    'Please enable microphone permissions from the browser settings.';

export const CONNECT_MICROPHONE =
    'Please connect a microphone to interact using speech.';

export const NO_INTERNET_CONNECTION =
    'Your browser is offline. Please check you internet connection to continue.';

export const NO_MICROPHONE_DEVICE =
    'No microphone device found. Make sure your microphone is installed and correctly configured.';
export const MESSAGE_NOT_RECOGNISED = "Sorry, your message wasn't recognized.";
export const MESSAGE_NOT_RECOGNISED_INFORMAL = "Sorry, we didn't catch that.";

export const RECOGNITION_ENDED_TOO_FAST =
    'Leave a slight pause before sending.';
export const RECOGNITION_ENDED_TOO_FAST_CLICK =
    'Please click after you have finished speaking.';
export const RECOGNITION_ENDED_TOO_FAST_SPACEBAR =
    'Hold spacebar until you have finished speaking.';

export const SCREEN_CAPTURE_PERMISSION_DENIED =
    'Please allow access to your screen';

export const DIALOGUE_CHOICE_NOT_RECOGNISED_HINT =
    'Message not recognized - Ensure you include keyword(s)';
export const DIALOGUE_CHOICE_INSTRUCTIONS =
    "Please include highlighted keyword(s) when rephrasing your option. You can also type by opening the sidebar, or simply click an option if you can't speak now.";

export const ACTIVITY_FEEDBACK_NEGATIVE_LABEL = 'Bad';
export const ACTIVITY_FEEDBACK_NEGATIVE_FORM_TITLE = "We're sorry to hear that";
export const ACTIVITY_FEEDBACK_NEGATIVE_FORM_PLACEHOLDER =
    'How can we improve?';

export const ACTIVITY_FEEDBACK_POSITIVE_LABEL = 'Good';
export const ACTIVITY_FEEDBACK_POSITIVE_FORM_TITLE =
    'Thank you for your feedback';
export const ACTIVITY_FEEDBACK_POSITIVE_FORM_PLACEHOLDER =
    'There is always room for improvement, tell us how';
