import React, { useState, useEffect, useCallback } from 'react';
import { Col, Button, Form, Alert, Row } from 'react-bootstrap';
import { Formik, FormikValues } from 'formik';
import { moocAPI } from '../../services';
import { getReadableTimeFromSeconds } from '../../utils/timeUtils';
import { AnnotationTypeName } from '../portfolio/WorkflowAnnotations';
import SelectableNoteTag from '../portfolio/SelectableNoteTag';
import PersonalInformationWarning from '../portfolio/PersonalInformationWarning';
import usePrevious from '../../hooks/usePrevious';
import CharacterCounter from '../../hocs/CharacterCounter';

const AnnotationCharLimits: Record<Annotation['type'], number> = {
    grading_item: 100,
    note: 280,
};

interface Props {
    caseId: number;
    annotationData: Annotation;
    tags: Tag[] | null | undefined;
    currentTime: number;
    setAnnotations: (annotations: any) => void;
    onCancel: () => void;
}

const CaseAnnotationForm: React.FC<Props> = ({
    caseId,
    annotationData,
    tags,
    currentTime,
    setAnnotations,
    onCancel,
}) => {
    const [serverError, setServerError] = useState<string | null>(null);
    const prevAnnotation = usePrevious(annotationData);

    const validate = (values: {
        content: string;
        timestamp: number;
    }): { [name: string]: string | null | undefined } => {
        const errors: {
            content?: null | string;
        } = {};

        if (!values.content) {
            errors.content = 'Content should not be empty';
        } else if (
            values.content.length > AnnotationCharLimits[annotationData.type]
        ) {
            errors.content = `${
                AnnotationTypeName[annotationData.type]
            }s should have less than ${
                AnnotationCharLimits[annotationData.type]
            } characters`;
        }
        return errors;
    };

    const addAnnotation = useCallback(
        (annotation: Annotation) => {
            setAnnotations((old: any) =>
                old
                    .concat([annotation])
                    .sort(
                        (a: Annotation, b: Annotation) =>
                            a.timestamp - b.timestamp,
                    ),
            );
        },
        [setAnnotations],
    );

    // Add the previous annotation back to the list if a new one is selected
    useEffect(() => {
        if (
            prevAnnotation &&
            prevAnnotation.id &&
            prevAnnotation.id !== annotationData.id
        ) {
            addAnnotation(prevAnnotation);
        }
    }, [addAnnotation, annotationData, prevAnnotation]);

    const onSubmit = (values: FormikValues): void => {
        if (annotationData.id) {
            moocAPI
                .patch(
                    `portfolio/case/${caseId}/annotation/${annotationData.id}/`,
                    {
                        content: values.content,
                        timestamp: values.timestamp,
                        tag: values.tag,
                        type: annotationData.type,
                    },
                )
                .then(res => {
                    addAnnotation(res);
                    onCancel();
                })
                .catch(error => setServerError(error));
        } else {
            moocAPI
                .post(`portfolio/case/${caseId}/annotations/`, {
                    timestamp: values.timestamp,
                    content: values.content,
                    tag: values.tag,
                    type: annotationData.type,
                })
                .then(res => {
                    addAnnotation(res);
                    onCancel();
                })
                .catch(error => setServerError(error));
        }
    };

    return (
        <div className='mt-4'>
            {serverError && (
                <Alert variant='danger'>{serverError.toString()}</Alert>
            )}

            <Formik
                initialValues={{
                    content: annotationData.content,
                    timestamp: annotationData.timestamp,
                    tag: annotationData.tag?.id,
                }}
                enableReinitialize
                onSubmit={onSubmit}
                validate={validate}
            >
                {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    handleChange,
                    handleSubmit,
                }): JSX.Element => (
                    <Form noValidate onSubmit={handleSubmit} className='my-2'>
                        <Row className='align-items-center justify-content-around mb-1'>
                            <Col
                                xs='auto'
                                className='felx-grow-0 py-1 ml-3 px-2 rounded text-muted'
                                style={{ backgroundColor: 'gainsboro' }}
                            >
                                {getReadableTimeFromSeconds(values.timestamp)}
                            </Col>
                            <Col xs='auto' className='felx-grow-0 ml-auto'>
                                <Button
                                    variant='secondary'
                                    size='sm'
                                    onClick={() =>
                                        setFieldValue('timestamp', currentTime)
                                    }
                                >
                                    Update Timestamp
                                </Button>
                            </Col>
                        </Row>
                        <Form.Group controlId='content'>
                            <CharacterCounter
                                content={values.content}
                                charLimit={
                                    AnnotationCharLimits[annotationData.type]
                                }
                            >
                                <Form.Control
                                    name='content'
                                    as='textarea'
                                    value={values.content}
                                    maxLength={
                                        AnnotationCharLimits[
                                            annotationData.type
                                        ]
                                    }
                                    rows={Math.max(
                                        annotationData.content.split(' ')
                                            .length / 6,
                                        3,
                                    )}
                                    isInvalid={
                                        touched.content && !!errors.content
                                    }
                                    onChange={handleChange}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.content}
                                </Form.Control.Feedback>
                            </CharacterCounter>
                            <Form.Text className='text-muted'>
                                <Row noGutters className='align-items-center'>
                                    <PersonalInformationWarning />
                                </Row>
                            </Form.Text>
                        </Form.Group>
                        {tags &&
                            tags.map((tag, index) => (
                                <SelectableNoteTag
                                    key={index}
                                    tag={tag}
                                    selected={values.tag === tag.id}
                                    onSelect={() =>
                                        setFieldValue('tag', tag.id)
                                    }
                                    onUnselect={() =>
                                        setFieldValue('tag', undefined)
                                    }
                                />
                            ))}
                        <Form.Row className='mt-4 justify-content-end'>
                            <Col className='flex-grow-0'>
                                <Button
                                    variant='outline-primary'
                                    size='sm'
                                    className='px-4'
                                    style={{ borderRadius: '20px' }}
                                    onClick={() => {
                                        if (annotationData.id) {
                                            addAnnotation(annotationData);
                                        }
                                        onCancel();
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col className='flex-grow-0'>
                                <Button
                                    variant='primary'
                                    size='sm'
                                    className='px-4'
                                    type='submit'
                                >
                                    {annotationData.id ? 'Update' : 'Add'}
                                </Button>
                            </Col>
                        </Form.Row>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default CaseAnnotationForm;
