import React from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { moocAPI } from '../services';
import DashboardPageLayout from '../components/layouts/DashboardPageLayout';
import PortfolioPage from './PortfolioPage';
import useAsyncError from '../hooks/useAsyncError';
import RenderWithLoading from '../components/generic/RenderWithLoading';
import { Box, Center, Heading, Skeleton, Spacer } from '@chakra-ui/react';
import UserActionsAvatar from '../components/layouts/DashboardPageLayout/UserActionsAvatar';

export const TeamsPageV2: React.FC = props => {
    const throwAsyncError = useAsyncError();
    const { data: groups, isSuccess, refetch } = useQuery(
        ['portfolio', 'groups'],
        () => moocAPI.get('portfolio/groups/'),
        { onError: throwAsyncError },
    );
    const queryClient = useQueryClient();

    const { data: userData, isSuccess: isUserDataReady } = useQuery(
        ['user-data'],
        () => moocAPI.get('student/user'),
    );

    return (
        <DashboardPageLayout>
            <Box
                py={4}
                mt={10}
                mb={5}
                borderColor={'gray.200'}
                borderBottom={'1px solid'}
            >
                <Skeleton isLoaded={isUserDataReady}>
                    <Center>
                        <Heading size='lg' as='h2' fontWeight='normal'>
                            {isUserDataReady
                                ? `Welcome, ${userData.first_name} ${userData.last_name}`
                                : 'Welcome'}
                        </Heading>
                        <Spacer />
                        <UserActionsAvatar />
                    </Center>
                </Skeleton>
            </Box>
            <RenderWithLoading hasLoaded={isSuccess}>
                <PortfolioPage
                    groups={groups}
                    setGroups={updater =>
                        queryClient.setQueryData(
                            ['portfolio', 'groups'],
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            updater,
                        )
                    }
                    refetch={refetch}
                />
            </RenderWithLoading>
        </DashboardPageLayout>
    );
};

export default TeamsPageV2;
