import React, { CSSProperties, Fragment, useEffect, useState } from 'react';
import {
    Form,
    Row,
    Col,
    Button,
    Alert,
    Tab,
    Nav,
    Spinner,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import useAsyncError from '../../hooks/useAsyncError';
import { moocAPI } from '../../services';
import { FileError } from 'react-dropzone';
import UploadProgressModal from '../modals/UploadProgressModal';
import { Formik, FormikValues } from 'formik';
import ROUTES from '../../consts/routes';
import AddGroupButton from '../portfolio/actions/AddGroupButton';
import PortfolioCaseUploadTermsAndConditionsModal from '../modals/PortfolioCaseUploadTermsAndConditionsModal';
import PersonalInformationWarning from '../portfolio/PersonalInformationWarning';
import { SCREEN_CAPTURE_PERMISSION_DENIED } from '../../consts/messages';
import FileUpload from '../generic/FileUpload';
import ScreenRecorder from '../generic/ScreenRecorder';
import {
    CASE_TITLE_ALREADY_EXISTS,
    GROUP_NAME_ALREADY_EXISTS,
} from '../../consts/errors';
import { isSafari } from 'react-device-detect';
import config from '../../consts/config';

enum FILE_TYPE {
    VIDEO,
    AUDIO,
}

export const CASE_TYPES: Case['case_type'][] = [
    'consultation_case',
    'other_case',
];
export const CONSULTATION_TYPES: Case['consultation_type'][] = [
    'real',
    'simulated',
    'other',
];
export const CREATION_METHODS = ['UPLOAD', 'RECORD'];

export interface InitialValues {
    caseType?: Case['case_type'];
    group?: number;
    title?: string;
    consultationType?: Case['consultation_type'];
}

export interface UploadCaseFromProps {
    groups: Group[] | null;
    addGroup: (group: Group) => void;
    initialValues?: InitialValues;
    creationMethod?: typeof CREATION_METHODS[number];
    enforceCreationMethod?: boolean;
}

const LoadingOptionsSpinner: React.FC<{
    onLoaded: () => void;
    loaded: boolean;
    style?: CSSProperties;
}> = ({ loaded, onLoaded, style }) => {
    useEffect(() => {
        if (loaded) {
            onLoaded();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loaded]);

    if (!loaded) {
        return (
            <Spinner
                variant='secondary'
                animation='border'
                size='sm'
                style={style}
            />
        );
    }

    return null;
};

const UploadCaseFrom: React.FC<UploadCaseFromProps> = ({
    groups,
    addGroup,
    initialValues,
    creationMethod = 'UPLOAD',
    enforceCreationMethod,
}) => {
    const [file, setFile] = useState<File | null>(null);
    const [fileUploadErrors, setFileUploadErrors] = useState<
        FileError[] | null
    >(null);
    const [mediaRecorderError, setMediaRecorderError] = useState<string | null>(
        null,
    );
    const [serverError, setServerError] = useState<string | null>(null);
    const [uploadPercentage, setUploadPercentage] = useState<
        number | undefined
    >(undefined);
    const [showTermsModal, setShowTermsModal] = useState<boolean>(false);
    const [isUploading, setIsUploading] = useState(false);
    const throwAsyncError = useAsyncError();
    const history = useHistory();

    const getAPICompatibleFileType = (file: File) => {
        const typeName = file.type
            .split('/')[0]
            .toUpperCase() as keyof typeof FILE_TYPE;
        return typeName in FILE_TYPE ? typeName : '';
    };

    const validate = (values: {
        title?: string;
        group?: number;
        consultation_type?: Case['consultation_type'];
        diagnosis?: string;
        chief_complaint?: string;
        terms_and_conditions?: boolean;
        no_patient_personal_data?: boolean;
    }): { [name: string]: string | null | undefined } => {
        const errors: {
            title?: null | string;
            group?: null | string;
            consultation_type?: null | string;
            diagnosis?: null | string;
            chief_complaint?: null | string;
            terms_and_conditions?: null | string;
            no_patient_personal_data?: null | string;
        } = {};

        if (!values.title) {
            errors.title = 'Case title is required';
        } else if (values.title.length > 100) {
            errors.title = 'Title should be less than 100 characters';
        }

        if (values.group === null || values.group === undefined) {
            errors.group = 'The case needs to be in a group';
        }

        if (initialValues?.caseType === 'consultation_case') {
            if (values.consultation_type === undefined) {
                errors.consultation_type =
                    'Select the type of consultation you are uploading';
            }
            if (!values.chief_complaint) {
                errors.chief_complaint =
                    'Please provide a chief complaint for your case';
            } else if (values.chief_complaint.length > 128) {
                errors.chief_complaint =
                    'Please make sure the chief complaint is concise (less than 128 characters)';
            }
            if (!values.diagnosis) {
                errors.diagnosis = 'Please provide a diagnosis for your case';
            } else if (values.diagnosis.length > 128) {
                errors.diagnosis =
                    'Please make sure the diagnosis is concise (less than 128 characters)';
            }
        }

        if (!values.terms_and_conditions) {
            errors.terms_and_conditions =
                'You need to agree with the terms and conditions';
        }

        if (
            !values.no_patient_personal_data &&
            values.consultation_type !== 'simulated'
        ) {
            errors.no_patient_personal_data =
                'Patient personal information should not be disclosed';
        }

        return errors;
    };

    const onSubmit = (values: FormikValues) => {
        if (file !== null) {
            setIsUploading(true);
            moocAPI
                .post('portfolio/cases/', {
                    ...values,
                    case_type: initialValues!.caseType as string,
                    file_type: getAPICompatibleFileType(file),
                })
                .then(({ id, upload_url }) => {
                    // Simple concatenation leads to https://api.com//api/v1/rest/of/url
                    // so the trailing "/" of the API_ROOT is removed
                    let mooc_api = config.REACT_APP_MOOC_API_ROOT_URL!;
                    mooc_api = mooc_api.substring(0, mooc_api.length - 1);
                    moocAPI
                        .putFile(`${mooc_api}${upload_url}`, file, event => {
                            const percentCompleted = Math.round(
                                (event.loaded * 100) / event.total,
                            );
                            setUploadPercentage(percentCompleted);
                        })
                        .then(response => {
                            setUploadPercentage(undefined);
                            moocAPI
                                .patch(`portfolio/case/${id}/`, {
                                    file: response.file_storage_path,
                                })
                                .then(() =>
                                    history.push(
                                        `/${ROUTES.CASE_OVERVIEW}/${id}`,
                                    ),
                                )
                                .catch(e => {
                                    setServerError(e.message);
                                    setUploadPercentage(undefined);
                                });
                        })
                        .catch(error => {
                            setServerError(error.message);
                            setUploadPercentage(undefined);
                        })
                        .finally(() => setIsUploading(false));
                })
                .catch(error => {
                    if (error.name === 'unique') {
                        setServerError(CASE_TITLE_ALREADY_EXISTS);
                    } else {
                        setServerError(error.message);
                    }
                    setUploadPercentage(undefined);
                    setIsUploading(false);
                });
        }
    };

    return (
        <Fragment>
            {serverError && <Alert variant='danger'>{serverError}</Alert>}
            <Row className='px-5'>
                <Col md={7}>
                    {fileUploadErrors && (
                        <Alert variant='danger'>
                            {fileUploadErrors.map((err, index) => {
                                switch (err.code) {
                                    case 'too-many-files':
                                        return (
                                            <Fragment key={index}>
                                                You can only upload 1 file
                                            </Fragment>
                                        );
                                    case 'file-invalid-type':
                                        return (
                                            <Fragment key={index}>
                                                Only <b>audio</b> and{' '}
                                                <b>video</b> files are supported
                                            </Fragment>
                                        );
                                    case 'file-too-large':
                                        return (
                                            <Fragment key={index}>
                                                File size should not exceed 500
                                                megabytes (MB) <br />
                                                <span
                                                    style={{
                                                        fontSize: '0.85rem',
                                                    }}
                                                >
                                                    Try using compression to
                                                    reduce the size
                                                </span>
                                            </Fragment>
                                        );
                                    default:
                                        return (
                                            <Fragment key={index}>
                                                {err.message}
                                            </Fragment>
                                        );
                                }
                            })}
                        </Alert>
                    )}
                    {mediaRecorderError && (
                        <Alert variant='danger'>
                            {(() => {
                                switch (mediaRecorderError) {
                                    case 'permission_denied':
                                        return (
                                            <Fragment>
                                                {
                                                    SCREEN_CAPTURE_PERMISSION_DENIED
                                                }
                                            </Fragment>
                                        );
                                    default:
                                        return (
                                            <Fragment>
                                                {mediaRecorderError}
                                            </Fragment>
                                        );
                                }
                            })()}
                        </Alert>
                    )}
                    <Tab.Container
                        defaultActiveKey={creationMethod || 'UPLOAD'}
                    >
                        {!enforceCreationMethod && (
                            <Nav
                                variant='pills'
                                className='ml-4'
                                style={{ marginBottom: '-3px' }}
                            >
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey='UPLOAD'
                                        style={{ borderRadius: '5px' }}
                                    >
                                        Upload
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey='RECORD'
                                        style={{ borderRadius: '5px' }}
                                    >
                                        Record
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        )}
                        <Tab.Content>
                            {(!enforceCreationMethod ||
                                creationMethod === 'UPLOAD') && (
                                <Tab.Pane eventKey='UPLOAD' transition={false}>
                                    <div
                                        className='px-2 py-1 d-flex flex-column justify-content-center'
                                        style={{
                                            backgroundColor: 'var(--primary)',
                                            width: '100%',
                                            height: '28rem',
                                            borderRadius: '10px',
                                        }}
                                    >
                                        <FileUpload
                                            options={{
                                                onDropAccepted: files => {
                                                    if (files.length) {
                                                        setFile(files[0]);
                                                    }
                                                    setFileUploadErrors(null);
                                                },
                                                onDropRejected: fileRejections => {
                                                    if (fileRejections.length) {
                                                        setFileUploadErrors(
                                                            fileRejections[0]
                                                                .errors,
                                                        );
                                                    }
                                                    setFile(null);
                                                },
                                                noClick: true,
                                                noKeyboard: true,
                                                multiple: false,
                                                accept: [
                                                    'video/*',
                                                    'audio/*',
                                                ].join(', '),
                                                maxSize: 500 * (1 << 20), // 500MB,
                                            }}
                                            file={file}
                                        />
                                    </div>
                                </Tab.Pane>
                            )}
                            {(!enforceCreationMethod ||
                                creationMethod === 'RECORD') && (
                                <Tab.Pane eventKey='RECORD' transition={false}>
                                    <div
                                        className='px-2 py-1 d-flex flex-column justify-content-center'
                                        style={{
                                            backgroundColor: 'var(--primary)',
                                            width: '100%',
                                            height: '28rem',
                                            borderRadius: '10px',
                                        }}
                                    >
                                        {isSafari ? (
                                            <Alert
                                                variant='dark'
                                                className='mx-5 bg-white'
                                            >
                                                Screen recording is not yet
                                                supported on this browser,
                                                please use Chrome on your
                                                desktop or laptop instead.
                                            </Alert>
                                        ) : (
                                            <ScreenRecorder
                                                mediaRecorderProps={{
                                                    userMediaCapture: {
                                                        audio: true,
                                                    },
                                                    screenCapture: {
                                                        video: true,
                                                        audio: true,
                                                    },
                                                    onStop: blob =>
                                                        setFile(
                                                            new File(
                                                                [blob],
                                                                'Screen recording.webm',
                                                                {
                                                                    type:
                                                                        'video/webm',
                                                                },
                                                            ),
                                                        ),
                                                }}
                                                onError={err =>
                                                    setMediaRecorderError(err)
                                                }
                                            />
                                        )}
                                    </div>
                                </Tab.Pane>
                            )}
                        </Tab.Content>
                    </Tab.Container>
                </Col>
                <Col md={5}>
                    <Formik
                        initialValues={{
                            title: initialValues?.title,
                            group: undefined,
                            consultation_type: initialValues?.consultationType,
                            chief_complaint: undefined,
                            diagnosis: undefined,
                            terms_and_conditions: false,
                            no_patient_personal_data: false,
                        }}
                        validate={validate}
                        onSubmit={onSubmit}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                        }): JSX.Element => (
                            <Form
                                noValidate
                                onSubmit={handleSubmit}
                                className='d-flex flex-column'
                            >
                                <Form.Group className='py-1' controlId='title'>
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control
                                        type='text'
                                        name='title'
                                        placeholder='Case title'
                                        required
                                        value={values.title}
                                        isInvalid={
                                            touched.title && !!errors.title
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.title}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group
                                    className='py-1'
                                    controlId='group'
                                    style={{ position: 'relative' }}
                                >
                                    <div
                                        style={{
                                            position: 'absolute',
                                            right: '0',
                                            top: '-5px',
                                        }}
                                    >
                                        <AddGroupButton
                                            size='sm'
                                            placement='left'
                                            variant='outline-primary'
                                            className='btn-sm'
                                            disabled={!groups}
                                            style={{ borderRadius: '20px' }}
                                            onFormSubmit={(
                                                values,
                                                { setFieldError },
                                            ) => {
                                                moocAPI
                                                    .post('portfolio/groups/', {
                                                        name: values.groupName,
                                                    })
                                                    .then(response => {
                                                        addGroup(response);
                                                        setFieldValue(
                                                            'group',
                                                            response.id,
                                                        );

                                                        // Hide form
                                                        document.body.click();
                                                    })
                                                    .catch(error => {
                                                        if (
                                                            error.name ===
                                                            'unique'
                                                        ) {
                                                            setFieldError(
                                                                'groupName',
                                                                GROUP_NAME_ALREADY_EXISTS,
                                                            );
                                                        } else {
                                                            throwAsyncError(
                                                                error,
                                                            );
                                                        }
                                                    });
                                            }}
                                        />
                                    </div>

                                    <Form.Label>Group</Form.Label>
                                    <div className='position-relative'>
                                        <Form.Control
                                            as='select'
                                            name='group'
                                            required
                                            defaultValue={
                                                initialValues?.group ||
                                                (groups?.length
                                                    ? groups[0].id
                                                    : undefined)
                                            }
                                            value={values.group}
                                            isInvalid={
                                                touched.group && !!errors.group
                                            }
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        >
                                            {groups?.map((group, index) => (
                                                <option
                                                    key={index}
                                                    value={group.id}
                                                >
                                                    {group.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                        <LoadingOptionsSpinner
                                            loaded={!!groups}
                                            onLoaded={() =>
                                                setFieldValue(
                                                    'group',
                                                    initialValues?.group ||
                                                        (groups?.length
                                                            ? groups[0].id
                                                            : undefined),
                                                )
                                            }
                                            style={{
                                                position: 'absolute',
                                                top: '0.7rem',
                                                left: '1rem',
                                            }}
                                        />
                                    </div>
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.group}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                {initialValues?.caseType ===
                                    'consultation_case' && (
                                    <Fragment>
                                        <Form.Group
                                            className='py-1 mt-2'
                                            controlId='consultation_type'
                                        >
                                            <Form.Label>
                                                Consultation type
                                            </Form.Label>
                                            <Form.Control
                                                hidden
                                                isInvalid={
                                                    touched.consultation_type &&
                                                    !!errors.consultation_type
                                                }
                                            />
                                            <div>
                                                {['Real', 'Simulated'].map(
                                                    (val, index) => (
                                                        <Form.Check
                                                            key={index}
                                                            defaultChecked={
                                                                val.toLowerCase() ===
                                                                initialValues?.consultationType
                                                            }
                                                            custom
                                                            inline
                                                            className='mx-5'
                                                            type='radio'
                                                            id={`${val.toLowerCase()}-case-type-radio`}
                                                            name='consultation_type'
                                                            label={val}
                                                            onChange={() =>
                                                                setFieldValue(
                                                                    'consultation_type',
                                                                    val.toLowerCase(),
                                                                )
                                                            }
                                                        />
                                                    ),
                                                )}
                                            </div>
                                            <Form.Control.Feedback type='invalid'>
                                                {errors.consultation_type}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Row>
                                            <Col>
                                                <Form.Group controlId='chief_complaint'>
                                                    <Form.Label>
                                                        Chief complaint
                                                    </Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        name='chief_complaint'
                                                        value={
                                                            values.chief_complaint
                                                        }
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={
                                                            touched.chief_complaint &&
                                                            !!errors.chief_complaint
                                                        }
                                                    />
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.chief_complaint}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>

                                            <Col>
                                                <Form.Group
                                                    className='ml-1'
                                                    controlId='diagnosis'
                                                >
                                                    <Form.Label>
                                                        Diagnosis
                                                    </Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        name='diagnosis'
                                                        value={values.diagnosis}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={
                                                            touched.diagnosis &&
                                                            !!errors.diagnosis
                                                        }
                                                    />
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors.diagnosis}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                    </Fragment>
                                )}

                                <Form.Group
                                    className='py-1 mt-4'
                                    controlId='terms_and_conditions'
                                >
                                    <Form.Check
                                        custom
                                        type='checkbox'
                                        required
                                        id='terms_and_conditions'
                                    >
                                        <Form.Check.Input
                                            type='checkbox'
                                            checked={
                                                values.terms_and_conditions
                                            }
                                            onChange={handleChange}
                                            isInvalid={
                                                touched.terms_and_conditions &&
                                                !!errors.terms_and_conditions
                                            }
                                        />
                                        <Form.Check.Label className='d-inline'>
                                            I agree with{' '}
                                        </Form.Check.Label>
                                        <Button
                                            variant='link'
                                            className='m-0 p-0'
                                            onClick={(e: React.MouseEvent) => {
                                                e.stopPropagation();
                                                setShowTermsModal(true);
                                            }}
                                        >
                                            terms and conditions
                                        </Button>
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.terms_and_conditions}
                                        </Form.Control.Feedback>
                                    </Form.Check>
                                </Form.Group>

                                {values.consultation_type !== 'simulated' && (
                                    <Form.Group
                                        className='py-1'
                                        controlId='no_patient_personal_data'
                                    >
                                        <Form.Check
                                            custom
                                            type='checkbox'
                                            required
                                            id='no_patient_personal_data'
                                        >
                                            <Form.Check.Input
                                                type='checkbox'
                                                checked={
                                                    values.no_patient_personal_data
                                                }
                                                onChange={handleChange}
                                                isInvalid={
                                                    touched.no_patient_personal_data &&
                                                    !!errors.no_patient_personal_data
                                                }
                                            />
                                            <Form.Check.Label className='d-inline'>
                                                The recording does not contain
                                                patient identifiable information
                                            </Form.Check.Label>
                                            <PersonalInformationWarning
                                                iconOnly
                                            />
                                            <Form.Control.Feedback type='invalid'>
                                                {
                                                    errors.no_patient_personal_data
                                                }
                                            </Form.Control.Feedback>
                                        </Form.Check>
                                    </Form.Group>
                                )}

                                <Form.Row className='mt-auto justify-content-end'>
                                    <Col className='flex-grow-0'>
                                        <Button
                                            variant='outline-primary'
                                            disabled={isUploading}
                                            size='lg'
                                            className='px-4'
                                            style={{ borderRadius: '20px' }}
                                            onClick={() => history.go(-1)}
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col className='flex-grow-0'>
                                        <Button
                                            variant='primary'
                                            size='lg'
                                            className='px-4'
                                            type='submit'
                                            disabled={
                                                file === null || isUploading
                                            }
                                        >
                                            {isUploading
                                                ? 'Submitting'
                                                : 'Submit'}
                                        </Button>
                                    </Col>
                                </Form.Row>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
            <UploadProgressModal
                show={uploadPercentage !== undefined}
                onClose={() => setUploadPercentage(undefined)}
                uploadPercentage={uploadPercentage}
            />
            <PortfolioCaseUploadTermsAndConditionsModal
                show={showTermsModal}
                onClose={() => setShowTermsModal(false)}
            />
        </Fragment>
    );
};

export default UploadCaseFrom;
