const getHumanReadableFromDate = date =>
    `${new Date(date).toLocaleDateString()} - ${new Date(
        date,
    ).toLocaleTimeString()}`;

const getPrettyDateFormat = date =>
    `${new Date(date).toLocaleDateString(undefined, {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    })}`;

const compareStrDates = (dateStr1, dateStr2) => {
    const [date1, date2] = [new Date(dateStr1), new Date(dateStr2)];
    return date1.getTime() - date2.getTime();
};

export { getPrettyDateFormat, compareStrDates };
export default getHumanReadableFromDate;
