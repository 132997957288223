import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import Footer from '../components/generic/Footer';
import Header from '../components/generic/Header';
import GlobalBoundaryError from '../components/generic/GlobalBoundaryError';

interface Props {
    hideHeader?: boolean;
    showFooter?: boolean;
    headerTitle?: string;
    headerLink?: string;
    fullWidth?: boolean;
}

interface State {
    error: Error | null;
}

export const ContentWrapper: React.FC<{ fullWidth: boolean }> = ({
    fullWidth,
    children,
}) => {
    if (fullWidth) {
        return <div style={{ width: '100%' }}>{children}</div>;
    }

    return (
        <Container
            style={{
                paddingTop: 20,
                paddingBottom: 70,
                // width: '100%',
                // minHeight: 'calc(100vh - 105px - 16px)', //ensures the footer is at the bottom of the viewport
                // // or the content, relies on setting static height header and footer.
            }}
            fluid={'xl'}
        >
            {children}
        </Container>
    );
};

export const BasePageHeaderContext = React.createContext({
    hideHeader: () => {
        /* EMPTY */
    },
    showHeader: () => {
        /* EMPTY */
    },
    isHeaderShowing: false,
});

const BasePage: React.FC<Props> = ({
    headerLink,
    headerTitle,
    showFooter,
    hideHeader,
    fullWidth = false,
    children,
}) => {
    const [showHeader, setShowHeader] = useState(!hideHeader);

    return (
        <div style={{ height: '100%', width: '100%' }}>
            {showHeader && (
                <Header
                    title={headerTitle}
                    headerLink={headerLink}
                    height={55} // Used in calc() function below
                />
            )}
            <GlobalBoundaryError fullWidth={fullWidth}>
                <ContentWrapper fullWidth={fullWidth}>
                    <BasePageHeaderContext.Provider
                        value={{
                            hideHeader: () => setShowHeader(false),
                            showHeader: () => setShowHeader(true),
                            isHeaderShowing: showHeader,
                        }}
                    >
                        {children}
                    </BasePageHeaderContext.Provider>
                </ContentWrapper>
            </GlobalBoundaryError>
            {showFooter && <Footer />}
        </div>
    );
};

export default BasePage;
