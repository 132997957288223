import React, {
    Fragment,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import CentredComponent from '../hocs/CentredComponent';
import { compareStrDates } from '../utils/dateUtils';
import CourseCard from '../components/courses/CourseCard';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import StudentFeaturesContext from '../components/contexts/StudentFeaturesContext';
import config from '../consts/config';

export interface CoursePageProps {
    courses: Course[];
    filter: 'all' | 'in_progress' | 'in-progress' | 'completed' | 'available';
}

export const courseFilter = (
    course: Course,
    filter: CoursePageProps['filter'],
): boolean => {
    switch (filter) {
        case 'all':
        case 'available':
            return true;
        case 'completed':
            return !!course.student_course && !!course.student_course.score;
        case 'in_progress':
        case 'in-progress':
            return !!course.student_course && !course.student_course.score;
    }
    return false;
};

export const courseCompare = (
    c1: Course,
    c2: Course,
    filter: CoursePageProps['filter'],
): number => {
    switch (filter) {
        case 'completed':
            return -compareStrDates(
                c1.student_course?.completed_datetime,
                c2.student_course?.completed_datetime,
            );
        case 'in-progress':
        case 'in_progress':
            return -compareStrDates(
                c1.student_course?.most_recent_attempt_datetime ||
                    c1.student_course?.created_at,
                c2.student_course?.most_recent_attempt_datetime ||
                    c2.student_course?.created_at,
            );
        default:
            return 0;
    }
};

const noContentMessageFactory = (filter: CoursePageProps['filter']): string => {
    switch (filter) {
        case 'completed':
            return "You've not yet completed any courses";
        case 'in_progress':
            return 'You have no active courses';
        default:
            return 'No courses found';
    }
};

const CoursesPage: React.FC<CoursePageProps> = ({ courses, filter }) => {
    const noContentMessage = noContentMessageFactory(filter);
    const canUseSmallCard = useMediaQuery({ minWidth: 576 });

    const displayCourses = useMemo(() => {
        const f = (c: Course): boolean => courseFilter(c, filter);
        const cmp = (c1: Course, c2: Course): number =>
            courseCompare(c1, c2, filter);
        return courses.filter(f).sort(cmp);
    }, [courses, filter]);

    const { trialData } = useContext(StudentFeaturesContext);
    const [trialLockedCourses, setTrialLockedCourses] = useState<
        Course[] | null
    >(null);

    useEffect(() => {
        if (trialData.is_trial && config.REACT_APP_TRIAL_LOCKED_COURSES_URL) {
            axios
                .get(config.REACT_APP_TRIAL_LOCKED_COURSES_URL, {
                    headers: { Accept: 'application/json' },
                })
                .then(res => {
                    setTrialLockedCourses(res.data);
                });
        }
    }, [trialData.is_trial]);

    if (displayCourses.length === 0) {
        return (
            <CentredComponent>
                <Alert variant='secondary'>{noContentMessage}</Alert>
            </CentredComponent>
        );
    }

    return (
        <Fragment>
            <Row
                noGutters
                className='justify-content-start'
                style={{ rowGap: '2.5rem', columnGap: '1.75rem' }}
            >
                {displayCourses.map((c, index) => (
                    <Col key={index} style={{ maxWidth: 340 }} className='px-0'>
                        <CourseCard className='h-100' course={c} />
                    </Col>
                ))}
            </Row>
            {filter === 'all' && trialLockedCourses && (
                <Fragment>
                    <CentredComponent className='mt-5 mb-2'>
                        <h3 className='h3'>Please upgrade for full catalog</h3>
                    </CentredComponent>
                    <Row noGutters>
                        {trialLockedCourses.map((c, index) => (
                            <Col
                                key={index}
                                className='mb-4 px-0 d-flex justify-content-center'
                            >
                                <CourseCard
                                    course={c}
                                    size={canUseSmallCard ? 'sm' : 'md'}
                                    className='m-2'
                                    isLocked
                                />
                            </Col>
                        ))}
                    </Row>
                </Fragment>
            )}
        </Fragment>
    );
};

export default CoursesPage;
