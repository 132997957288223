import React, { useState } from 'react';
import { ModalProps } from './types';
import {
    Box,
    chakra,
    Flex,
    Heading,
    HStack,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Select,
    Spacer,
} from '@chakra-ui/react';
import getHumanReadableFromDate from '../../utils/dateUtils';
import { orderBy } from 'lodash';
import { InteractionFeedbackModalContent } from './InteractionFeedbackModal';
import ActivityIcon from '../activities/ActivityIcon';
import { InvestigationModalContent } from './InvestigationFeedbackModal';
import { ModalWithNoInitialFocus } from '@recourseai/components/src/atoms/ModalWithNoInitialFocus/ModalWithNoInitialFocus';
import { useAttemptFeedback } from '../../pages/useExerciseActivity';

interface InsightsFeedbackModal extends ModalProps {
    attempts: StudentExerciseAttempt[];
    activity: Activity;
    course?: Course;
}

const getInitialAttempt = (
    activity: Activity,
    attempts: StudentExerciseAttempt[],
): StudentExerciseAttempt => {
    const isPassOnly = !activity.config.pass_threshold;
    if (isPassOnly) {
        return orderBy(attempts, a => new Date(a.completed_datetime!), [
            'desc',
        ])[0];
    }
    return orderBy(attempts, ['score'], ['desc'])[0];
};

const InsightsFeedbackModal: React.FC<InsightsFeedbackModal> = ({
    attempts,
    activity,
    course,
    ...modalProps
}) => {
    const [attemptId, setAttemptId] = useState(
        () => getInitialAttempt(activity, attempts).id,
    );
    const { data: feedback } = useAttemptFeedback(
        course?.id,
        activity.id,
        attemptId,
    );

    return (
        <ModalWithNoInitialFocus
            isOpen={modalProps.show}
            onClose={modalProps.onClose}
            onCloseComplete={modalProps.onExited}
            scrollBehavior='inside'
            size='5xl'
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader
                    bg='brand.gradient.cold.full'
                    textColor='white'
                    fontWeight='normal'
                >
                    <Flex>
                        {course && (
                            <HStack alignItems='end'>
                                <Heading
                                    as='h3'
                                    fontSize='3xl'
                                    fontWeight='normal'
                                >
                                    {course.title}
                                </Heading>
                                <chakra.span>
                                    by {course.provider.name}
                                </chakra.span>
                            </HStack>
                        )}
                        <Spacer />
                        {/*<Heading mr={10} fontWeight='normal'>*/}
                        {/*    Insights*/}
                        {/*</Heading>*/}
                        <ModalCloseButton />
                    </Flex>
                </ModalHeader>
                <ModalBody
                    minHeight='min(70vh, 100%)'
                    tabIndex={0}
                    role='group'
                    aria-label='feedback-content'
                >
                    <Box>
                        <Heading as='h4' fontSize='2xl' my={4}>
                            <HStack>
                                <ActivityIcon activity={activity} />
                                <chakra.span>{activity.title}</chakra.span>
                            </HStack>
                        </Heading>
                        <Box my={4}>
                            Attempts ({attempts.length})
                            <Select
                                value={attemptId}
                                onChange={event =>
                                    setAttemptId(Number(event.target.value))
                                }
                            >
                                {attempts.map(attempt => (
                                    <option key={attempt.id} value={attempt.id}>
                                        {!!activity.config.pass_threshold &&
                                            `${attempt.score}% | `}
                                        {getHumanReadableFromDate(
                                            attempt.completed_datetime,
                                        )}
                                    </option>
                                ))}
                            </Select>
                        </Box>
                        <Box height='100%' p={1}>
                            {feedback &&
                                (activity.config.exercise_type ===
                                'INVESTIGATION' ? (
                                    <InvestigationModalContent
                                        feedback={feedback}
                                    />
                                ) : (
                                    <InteractionFeedbackModalContent
                                        feedback={feedback}
                                    />
                                ))}
                        </Box>
                    </Box>
                </ModalBody>
            </ModalContent>
        </ModalWithNoInitialFocus>
    );
};

export default InsightsFeedbackModal;
