import React from 'react';
import usePortal from '../../utils/usePortal';
import { Modal } from 'react-bootstrap';
import config from '../../consts/config';

interface Props {
    show: boolean;
    onClose: () => void;
}

const TermsOfServiceModal: React.FC<Props> = ({ show, onClose }) => {
    const target = usePortal('modal-root');

    return (
        <Modal
            show={show}
            container={target}
            onHide={onClose}
            dialogClassName='mw-800-modal-dialog'
        >
            <Modal.Header closeButton>
                <Modal.Title>Terms of service</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h2 className='h2'>RECOURSE AI LTD. Terms of Service</h2>
                <ol>
                    <li>
                        Terms
                        <p>
                            By accessing the website at{' '}
                            <a href={config.REACT_APP_BRANDING_HOME_URL}>
                                {config.REACT_APP_BRANDING_HOME_URL}
                            </a>{' '}
                            (and www.recoursemed.com), you are agreeing to be
                            bound by these terms of service, all applicable laws
                            and regulations, and agree that you are responsible
                            for compliance with any applicable local laws. If
                            you do not agree with any of these terms, you are
                            prohibited from using or accessing this site. The
                            materials contained in this website are protected by
                            applicable copyright and trademark law.
                        </p>
                    </li>
                    <li>
                        Use License
                        <ol>
                            <li>
                                {`Permission is granted to temporarily
                                download one copy of the materials
                                (information or software) on RECOURSE AI
                                LTD.'s website for personal, non-commercial
                                transitory viewing only. This is the grant
                                of a license, not a transfer of title, and
                                under this license you may not:`}
                                <ol>
                                    <li>modify or copy the materials;</li>
                                    <li>
                                        use the materials for any commercial
                                        purpose, or for any public display
                                        (commercial or non-commercial);
                                    </li>
                                    <li>
                                        {`attempt to decompile or reverse
                                        engineer any software contained on
                                        RECOURSE AI LTD.'s website;`}
                                    </li>
                                    <li>
                                        remove any copyright or other
                                        proprietary notations from the
                                        materials; or
                                    </li>
                                    <li>
                                        {`transfer the materials to another
                                        person or "mirror" the materials on
                                        any other server.`}
                                    </li>
                                </ol>
                            </li>
                            This license shall automatically terminate if you
                            violate any of these restrictions and may be
                            terminated by RECOURSE AI LTD. at any time. Upon
                            terminating your viewing of these materials or upon
                            the termination of this license, you must destroy
                            any downloaded materials in your possession whether
                            in electronic or printed format.
                        </ol>
                    </li>
                    <li>
                        Disclaimer
                        <p>
                            {`The materials on RECOURSE AI LTD.'s website are
                            provided on an 'as is' basis. RECOURSE AI LTD.
                            makes no warranties, expressed or implied, and
                            hereby disclaims and negates all other
                            warranties including, without limitation,
                            implied warranties or conditions of
                            merchantability, fitness for a particular
                            purpose, or non-infringement of intellectual
                            property or other violation of rights. Further,
                            RECOURSE AI LTD. does not warrant or make any
                            representations concerning the accuracy, likely
                            results, or reliability of the use of the
                            materials on its website or otherwise relating
                            to such materials or on any sites linked to this
                            site.`}
                        </p>
                    </li>
                    <li>
                        Limitations
                        <p>
                            {`In no event shall RECOURSE AI LTD. or its
                            suppliers be liable for any damages (including,
                            without limitation, damages for loss of data or
                            profit, or due to business interruption) arising
                            out of the use or inability to use the materials
                            on RECOURSE AI LTD.'s website, even if RECOURSE AI
                            LTD. or a RECOURSE AI LTD. authorized
                            representative has been notified orally or in
                            writing of the possibility of such damage.
                            Because some jurisdictions do not allow
                            limitations on implied warranties, or
                            limitations of liability for consequential or
                            incidental damages, these limitations may not
                            apply to you.`}
                        </p>
                    </li>
                    <li>
                        Accuracy of materials
                        <p>
                            {`The materials appearing on RECOURSE AI LTD.'s
                            website could include technical, typographical,
                            or photographic errors. RECOURSE AI LTD. does not
                            warrant that any of the materials on its website
                            are accurate, complete or current. RECOURSE AI
                            LTD. may make changes to the materials contained
                            on its website at any time without notice.
                            However RECOURSE AI LTD. does not make any
                            commitment to update the materials.`}
                        </p>
                    </li>
                    <li>
                        Links
                        <p>
                            {`RECOURSE AI LTD. has not reviewed all of the sites
                            linked to its website and is not responsible for
                            the contents of any such linked site. The
                            inclusion of any link does not imply endorsement
                            by RECOURSE AI LTD. of the site. Use of any such
                            linked website is at the user's own risk.`}
                        </p>
                    </li>
                    <li>
                        Modifications
                        <p>
                            RECOURSE AI LTD. may revise these terms of service
                            for its website at any time without notice. By using
                            this website you are agreeing to be bound by the
                            then current version of these terms of service.
                        </p>
                    </li>
                    <li>
                        Governing Law
                        <p>
                            These terms and conditions are governed by and
                            construed in accordance with the laws of United
                            Kingdom and you irrevocably submit to the exclusive
                            jurisdiction of the courts in that State or
                            location.
                        </p>
                    </li>
                    <li>
                        Purchases
                        <p>
                            Recourse grants you (as a student) a limited,
                            non-exclusive, non-transferable license to access
                            and view the courses and associated content for
                            which you have paid all required fees, solely for
                            your personal, non-commercial, educational purposes
                            through the Services, in accordance with these Terms
                            and any conditions or restrictions associated with a
                            particular courses or feature of our Services. All
                            other uses are expressly prohibited. You may not
                            reproduce, redistribute, transmit, assign, sell,
                            broadcast, rent, share, lend, modify, adapt, edit,
                            create derivative works of, sublicense, or otherwise
                            transfer or use any course unless we give you
                            explicit permission to do so/. This also applies to
                            content you can access via any of our APIs.
                        </p>
                        <p>
                            We generally give a lifetime access license to our
                            students when they enroll in a course. However, we
                            reserve the right to revoke any license to access
                            and use courses at any point in time in the event
                            where we decide or are obligated to disable access
                            to a course due to legal or policy reasons.
                        </p>
                    </li>
                    <li>
                        Payments
                        <p>
                            You may be required to purchase or pay a fee to
                            access some of our services. You agree to provide
                            current, complete, and accurate purchase and account
                            information for all purchases made via the Site. You
                            further agree to promptly update account and payment
                            information, including email address, payment
                            method, and payment card expiration date, so that we
                            can complete your transactions and contact you as
                            needed. We bill you through an online billing
                            account for purchases made via the Site. Sales tax
                            will be added to the price of purchases as deemed
                            required by us. We may change prices at any time.
                            All payments shall be in pounds sterling (GBP).
                        </p>
                        <p>
                            You agree to pay all charges or fees at the prices
                            then in effect for your purchases, and you authorize
                            us to charge your chosen payment provider for any
                            such amounts upon making your purchase.
                        </p>
                        <p>
                            We reserve the right to correct any errors or
                            mistakes in pricing, even if we have already
                            requested or received payment. We also reserve the
                            right to refuse any order placed through the Site.
                        </p>
                    </li>
                    <li>
                        Refunds
                        <p>
                            You have the right to a refund for any goods
                            purchased within a 14 day period from the date of
                            purchase, so long as you have not completed the
                            course. After this date, or once the purchased
                            course has been completed, you will no longer be
                            entitled to a refund. To enquire about a refund
                            please contact{' '}
                            <a
                                href={`mailto:${config.REACT_APP_BRANDING_SUPPORT_EMAIL}`}
                            >
                                {config.REACT_APP_BRANDING_SUPPORT_EMAIL}
                            </a>
                            .
                        </p>
                    </li>
                </ol>
            </Modal.Body>
        </Modal>
    );
};

export default TermsOfServiceModal;
