import React, { Fragment, useState, useContext, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import CaseCard from './CaseCard';
import DeleteConfirmationModal from '../modals/DeleteConfirmationModal';
import { moocAPI } from '../../services';
import { useHistory, Link } from 'react-router-dom';
import ROUTES from '../../consts/routes';
import GroupForm from '../forms/GroupForm';
import BackToNavButtons from '../generic/BackToNavButtons';
import { FormikHelpers, FormikValues } from 'formik';
import PortfolioGroupMembersModal from '../modals/PortfolioGroupMembersModal';
import ActionsDropdown, {
    Edit,
    Delete,
    SeeMembers,
    Share,
} from './actions/ActionsDropdown';
import useAsyncError from '../../hooks/useAsyncError';
import AllowedActionsContext from '../contexts/AllowedActionsContext';
import { GROUP_NAME_ALREADY_EXISTS } from '../../consts/errors';
import { RecordCaseButton, UploadCaseButton } from './actions/CreateCaseButton';

interface EditableGroupNameProps {
    group: Group;
    isEditMode: boolean;
    isLink?: boolean;
    onSubmit: (values: FormikValues, formikHelpers: FormikHelpers<any>) => void;
    onCancel: () => void;
}

const EditableGroupName: React.FC<EditableGroupNameProps> = ({
    group,
    isEditMode,
    onSubmit,
    onCancel,
    isLink = false,
}) => (
    <Fragment>
        {isEditMode ? (
            <GroupForm
                groupNameInitial={group.name}
                onSubmit={onSubmit}
                onCancel={onCancel}
            />
        ) : isLink ? (
            <Link to={`/${ROUTES.GROUP_OVERVIEW}/${group.id}`}>
                <h3 className='h3'>{group.name}</h3>
            </Link>
        ) : (
            <h3 className='h3'>{group.name}</h3>
        )}
    </Fragment>
);

export interface ActionsMenuProps {
    group: Group;
    onEdit?: () => void;
    onDelete?: () => void;
    onShare?: () => void;
    onLeave?: () => void;
    onSeeMembers?: () => void;
}

const ActionsMenu: React.FC<ActionsMenuProps> = props => {
    const userPermissions = useContext(AllowedActionsContext);

    return (
        <ActionsDropdown>
            {props.group.status === 'private' &&
                props.group.name !== 'Private cases' && (
                    <Fragment>
                        {props.onEdit && <Edit onEdit={props.onEdit} />}
                        {props.onShare && <Share onShare={props.onShare} />}
                        {props.onDelete && <Delete onDelete={props.onDelete} />}
                    </Fragment>
                )}
            {props.group.status === 'shared' && (
                <Fragment>
                    {props.onEdit && userPermissions.includes('edit_group') && (
                        <Edit onEdit={props.onEdit} />
                    )}
                    {props.onSeeMembers &&
                        userPermissions.includes('view_members') && (
                            <SeeMembers onSeeMembers={props.onSeeMembers} />
                        )}
                </Fragment>
            )}
        </ActionsDropdown>
    );
};

export { EditableGroupName, ActionsMenu };

const GroupOverview: React.FC<{
    group: Group;
    setGroup: React.Dispatch<React.SetStateAction<Group | null>>;
}> = ({ group, setGroup }) => {
    const [showModal, setShowModal] = useState<
        'delete' | 'members' | undefined
    >(undefined);
    const [groupMembers, setGroupMembers] = useState<
        PortfolioGroupMember[] | undefined
    >(undefined);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const history = useHistory();
    const throwAsyncError = useAsyncError();
    const userPermissions = useContext(AllowedActionsContext);
    const deleteGroup = useCallback(
        () =>
            moocAPI
                .delete(`portfolio/group/${group.id}/`)
                .then(() => history.push(`/${ROUTES.PORTFOLIO}`)),
        [group.id, history],
    );

    return (
        <Fragment>
            <Row>
                <Col>
                    <BackToNavButtons
                        pageName='teams'
                        pageUrl={`/${ROUTES.PORTFOLIO}`}
                    />
                </Col>
            </Row>
            <Row className='my-4 align-items-center'>
                <Col xs='auto' style={{ flexGrow: isEditMode ? 1 : 0 }}>
                    <EditableGroupName
                        group={group}
                        isEditMode={isEditMode}
                        onSubmit={(values, { setFieldError }) => {
                            moocAPI
                                .patch(`portfolio/group/${group.id}/`, {
                                    name: values.groupName,
                                })
                                .then(res => {
                                    setGroup(res);
                                    setIsEditMode(false);
                                })
                                .catch(error => {
                                    if (error.name === 'unique') {
                                        setFieldError(
                                            'groupName',
                                            GROUP_NAME_ALREADY_EXISTS,
                                        );
                                    } else {
                                        throwAsyncError(error);
                                    }
                                });
                        }}
                        onCancel={() => setIsEditMode(false)}
                    />
                </Col>
                <Col>
                    {!isEditMode && (
                        <ActionsMenu
                            group={group}
                            onEdit={() => setIsEditMode(true)}
                            onDelete={() =>
                                group.cases.length > 0
                                    ? setShowModal('delete')
                                    : deleteGroup()
                            }
                            onSeeMembers={() => {
                                moocAPI
                                    .get(`portfolio/group/${group.id}/members/`)
                                    .then(members => {
                                        setGroupMembers(members);
                                        setShowModal('members');
                                    })
                                    .catch(throwAsyncError);
                            }}
                            onShare={() => {
                                moocAPI
                                    .patch(`portfolio/group/${group.id}/`, {
                                        status: 'shared',
                                    })
                                    .then(res => {
                                        setGroup(res);
                                        setShowModal('members');
                                    });
                            }}
                        />
                    )}
                </Col>
                <Col xs='auto' className='ml-auto'>
                    {userPermissions.includes('add_cases') && (
                        <Fragment>
                            <RecordCaseButton
                                className='mx-2'
                                caseFormQueryParams={{ group: group.id }}
                            />
                            <UploadCaseButton
                                className='mx-2'
                                caseFormQueryParams={{ group: group.id }}
                            />
                        </Fragment>
                    )}
                </Col>
            </Row>
            <Row className='mb-4 justify-content-start flex-wrap'>
                {group.cases.map((portfolioCase, index) => (
                    <Col xs='auto' key={index}>
                        <CaseCard portfolioCase={portfolioCase} />
                    </Col>
                ))}
            </Row>
            <DeleteConfirmationModal
                show={showModal === 'delete'}
                onClose={() => setShowModal(undefined)}
                validationText='delete-group'
                resourceName={group.name}
                message={'Deleting the group will also remove all its cases!'}
                onDelete={deleteGroup}
            />
            <PortfolioGroupMembersModal
                open={showModal === 'members'}
                onClose={() => setShowModal(undefined)}
                invitationLink={group.invitation_link}
                members={groupMembers}
                onMakePrivate={() => {
                    moocAPI
                        .patch(`portfolio/group/${group.id}/`, {
                            status: 'private',
                        })
                        .then(res => {
                            setGroup(res);
                            setShowModal(undefined);
                        })
                        .catch(throwAsyncError);
                }}
                onDeleteMember={(memberId: number) => {
                    moocAPI
                        .delete(
                            `portfolio/group/${group.id}/members/${memberId}/`,
                        )
                        .then(() =>
                            setGroupMembers(old =>
                                old?.filter(
                                    member => member.user.id !== memberId,
                                ),
                            ),
                        )
                        .catch(throwAsyncError);
                }}
                onLeave={() => {
                    moocAPI
                        .delete(`portfolio/group/${group.id}/members/leave/`)
                        .then(() => history.push(`/${ROUTES.PORTFOLIO}`))
                        .catch(throwAsyncError);
                }}
            />
        </Fragment>
    );
};

export default GroupOverview;
