import React, { useContext } from 'react';
import StudentFeaturesContext, {
    MoocFeature,
} from '../components/contexts/StudentFeaturesContext';
import { Redirect } from 'react-router-dom';
import ROUTES from '../consts/routes';

const OrderedFeaturesRedirects: { feature: MoocFeature; to: string }[] = [
    {
        feature: 'courses',
        to: ROUTES.COURSES,
    },
    {
        feature: 'portfolio',
        to: ROUTES.PORTFOLIO,
    },
];

export const Home: React.FC = props => {
    const { features } = useContext(StudentFeaturesContext);

    for (const featureRedirect of OrderedFeaturesRedirects) {
        if (features.includes(featureRedirect.feature)) {
            return <Redirect to={featureRedirect.to} />;
        }
    }

    return null;
};
