import { useEffect, useRef } from 'react';

const usePrevious = <T>(value: T, skipValues?: any[]): T | undefined => {
    const ref = useRef<T>();
    useEffect(() => {
        if (!skipValues || !skipValues.includes(value)) {
            ref.current = value;
        }
    }, [value, skipValues]);
    return ref.current;
};

export default usePrevious;
