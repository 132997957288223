import { useParams } from 'react-router-dom';
import { keycloak } from '../services';
import IDPS from '../consts/external_idps';

const useExternalIDPRoutePrefix = () => {
    const { idpHint } = useParams<{ idpHint: string }>();
    let routePrefix = '';

    if (keycloak.authenticated) {
        const external_idp = (keycloak?.tokenParsed as any)?.external_idp;
        routePrefix = external_idp ? `/${external_idp}` : '';
    } else if (idpHint && IDPS.includes(idpHint)) {
        routePrefix = `/${idpHint}`;
    }

    return routePrefix;
};

export default useExternalIDPRoutePrefix;
