import { useCallback, useEffect, useMemo, useRef } from 'react';
import useQueue from '../../../../core/src/hooks/useQueue';
import useTypewriterEffect from '../../../../core/src/hooks/useTypewriterEffect';
import {
    ChatConnection,
    ChatConnOptions,
    ChatWebsocketCloseReason,
    ChatWebsocketCodes,
    openChatHttpConn,
    openChatWebsocketConn,
} from './InteractionConnection';
import { parseChatEntries } from './utils';
import { Session } from '../../../../../apps/mooc-frontend/src/components/activities/ActivityContent';
import ExerciseAPI from '../../../../../apps/mooc-frontend/src/components/activities/ExerciseAPI';
import { promiseWithResolvers } from '../../../../core/src/utils/promise';
import { InteractionActions, useInteractionAgent } from './useInteractionAgent';
import * as Sentry from '@sentry/browser';

interface RunnableAction {
    // Resolves when an action has finished processing
    promise: Promise<any>;
    // Will immediately terminate a running action
    stop: () => void;
}

export type ActionProcessor = (
    action: AgentAction,
    activeStage: ActiveStage,
) => RunnableAction | undefined;

export interface InitProps {
    session: Session;
    exerciseAPI: ExerciseAPI;
    useStreaming: boolean;
    messageDisplayLimit?: number;
}

const useInteraction = ({
    session,
    exerciseAPI,
    useStreaming,
    messageDisplayLimit,
}: InitProps) => {
    const sessionId = session.id;
    const stageId = session.active_stage.id;
    const activeStage = session.active_stage;

    const chatUrl = `sessions/${sessionId}/stages/${stageId}/`;

    const [messages, setMessages, addMessages] = useQueue<TextMessage>(
        messageDisplayLimit,
    );

    const hints = useMemo(() => {
        return (
            session.active_stage.interaction_stage.hint
                ?.split('\n')
                .map(hint => hint.trim()) || []
        );
    }, [session.active_stage.interaction_stage.hint]);

    // Do not use spead operator as it will recreate the object unnecessarily causing performance issues downstream
    const {
        act,
        status,
        isDisabled,
        isAgentBusy,
        isConnected,
        isTranscribing,
        awaitingResponse,
    } = useInteractionAgent();

    const { displayTextual, stop: stopTextual } = useTypewriterEffect(
        40,
        setMessages,
    );

    const chatConnectionRef = useRef<ChatConnection | null>(null);
    const avatarRef = useRef(null);
    const runningActions = useRef(new Map<any, RunnableAction[]>());
    const interruptedActions = useRef(new Set<any>());

    const actionProcessors = useRef<Map<string, ActionProcessor>>(new Map());
    const addActionProcessor = useCallback(
        (name: string, processor: ActionProcessor) => {
            if (actionProcessors.current.has(name)) {
                console.warn(`Overriding action processor ${name}`);
            }
            actionProcessors.current.set(name, processor);
        },
        [],
    );
    const removeActionProcessor = useCallback((name: string) => {
        actionProcessors.current.delete(name);
    }, []);

    const runAction: (
        action: AgentAction,
        activeStage: ActiveStage,
    ) => RunnableAction = useCallback((action, activeStage) => {
        const { promise, resolve } = promiseWithResolvers();

        const agentActionTasks: RunnableAction['promise'][] = [];
        const stopFunctions: RunnableAction['stop'][] = [];

        const stop = () => stopFunctions.forEach(f => f());

        actionProcessors.current.forEach(processor => {
            const processorOutcome = processor(action, activeStage);
            if (processorOutcome) {
                const { promise, stop } = processorOutcome;
                agentActionTasks.push(promise);
                stopFunctions.push(stop);
            }
        });

        Promise.all(agentActionTasks).then(() => resolve());
        return { promise, stop };
    }, []);

    useEffect(() => {
        const textProcessor: ActionProcessor | undefined = action => {
            const { textual, chunks } = action.payload;
            if (!textual?.text) return;

            const { promise, resolve } = promiseWithResolvers();

            let displayText: AvatarText = [];
            if (chunks && chunks.length) {
                chunks.forEach(({ text, citations }) => {
                    const endsWithPunctuation = /[.!?]$/.test(text);
                    const punctuation = endsWithPunctuation
                        ? text[text.length - 1]
                        : '';
                    const mainText = endsWithPunctuation
                        ? text.slice(0, -1)
                        : text;
                    displayText.push(
                        ...mainText.split(''),
                        ...citations,
                        punctuation,
                    );
                });
            } else {
                displayText = textual.text.split('');
            }

            displayTextual(
                displayText,
                action.id,
                action.partial,
                !!textual.hidden,
                action.payload.media?.attachments,
                () => resolve(),
            );

            const stop = () => stopTextual(action.id);

            return {
                promise,
                stop,
            };
        };
        addActionProcessor('text', textProcessor);

        return () => {
            removeActionProcessor('text');
        };
    }, [
        addActionProcessor,
        displayTextual,
        removeActionProcessor,
        stopTextual,
    ]);

    const completeAction = useCallback(
        (id: any) => {
            const promises = runningActions.current
                .get(id)
                ?.map(runnable => runnable.promise);

            if (promises) {
                Promise.all(promises).finally(() => {
                    act(InteractionActions.processed);
                    runningActions.current.delete(id);
                });
            } else {
                act(InteractionActions.processed);
            }
        },
        [act],
    );

    const completeStage = useCallback(async (): Promise<{
        status?: string;
        error?: string;
    }> => {
        if (!activeStage) {
            throw new Error('No active stage');
        }

        if (chatConnectionRef.current !== null) {
            chatConnectionRef.current?.close(ChatWebsocketCodes.CLOSE_NORMAL);
        }

        try {
            const data = await exerciseAPI.post(chatUrl + 'complete/');
            if (data.stage.status === 'completed') {
                return { status: 'completed' };
            } else if (data.stage.status === 'cancelled') {
                return {
                    error:
                        'This interaction has been cancelled and can no longer be completed',
                };
            } else {
                return {
                    error: `Interaction with status ${data.stage.status} could not be completed`,
                };
            }
        } catch (e) {
            console.log(e);
            Sentry.captureException(e);
            return {
                error:
                    'An error has occurred while completing the attempt, try again later',
            };
        }
    }, [activeStage, chatUrl, exerciseAPI]);

    useEffect(() => {
        act(InteractionActions.initialise);
    }, [act]);

    useEffect(() => {
        let shouldStillUseConnection = true;
        const chatMessages = parseChatEntries(activeStage.entries);
        setMessages(chatMessages);

        const connOptions: ChatConnOptions = {
            chatUrl,
            activeStage: activeStage,
            act,
            sessionData: session,
            avatarRef,
            exerciseAPI: exerciseAPI,
            setActions: actions => {
                actions.forEach(action => {
                    if (interruptedActions.current.has(action.id)) {
                        return;
                    }

                    const { payload } = action;
                    const isFinal = !action.partial;
                    const hasFinishedActions = !!payload.control
                        ?.finished_actions.length;
                    const isProcessingRequired = payload.textual?.text;

                    // The below handles an edge case to this, if we don't receive any
                    // speak action, and want to early exit the processing state, usually
                    // at the start of interactions, the below conditions will take care of it
                    if (
                        !isProcessingRequired &&
                        !hasFinishedActions &&
                        isFinal
                    ) {
                        act(InteractionActions.processed);
                        return;
                    }

                    if (payload.control?.finished_actions.length) {
                        payload.control.finished_actions.forEach(
                            completeAction,
                        );
                        return;
                    }

                    if (action.id) {
                        act(InteractionActions.process);
                        if (!runningActions.current.has(action.id)) {
                            runningActions.current.set(action.id, []);
                        }

                        const actionParts = runningActions.current.get(
                            action.id,
                        )!;
                        const runningAction = runAction(action, activeStage);
                        actionParts.push(runningAction);

                        if (isFinal) {
                            completeAction(action.id);
                        }
                    }
                });
            },
            onSuccess: connection => {
                if (shouldStillUseConnection) {
                    chatConnectionRef.current = connection;
                } else {
                    connection.close(
                        ChatWebsocketCodes.CLOSE_NORMAL,
                        // escape hatch to prevent the state from updating to 'disconnected'
                        // because the new connection will have been initialised by that point
                        ChatWebsocketCloseReason.CLIENT_RECONNECT,
                    );
                    act(InteractionActions.disconnect);
                }
            },
            // Skips unnecessary reconnects
            shouldReconnect: () => shouldStillUseConnection,
            onError: console.log,
        };

        const openConn = useStreaming
            ? openChatWebsocketConn
            : openChatHttpConn;

        openConn(connOptions);

        return () => {
            shouldStillUseConnection = false;
            chatConnectionRef.current?.close(
                ChatWebsocketCodes.CLOSE_NORMAL,
                ChatWebsocketCloseReason.CLIENT_RECONNECT,
            );
            act(InteractionActions.disconnect);
        };
        // Missing deps: exerciseAPI, activeStage, session
        // they can change, but meaningful changes (when the effect needs to re-run) will be reflected
        // in a chatUrl change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [runAction, chatUrl, setMessages, useStreaming, act, completeAction]);

    const interrupt = useCallback(async () => {
        const promises: Promise<any>[] = [];
        Array.from(runningActions.current.entries()).forEach(
            ([id, runningActions]) => {
                interruptedActions.current.add(id);
                runningActions.forEach(ra => ra.stop());
                promises.push(...runningActions.map(ra => ra.promise));
            },
        );
        await Promise.all(promises);
    }, []);

    const shouldSkipTtsSynthesis = useRef(true);
    const setShouldSkipTtsSynthesis = useCallback(
        b => (shouldSkipTtsSynthesis.current = b),
        [],
    );
    const sendMessage = useCallback(
        async (message: string, meta: UserMessageMeta) => {
            await interrupt();

            addMessages({
                type: 'user',
                text: message,
                actionId: new Date().getTime(),
            });

            const requestData = {
                action_type: 'utterance',
                payload: {
                    text: message,
                    meta,
                },
                skip_tts_synthesis: shouldSkipTtsSynthesis.current,
            };
            chatConnectionRef.current?.send(JSON.stringify(requestData));
            act(InteractionActions.send);
        },
        [act, addMessages, interrupt],
    );

    return {
        act,
        hints,
        messages,
        activeStage,
        interrupt,
        sendMessage,
        agentState: status,
        isAgentBusy,
        isConnected,
        isDisabled,
        isTranscribing,
        setShouldSkipTtsSynthesis,
        awaitingResponse,
        addActionProcessor,
        removeActionProcessor,
        completeStage,
    };
};
export default useInteraction;
