import React from 'react';
import BasePage from './BasePage';
import PageNotFoundPageContent from '../components/generic/PageNotFoundPageContent';

const PageNotFoundPage: React.FC = () => {
    return (
        <BasePage>
            <PageNotFoundPageContent />
        </BasePage>
    );
};

export default PageNotFoundPage;
