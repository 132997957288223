import React, { useEffect, useMemo, useState } from 'react';
import UploadCaseForm, {
    CASE_TYPES,
    InitialValues,
    CREATION_METHODS,
    UploadCaseFromProps,
    CONSULTATION_TYPES,
} from '../components/forms/UploadCaseForm';
import BasePage from './BasePage';
import { Row, Col } from 'react-bootstrap';
import ROUTES from '../consts/routes';
import BackToNavButtons from '../components/generic/BackToNavButtons';
import { moocAPI } from '../services';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import useAsyncError from '../hooks/useAsyncError';
import CaseContentTypeModal from '../components/modals/CaseContentTypeModal';

const initialValuesFromQueryParams = (
    locationSearch: string,
): InitialValues => {
    const { caseType, group, title, consultationType } = queryString.parse(
        locationSearch,
    );

    let validatedCaseType: InitialValues['caseType'];
    if (
        typeof caseType === 'string' &&
        CASE_TYPES.includes(caseType.toLowerCase() as Case['case_type'])
    )
        validatedCaseType = caseType.toLowerCase() as Case['case_type'];
    else validatedCaseType = undefined;

    let validatedGroup: InitialValues['group'];
    if (typeof group === 'string')
        validatedGroup = isNaN(Number(group)) ? undefined : Number(group);
    else validatedGroup = undefined;

    let validatedTitle: InitialValues['title'];
    if (typeof title === 'string') validatedTitle = title;
    else validatedTitle = undefined;

    let validatedConsultationType: InitialValues['consultationType'];
    if (
        typeof consultationType === 'string' &&
        CONSULTATION_TYPES?.includes(
            consultationType.toLowerCase() as Case['consultation_type'],
        )
    ) {
        validatedConsultationType = consultationType.toLowerCase() as Case['consultation_type'];
    } else validatedConsultationType = undefined;

    return {
        caseType: validatedCaseType,
        group: validatedGroup,
        title: validatedTitle,
        consultationType: validatedConsultationType,
    };
};

const creationMethodFromQueryParams = (
    locationSearch: string,
): UploadCaseFromProps['creationMethod'] => {
    const { creationMethod } = queryString.parse(locationSearch);
    let validatedCreationMethod: UploadCaseFromProps['creationMethod'];

    if (typeof creationMethod === 'string') {
        if (CREATION_METHODS.includes(creationMethod.toUpperCase())) {
            validatedCreationMethod = creationMethod.toUpperCase();
        } else {
            validatedCreationMethod = undefined;
        }
    } else {
        validatedCreationMethod = undefined;
    }
    return validatedCreationMethod;
};

const UploadCasePage: React.FC = () => {
    const [groups, setGroups] = useState<Group[] | null>(null);
    const location = useLocation();
    const throwAsyncError = useAsyncError();
    const history = useHistory();

    useEffect(() => {
        if (!groups) {
            moocAPI
                .get('portfolio/groups/')
                .then(groups => {
                    setGroups(
                        groups.filter((group: Group) =>
                            group.user_permissions.includes('add_cases'),
                        ),
                    );
                })
                .catch(error => {
                    throwAsyncError(error);
                });
        }
    }, [groups, history, throwAsyncError]);

    const initialValues = useMemo(
        () => initialValuesFromQueryParams(location.search),
        [location.search],
    );
    const creationMethod = useMemo(
        () => creationMethodFromQueryParams(location.search),
        [location.search],
    );

    return (
        <BasePage>
            <Row className='mb-4'>
                <Col>
                    <BackToNavButtons
                        pageName='teams'
                        pageUrl={`/${ROUTES.PORTFOLIO}`}
                    />
                </Col>
            </Row>
            {!initialValues?.caseType && (
                <CaseContentTypeModal
                    show
                    required
                    onClose={() => {
                        /*This should not be closed*/
                    }}
                />
            )}
            <UploadCaseForm
                groups={groups}
                addGroup={group => setGroups(old => [group].concat(old!))}
                creationMethod={creationMethod}
                initialValues={initialValues}
            />
        </BasePage>
    );
};

export default UploadCasePage;
