export const popSearchParam = (
    param: string,
    defaultVal: any,
    history: any,
) => {
    const searchParams = new URLSearchParams(history.location.search);
    const paramValue = searchParams.get(param);
    if (paramValue !== null) {
        searchParams.delete(param);
        history.replace({
            search: searchParams.toString(),
        });
    }
    return paramValue ?? defaultVal;
};
