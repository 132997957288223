import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { moocAPI } from '../../services';
import * as Sentry from '@sentry/browser';
import APIService from '../../services/APIService';

type QueryStatus = 'idle' | 'loading';
interface CacheStore {
    [key: string]: {
        payload?: any;
        status: QueryStatus;
        result?: Promise<any>;
    };
}

type EmittedCallback<T> = (newData: T) => void;
type CacheUnsubscribe = () => void;

class CachePublisher<T> {
    subscribers: Map<symbol, EmittedCallback<T>>;
    constructor() {
        this.subscribers = new Map();
    }

    public emit(data: T): void {
        this.subscribers.forEach(onEmitted => onEmitted(data));
    }

    public subscribe(onEmitted: EmittedCallback<T>): CacheUnsubscribe {
        const key = Symbol();
        this.subscribers.set(key, onEmitted);
        return (): void => {
            this.subscribers.delete(key);
        };
    }
}

interface CachePublisherStore {
    [key: string]: CachePublisher<any>;
}

const QueryCacheContext = createContext<{
    cache: CacheStore;
    cachePublisher: CachePublisherStore;
}>({
    cache: {},
    cachePublisher: {},
});

const QueryCacheProvider: React.FC<{ initialStore?: CacheStore }> = ({
    children,
    initialStore = {},
}) => {
    const cacheStore = useRef<CacheStore>(initialStore);
    const cachePublisher = useRef<CachePublisherStore>({});

    return (
        <QueryCacheContext.Provider
            value={{
                cache: cacheStore.current,
                cachePublisher: cachePublisher.current,
            }}
        >
            {children}
        </QueryCacheContext.Provider>
    );
};

interface UseQueryCacheOptions {
    apiService?: APIService;
    refreshOnMount?: boolean;
    onError?: (error: any) => void;
}
const useQueryCache = <T,>(
    url: string,
    {
        apiService = moocAPI,
        refreshOnMount = false,
        onError = Sentry.captureException,
    }: UseQueryCacheOptions = {
        apiService: moocAPI,
        refreshOnMount: false,
        onError: Sentry.captureException,
    },
) => {
    const { cache, cachePublisher } = useContext(QueryCacheContext);
    const [localData, setLocalData] = useState<T>(cache[url]?.payload);

    useEffect(() => {
        if (!(url in cachePublisher)) {
            cachePublisher[url] = new CachePublisher();
        }
        const unsubscribe = cachePublisher[url].subscribe(data => {
            setLocalData(data);
        });
        return unsubscribe;
    }, [url, cachePublisher]);

    const setCache = useCallback(
        (data: T): void => {
            cache[url].payload = data;
            cachePublisher[url].emit(data);
        },
        [cache, url, cachePublisher],
    );

    const refreshData = useCallback((): Promise<T> => {
        if (cache[url].status === 'idle') {
            cache[url].status = 'loading';
            cache[url].result = apiService
                .get(url)
                .then(queryData => {
                    setCache(queryData);
                    cache[url].status = 'idle';
                    return queryData;
                })
                .catch(onError);
        }

        return cache[url].result!;
    }, [cache, url, apiService, onError, setCache]);

    useEffect(() => {
        if (!(url in cache)) {
            cache[url] = {
                status: 'idle',
            };
            refreshData();
        } else {
            setLocalData(cache[url].payload);
        }
    }, [url, cache, refreshData]);

    useEffect(() => {
        if (refreshOnMount) {
            refreshData();
        }
    }, [refreshData, refreshOnMount]);

    return useMemo(() => ({ data: localData, refreshData: refreshData }), [
        localData,
        refreshData,
    ]);
};

export { useQueryCache, QueryCacheProvider };
