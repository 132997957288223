import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { injectMetadata } from '../utils/mediaRecordingUtils';

export type ReactMediaRecorderRenderProps = {
    error: string;
    muteAudio: () => void;
    unMuteAudio: () => void;
    startRecording: () => void;
    pauseRecording: () => void;
    resumeRecording: () => void;
    stopRecording: () => void;
    mediaBlob: null | Blob;
    status: StatusMessages;
    isAudioMuted: boolean;
    previewStream: MediaStream | null;
    clearBlobUrl: () => void;
};

export type ReactMediaRecorderHookProps = {
    userMediaCapture: {
        audio?: boolean | MediaTrackConstraints;
        video?: boolean | MediaTrackConstraints;
    };
    screenCapture: {
        audio?: boolean | MediaTrackConstraints;
        video?: boolean | MediaTrackConstraints;
    };
    onStop?: (blob: Blob) => void;
    blobPropertyBag?: BlobPropertyBag;
    mediaRecorderOptions?: MediaRecorderOptions | null;
};

export type ReactMediaRecorderProps = ReactMediaRecorderHookProps & {
    render: (props: ReactMediaRecorderRenderProps) => ReactElement;
};

export type StatusMessages =
    | 'media_aborted'
    | 'permission_denied'
    | 'no_specified_media_found'
    | 'media_in_use'
    | 'invalid_media_constraints'
    | 'no_constraints'
    | 'recorder_error'
    | 'idle'
    | 'acquiring_media'
    | 'delayed_start'
    | 'recording'
    | 'paused'
    | 'stopping'
    | 'stopped';

export enum RecorderErrors {
    AbortError = 'media_aborted',
    NotAllowedError = 'permission_denied',
    NotFoundError = 'no_specified_media_found',
    NotReadableError = 'media_in_use',
    OverconstrainedError = 'invalid_media_constraints',
    TypeError = 'no_constraints',
    NO_RECORDER = 'recorder_error',
}

export function useReactMediaRecorder({
    userMediaCapture,
    screenCapture,
    onStop = () => null,
    blobPropertyBag,
    mediaRecorderOptions = null,
}: ReactMediaRecorderHookProps): ReactMediaRecorderRenderProps {
    // Raw streams
    const userMediaStream = useRef<MediaStream | null>(null);
    const screenCaptureMediaStream = useRef<MediaStream | null>(null);
    // Merges the two raw streams
    // needed because recording supports only one audio track
    const mediaStream = useRef<MediaStream | null>(null);

    const mediaRecorder = useRef<MediaRecorder | null>(null);
    const mediaChunks = useRef<Blob[]>([]);
    const [status, setStatus] = useState<StatusMessages>('idle');
    const [error, setError] = useState<keyof typeof RecorderErrors | null>(
        null,
    );
    const [isAudioMuted, setIsAudioMuted] = useState<boolean>(false);
    const [mediaBlob, setMediaBlob] = useState<Blob | null>(null);

    const onError = (error: any, throwFurther = false): void => {
        if (error instanceof Error) {
            const err_name = error.name as keyof typeof RecorderErrors;
            setError(err_name);
            setStatus(RecorderErrors[err_name]);
        }
        Sentry.captureException(error);
        if (throwFurther) {
            throw error;
        }
    };

    const getMediaStream = useCallback(async () => {
        setStatus('acquiring_media');

        try {
            mediaStream.current = new MediaStream();

            if (screenCapture && Object.values(screenCapture).some(Boolean)) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                screenCaptureMediaStream.current = await window.navigator.mediaDevices.getDisplayMedia(
                    screenCapture,
                );
            }

            if (
                userMediaCapture &&
                Object.values(userMediaCapture).some(Boolean)
            ) {
                userMediaStream.current = await window.navigator.mediaDevices.getUserMedia(
                    userMediaCapture,
                );
            }

            // Add screen video if existing
            if (screenCapture.video) {
                mediaStream.current?.addTrack(
                    screenCaptureMediaStream.current!.getVideoTracks()[0],
                );
            }

            // Merge audio tracks
            const screenAudioTs = screenCaptureMediaStream.current?.getAudioTracks();
            const userMediaAudioTs = userMediaStream.current?.getAudioTracks();
            if (
                screenAudioTs &&
                screenAudioTs.length &&
                userMediaAudioTs &&
                userMediaAudioTs.length
            ) {
                const audioCtx = new AudioContext();
                const audioSources = [
                    screenCaptureMediaStream.current,
                    userMediaStream.current,
                ].map(stream => audioCtx.createMediaStreamSource(stream!));
                const mergedAudio = audioCtx.createMediaStreamDestination();
                audioSources.forEach(as => as.connect(mergedAudio));
                mediaStream.current?.addTrack(
                    mergedAudio.stream.getAudioTracks()[0],
                );
            }
            // Select the one available
            else if (
                (screenAudioTs && screenAudioTs.length) ||
                (userMediaAudioTs && userMediaAudioTs.length)
            ) {
                const audioTrack = (screenAudioTs && screenAudioTs.length
                    ? screenAudioTs
                    : userMediaAudioTs)![0];
                if (audioTrack) {
                    mediaStream.current?.addTrack(audioTrack);
                }
            }
            setStatus('idle');
        } catch (err) {
            onError(err, true);
        }
    }, [userMediaCapture, screenCapture]);

    useEffect(() => {
        if (!window.MediaRecorder) {
            throw new Error('Unsupported Browser');
        }

        if (screenCapture) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (!window.navigator.mediaDevices.getDisplayMedia) {
                throw new Error(
                    "This browser doesn't support screen capturing",
                );
            }
        }

        const checkConstraints = (mediaType: MediaTrackConstraints) => {
            const supportedMediaConstraints = navigator.mediaDevices.getSupportedConstraints();
            const unSupportedConstraints = Object.keys(mediaType).filter(
                constraint =>
                    !(supportedMediaConstraints as { [key: string]: any })[
                        constraint
                    ],
            );

            if (unSupportedConstraints.length > 0) {
                setError('OverconstrainedError');
                Sentry.captureMessage(
                    `The constraints ${unSupportedConstraints.join(
                        ',',
                    )} doesn't support on this browser. Please check your ReactMediaRecorder component.`,
                    Sentry.Severity.Error,
                );
            }
        };

        [screenCapture, userMediaCapture].forEach(({ audio, video }) => {
            if (typeof audio === 'object') {
                checkConstraints(audio);
            }
            if (typeof video === 'object') {
                checkConstraints(video);
            }
        });

        if (mediaRecorderOptions && mediaRecorderOptions.mimeType) {
            if (!MediaRecorder.isTypeSupported(mediaRecorderOptions.mimeType)) {
                setError('OverconstrainedError');
                Sentry.withScope(scope => {
                    scope.setExtra('mime-type', mediaRecorderOptions.mimeType);
                    Sentry.captureMessage(
                        "The specified MIME type you supplied for MediaRecorder doesn't support this browser",
                    );
                });
            }
        }
    }, [userMediaCapture, screenCapture, getMediaStream, mediaRecorderOptions]);

    // Media Recorder Handlers
    const startRecording = async () => {
        try {
            setError(null);
            if (!mediaStream.current) {
                await getMediaStream();
            } else if (mediaStream.current) {
                const tracks = mediaStream.current.getTracks();
                if (!tracks.length) {
                    await getMediaStream();
                } else {
                    const isStreamEnded = tracks.some(
                        track => track.readyState === 'ended',
                    );
                    if (isStreamEnded) {
                        await getMediaStream();
                    }
                }
            }
            mediaRecorder.current = new MediaRecorder(mediaStream.current!);
            mediaRecorder.current.ondataavailable = onRecordingActive;
            mediaRecorder.current.onstop = onRecordingStop;
            mediaRecorder.current.onerror = () => {
                setError('NO_RECORDER');
                setStatus('idle');
            };

            // The user might end the screen share from the browser rather than the stop button in the UI
            mediaStream.current?.getVideoTracks().forEach(vt => {
                vt.onended = () => stopRecording();
            });

            try {
                mediaRecorder.current.start();
                setStatus('recording');
            } catch (e) {
                onError(e);
            }
        } catch (e) {
            onError(e);
        }
    };

    const onRecordingActive = ({ data }: BlobEvent): void => {
        mediaChunks.current.push(data);
    };

    const onRecordingStop = async () => {
        const [chunk] = mediaChunks.current;
        const blobProperty: BlobPropertyBag = Object.assign(
            { type: chunk.type },
            blobPropertyBag ||
                (screenCapture.video
                    ? { type: 'video/webm' }
                    : { type: 'audio/wav' }),
        );
        let blob = new Blob(mediaChunks.current, blobProperty);
        blob = await injectMetadata(blob);
        setStatus('stopped');
        setMediaBlob(blob);
        onStop(blob);
    };

    const muteAudio = (mute: boolean): void => {
        setIsAudioMuted(mute);
        if (mediaStream.current) {
            mediaStream.current
                .getAudioTracks()
                .forEach(audioTrack => (audioTrack.enabled = !mute));
        }
    };

    const pauseRecording = (): void => {
        if (
            mediaRecorder.current &&
            mediaRecorder.current.state === 'recording'
        ) {
            mediaRecorder.current.pause();
            setStatus('paused');
        }
    };

    const resumeRecording = (): void => {
        if (mediaRecorder.current && mediaRecorder.current.state === 'paused') {
            mediaRecorder.current.resume();
            setStatus('recording');
        }
    };

    const stopRecording = (): void => {
        if (mediaRecorder.current) {
            if (mediaRecorder.current.state !== 'inactive') {
                setStatus('stopping');
                mediaRecorder.current.stop();

                mediaStream.current?.getTracks().forEach(track => track.stop());
                mediaStream.current = null;

                userMediaStream.current
                    ?.getTracks()
                    .forEach(track => track.stop());
                userMediaStream.current = null;

                screenCaptureMediaStream.current
                    ?.getTracks()
                    .forEach(track => track.stop());
                screenCaptureMediaStream.current = null;

                mediaChunks.current = [];
            }
        }
    };

    const getPreviewStream = () => {
        if (mediaRecorder.current?.state !== 'inactive') {
            if (
                mediaStream.current &&
                mediaStream.current.getVideoTracks().length
            ) {
                return new MediaStream(mediaStream.current.getVideoTracks());
            }
        }
        return null;
    };

    return {
        error: error ? RecorderErrors[error] : '',
        muteAudio: (): void => muteAudio(true),
        unMuteAudio: (): void => muteAudio(false),
        startRecording,
        pauseRecording,
        resumeRecording,
        stopRecording,
        mediaBlob,
        status,
        isAudioMuted,
        previewStream: getPreviewStream(),
        clearBlobUrl: (): void => setMediaBlob(null),
    };
}

export const ReactMediaRecorder = (props: ReactMediaRecorderProps) =>
    props.render(useReactMediaRecorder(props));
