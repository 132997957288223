import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import RenderWithLoading from '../components/generic/RenderWithLoading';
import { useHistory, useLocation } from 'react-router-dom';
import { keycloak, moocAPI } from '../services';
import B2CPurchaseRegistrationForm from '../components/forms/B2CPurchaseRegistrationForm';
import AuthPageLayout, {
    RegistrationSubtitle,
    RegistrationTitle,
} from '../components/layouts/AuthPageLayout';

const B2CRegistrationPage: React.FC = () => {
    const location = useLocation();
    const history = useHistory();
    const [product, setProduct] = useState<OneOffProductInformation | null>(
        null,
    );

    useEffect(() => {
        const params = queryString.parse(location.search);
        if (!('product_id' in params)) {
            history.push('/');
        } else {
            const product_id = params['product_id'];
            moocAPI
                .get(`products/${product_id}/`, true)
                .then(product => {
                    if (!product) {
                        history.push('/');
                        return;
                    }
                    setProduct(product);
                })
                .catch(() => {
                    history.push('/');
                });
        }
    }, [history, location.search]);

    useEffect(() => {
        if (keycloak.authenticated) {
            history.replace('/');
        }
    }, [history]);

    return (
        <RenderWithLoading
            hasLoaded={product !== null && !keycloak.authenticated}
        >
            <AuthPageLayout
                title={RegistrationTitle}
                subTitle={<RegistrationSubtitle />}
            >
                <B2CPurchaseRegistrationForm product={product!} />
            </AuthPageLayout>
        </RenderWithLoading>
    );
};

export default B2CRegistrationPage;
