import React from 'react';
import {
    Box,
    Button,
    Flex,
    HStack,
    Spacer,
    VStack,
    Text,
    AspectRatio,
} from '@chakra-ui/react';
import IndexNumber from './IndexNumber';
import ActivityHeading from './ActivityHeading';
import ExpandableText from '../../../components/generic/ExpandableText';
import {
    isExercise,
    isReading,
    isVideo,
    isWritingAssignment,
} from '../../../utils/activityUtils';
import WritingAssignmentLabel from './WritingAssignmentLabel';
import ModalOpener from '../../../components/generic/ModalOpener';
import { hasAttemptInProgress } from './utils';
import { ActivityImage } from '../../../components/activities/ActivityIcon';
import BestAttempt from './BestAttempt';
import getHumanReadableFromDate from '../../../utils/dateUtils';
import { UseMutationResult } from '@tanstack/react-query';
import { ModalProps } from '../../../components/modals/types';

export interface ActivityPresentationProps {
    activity: Activity;
    scrollRef: React.RefObject<HTMLDivElement>;
    index?: number;
    isHighlighted: boolean | undefined;
    screenWidthGreatherThan950: boolean;
    studentActivity: StudentActivity | null | undefined;
    cancelAttemptMutation: UseMutationResult<
        void,
        any,
        {
            course: Course | undefined;
            activity: Activity;
            attempt: StudentExerciseAttempt;
        },
        void
    >;
    startActivity: () => void;
    hasRemainingAttemptsToday: boolean;
    hasRemainingTotalAttempts: boolean;
    course?: Course;
    completedAttempts: StudentExerciseAttempt[];
    insightsModal: {
        (props: ModalProps): JSX.Element;
        display_name: string;
    };
    viewWritingModal: {
        (props: ModalProps): JSX.Element;
        display_name: string;
    };
}

export default function DetailedView({
    activity,
    scrollRef,
    index,
    isHighlighted,
    screenWidthGreatherThan950,
    studentActivity,
    cancelAttemptMutation,
    startActivity,
    hasRemainingAttemptsToday,
    hasRemainingTotalAttempts,
    course,
    completedAttempts,
    insightsModal,
    viewWritingModal,
}: ActivityPresentationProps) {
    return (
        <HStack
            id={`activity-card_${activity.id}`}
            ref={scrollRef}
            borderRadius='lg'
            bg='white'
            px={5}
            py={6}
            alignItems='top'
            spacing={4}
        >
            <HStack spacing={4} alignSelf='start'>
                {index && (
                    <IndexNumber
                        activity={activity}
                        index={index}
                        isActive={isHighlighted}
                    />
                )}
                {screenWidthGreatherThan950 && (
                    <Box flex='0 0 auto'>
                        <AspectRatio ratio={3 / 2} width='260px'>
                            <ActivityImage activity={activity} />
                        </AspectRatio>
                    </Box>
                )}
            </HStack>
            <Flex flex={1} alignSelf='stretch' direction='column'>
                <VStack alignItems='start'>
                    <Flex alignSelf='stretch' alignItems='center' mb={1}>
                        <ActivityHeading
                            activity={activity}
                            studentActivity={studentActivity}
                        />
                    </Flex>
                    <ExpandableText noOfLines={2} lineHeight='1.3em'>
                        <Box
                            lineHeight='1.3em'
                            mb='-1rem' // Countering the default 1rem padding on p elements
                            dangerouslySetInnerHTML={{
                                __html: activity.description,
                            }}
                        />
                    </ExpandableText>
                </VStack>

                <Spacer />
                {!!studentActivity?.attempts.length && (
                    <BestAttempt attempts={studentActivity.attempts} />
                )}
                {(isVideo(activity) ||
                    isReading(activity) ||
                    !!activity.config.pass_threshold) &&
                    studentActivity?.completed_datetime &&
                    `Completed at ${getHumanReadableFromDate(
                        studentActivity?.completed_datetime,
                    )}`}
                {isWritingAssignment(activity) && (
                    <WritingAssignmentLabel
                        writingAssignment={activity.writing_assignment}
                    />
                )}
                <HStack mt={2}>
                    {!activity.writing_assignment?.submitted_at && ( // We don't support reattempts for writings
                        <Button
                            colorScheme='brand.black'
                            _hover={{ color: 'white' }}
                            onClick={() => startActivity()}
                            disabled={
                                cancelAttemptMutation.isLoading ||
                                (isExercise(activity) &&
                                    !hasAttemptInProgress(
                                        activity,
                                        studentActivity,
                                    ) &&
                                    (!hasRemainingAttemptsToday ||
                                        !hasRemainingTotalAttempts))
                            }
                        >
                            {hasAttemptInProgress(activity, studentActivity)
                                ? 'Resume'
                                : studentActivity?.attempts.length
                                ? 'Reattempt'
                                : 'Start activity'}
                        </Button>
                    )}
                    {!activity.writing_assignment &&
                    hasAttemptInProgress(activity, studentActivity) && ( // We don't support cancellations for writings
                            <Button
                                variant='outline'
                                colorScheme='brand.black'
                                onClick={() =>
                                    cancelAttemptMutation.mutate({
                                        course,
                                        activity,
                                        attempt: studentActivity!.attempts.find(
                                            a => a.completed_datetime === null,
                                        )!,
                                    })
                                }
                                isLoading={cancelAttemptMutation.isLoading}
                                loadingText='Cancelling'
                            >
                                Cancel
                            </Button>
                        )}
                    {completedAttempts.length > 0 && (
                        <ModalOpener Modal={insightsModal}>
                            <Button variant='outline' colorScheme='brand.black'>
                                View feedback
                            </Button>
                        </ModalOpener>
                    )}
                    {isWritingAssignment(activity) &&
                        (activity.writing_assignment?.submitted_at ||
                            activity.writing_assignment?.saved_draft_at) && (
                            <ModalOpener Modal={viewWritingModal}>
                                <Button
                                    variant='outline'
                                    colorScheme='brand.black'
                                >
                                    View
                                </Button>
                            </ModalOpener>
                        )}
                </HStack>
                {isExercise(activity) &&
                    !hasAttemptInProgress(activity, studentActivity) &&
                    (!hasRemainingTotalAttempts ||
                        !hasRemainingAttemptsToday) && (
                        <Text mb={0} color='red.500'>
                            {!hasRemainingTotalAttempts
                                ? 'No attempts remaining'
                                : 'No attempts remaining today'}
                        </Text>
                    )}
            </Flex>
        </HStack>
    );
}
