import * as React from 'react';
import { TextareaProps, Textarea } from '@chakra-ui/react';
import { scrollBarStyles } from '../../theme/consts';
import AutoResizeTextarea from 'react-textarea-autosize';

const textAreaNoHover = {
    _hover: {
        borderColor: 'inherit',
    },
    _focus: {
        borderColor: 'inherit',
        boxShadow: 'none',
    },
    _active: {
        borderColor: 'inherit',
        boxShadow: 'none',
    },
};

const UserInputField: React.FC<TextareaProps> = textAreaProps => {
    return (
        <Textarea
            minH='unset'
            name='message'
            resize='none'
            color='white'
            border='none'
            mr='10px'
            p='0px 5px 0px 0px'
            sx={{ ...scrollBarStyles, ...textAreaNoHover }}
            minRows={1}
            maxRows={4}
            as={AutoResizeTextarea}
            {...textAreaProps}
        />
    );
};

export default UserInputField;
