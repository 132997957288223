import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Alert, ListGroup, Modal } from 'react-bootstrap';
import usePortal from '../../utils/usePortal';
import { moocAPI } from '../../services';
import RenderWithLoading from '../generic/RenderWithLoading';
import * as Sentry from '@sentry/browser';
import SurveyForm, { SurveyQuestion } from '../forms/SurveyForm';
import { useMixpanel } from '../contexts/MixpanelContext';

interface Props {
    show: boolean;
    onClose: () => void;
}

const MESSAGES = {
    MODAL_TITLE: "We'd love to hear your thoughts",
    SUBMITTED: 'Thank you for your feedback!',
    ERROR_SUBMITTING:
        "An error occurred while submitting your feedback. We'd still love to hear from you, so please try again later.",
    ERROR_FETCHING:
        "Our feedback form is currently unavailable. We'd still love to hear from you, so please try again later.",
};

const UserFeedbackModal: React.FC<Props> = ({ show, onClose }) => {
    const target = usePortal('modal-root');
    const [surveyQuestions, setSurveyQuestions] = useState<
        SurveyQuestion[] | undefined
    >(undefined);
    const [selectedQuestion, setSelectedQuestion] = useState<
        SurveyQuestion | undefined
    >(undefined);

    const [alert, setAlert] = useState<
        'submitted' | 'error-submitting' | 'error-fetching' | null
    >(null);

    const onSubmit = useCallback(
        (values: any) =>
            moocAPI.post(`survey/responses/?question=${selectedQuestion?.id}`, {
                content: values,
            }),
        [selectedQuestion],
    );
    const onEdit = useCallback(
        (responseId: number, values: any) =>
            moocAPI.patch(`survey/responses/${responseId}/`, {
                content: values,
            }),
        [],
    );

    const onSuccess = useCallback(() => {
        setAlert('submitted');
    }, []);

    useEffect(() => {
        let timeout: any;
        if (alert === 'submitted') {
            timeout = setTimeout(onClose, 1200);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [alert, onClose]);

    const onError = useCallback(() => setAlert('error-submitting'), []);

    useEffect(() => {
        if (show) {
            setSurveyQuestions(undefined);
            setSelectedQuestion(undefined);
            setAlert(null);

            moocAPI
                .get('survey/questions/')
                .then(setSurveyQuestions)
                .catch(e => {
                    Sentry.captureException(e);
                    setAlert('error-fetching');
                });
        }
    }, [show]);

    const mixpanel = useMixpanel();
    useEffect(() => {
        if (show) {
            mixpanel?.track('platform_feedback_modal_opened');
        }
    }, [show, mixpanel]);

    return (
        <Fragment>
            <style>
                {`
                    .user-feedback-dialogue {
                        max-width: 600px;
                    } 
                `}
            </style>

            <Modal
                show={show}
                onHide={onClose}
                container={target}
                dialogClassName='user-feedback-dialogue'
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {selectedQuestion
                            ? selectedQuestion.title
                            : MESSAGES.MODAL_TITLE}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <RenderWithLoading hasLoaded={!!surveyQuestions}>
                        {alert ? (
                            <Alert
                                variant={
                                    alert === 'submitted' ? 'success' : 'danger'
                                }
                            >
                                {alert === 'submitted'
                                    ? MESSAGES.SUBMITTED
                                    : alert === 'error-submitting'
                                    ? MESSAGES.ERROR_SUBMITTING
                                    : MESSAGES.ERROR_FETCHING}
                            </Alert>
                        ) : selectedQuestion === undefined ? (
                            <ListGroup>
                                {surveyQuestions?.map(sq => (
                                    <ListGroup.Item
                                        key={sq.slug}
                                        className='btn-outline-primary'
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setSelectedQuestion(sq)}
                                    >
                                        {sq.title}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        ) : (
                            <Fragment>
                                {selectedQuestion.description && (
                                    <p> {selectedQuestion.description} </p>
                                )}
                                <SurveyForm
                                    key={selectedQuestion.id}
                                    onSubmit={onSubmit}
                                    onEdit={onEdit}
                                    onSuccess={onSuccess}
                                    onError={onError}
                                    surveyTemplate={selectedQuestion.template}
                                />
                            </Fragment>
                        )}
                    </RenderWithLoading>
                </Modal.Body>
            </Modal>
        </Fragment>
    );
};

export default UserFeedbackModal;
