import React from 'react';
import LabeledDate from '../../../components/generic/LabeledDate';
import getHumanReadableFromDate from '../../../utils/dateUtils';
import { isNumber } from 'lodash';

const BestAttempt: React.FC<{ attempts: StudentExerciseAttempt[] }> = ({
    attempts,
}) => {
    const bestAttempt = attempts
        .filter(a => a.completed_datetime !== null)
        .sort((a1, a2) => a2.score - a1.score)[0];

    if (!bestAttempt) {
        return null;
    }

    return (
        <LabeledDate
            label='Best attempt:'
            date={bestAttempt.completed_datetime!}
            dateFormatter={date => {
                const scorePrefix = isNumber(bestAttempt.score)
                    ? `${bestAttempt.score}% | `
                    : '';
                return scorePrefix + getHumanReadableFromDate(date);
            }}
        />
    );
};

export default BestAttempt;
