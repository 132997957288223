import React, { CSSProperties } from 'react';
import { Spinner } from 'react-bootstrap';
import './style.scss';

const LoadingSpinner = ({ style }: { style?: CSSProperties }) => (
    <Spinner style={style} variant='primary' animation='border' role='status'>
        <span className='sr-only'>Loading...</span>
    </Spinner>
);

const NewLoadingSpinner: React.FC<{
    className?: string;
    style?: CSSProperties;
    size?: 'sm' | 'md' | 'lg';
}> = ({ children, className, style, size = 'sm' }) => {
    const itemsOnCircle = 18;
    return (
        <div
            className={`circle-container-${size} d-flex justify-content-center align-items-center`}
        >
            {[...Array(itemsOnCircle).keys()].map(i => (
                <div
                    key={i}
                    className='item-on-circle expanding'
                    style={{ animationDelay: `${i * 0.25}s` }}
                />
            ))}
            {children}
        </div>
    );
};

export { NewLoadingSpinner };
export default LoadingSpinner;
