import React, { Fragment, useState } from 'react';
import { Formik, FormikValues, FormikErrors } from 'formik';
import { Form, Button, Col, Spinner, Alert } from 'react-bootstrap';
import { UserData } from './UserSettingsPageContent';
import useAsyncError from '../../hooks/useAsyncError';
import { EMAIL_ALREADY_IN_USE } from '../../consts/errors';

const SubmitButton: React.FC<{
    disabled: boolean;
    isSubmitting: boolean;
    title: string;
}> = ({ disabled, isSubmitting, title }) => {
    return (
        <Form.Row className='mt-5 justify-content-center'>
            <Col>
                <Button
                    block
                    variant='primary'
                    type='submit'
                    style={{ width: 170, margin: '0 auto' }}
                    disabled={disabled}
                >
                    {isSubmitting ? (
                        <Fragment>
                            <Spinner
                                as='span'
                                animation='border'
                                size='sm'
                                role='status'
                                aria-hidden='true'
                                style={{ marginRight: 5 }}
                            />
                            <span className='sr-only'>Saving...</span>
                            Saving...
                        </Fragment>
                    ) : (
                        <Fragment>{title}</Fragment>
                    )}
                </Button>
            </Col>
        </Form.Row>
    );
};

export { SubmitButton };

interface Props {
    userData: UserData;
    onSubmit: (values: FormikValues) => Promise<any>;
}

const UserDetailsForm: React.FC<Props> = ({ userData, onSubmit }) => {
    const [startedEditing, setStartedEditing] = useState<boolean>(false);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const throwAsyncError = useAsyncError();

    const validateName = (name: string, value: string) => {
        if (!value) {
            return `${name} is required`;
        }

        if (value.match(/[^a-z ]/gi)) {
            return `Only alphabets or spaces are allowed.`;
        }

        return;
    };

    const validate = (values: {
        [name: string]: string;
    }): { [name: string]: string | null | undefined } => {
        const errors: {
            first_name?: null | string;
            last_name?: null | string;
            email?: null | string;
        } = {};

        if (
            (values.first_name !== intitialValues.first_name ||
                values.last_name !== intitialValues.last_name ||
                values.email !== intitialValues.email) &&
            !startedEditing
        ) {
            setStartedEditing(true);
        }

        const firstNameError = validateName('First Name', values.first_name);
        if (firstNameError) {
            errors.first_name = firstNameError;
        }

        const lastNameError = validateName('Last Name', values.last_name);
        if (lastNameError) {
            errors.last_name = lastNameError;
        }

        if (!values.email) {
            errors.email = 'Email required';
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
            errors.email = 'Invalid email address';
        }

        return errors;
    };

    const submit = (
        values: FormikValues,
        {
            setErrors,
            setSubmitting,
        }: {
            setErrors: (errors: FormikErrors<FormikValues>) => void;
            setSubmitting: (val: boolean) => void;
        },
    ): void => {
        setSubmitting(true);
        onSubmit(values)
            .then(() => {
                setShowConfirmation(true);
                setTimeout(() => setShowConfirmation(false), 3500);
                setStartedEditing(false);
            })
            .catch(error => {
                if (error.name === 'unique') {
                    setErrors({
                        email: EMAIL_ALREADY_IN_USE,
                    });
                } else {
                    throwAsyncError(error);
                }
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const intitialValues = {
        first_name: userData.first_name!,
        last_name: userData.last_name!,
        email: userData.email!,
    };

    return (
        <Formik
            initialValues={intitialValues}
            validate={validate}
            onSubmit={submit}
            enableReinitialize
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
            }): any => (
                <Form noValidate onSubmit={handleSubmit}>
                    <h4 className='h4'>User details</h4>
                    {showConfirmation && (
                        <Alert variant='success'>
                            Details updated successfully
                        </Alert>
                    )}
                    <Form.Row>
                        <Col md={6}>
                            <Form.Group controlId='first_name'>
                                <Form.Label>First name</Form.Label>
                                <Form.Control
                                    type='input'
                                    placeholder='First name'
                                    required
                                    isInvalid={
                                        touched.first_name &&
                                        !!errors.first_name
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.first_name}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.first_name}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId='last_name'>
                                <Form.Label>Last name</Form.Label>
                                <Form.Control
                                    type='input'
                                    placeholder='Last name'
                                    required
                                    isInvalid={
                                        touched.last_name && !!errors.last_name
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.last_name}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.last_name}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Form.Row>

                    <Form.Group controlId='email'>
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                            type='email'
                            placeholder='Email address'
                            disabled
                            required
                            isInvalid={touched.email && !!errors.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                        />
                        <Form.Control.Feedback type='invalid'>
                            {errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <SubmitButton
                        title={'Update details'}
                        disabled={isSubmitting || !startedEditing}
                        isSubmitting={isSubmitting}
                    />
                </Form>
            )}
        </Formik>
    );
};

export default UserDetailsForm;
