import React, { Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import PatientIdentifiableInformationModal from '../modals/PatietntIdentifiableInformationModal';

const PersonalInformationWarning: React.FC<{ iconOnly?: boolean }> = props => {
    const [showPIIModal, setShowPIIModal] = useState<boolean>(false);
    return (
        <Fragment>
            <Button variant='link' className='py-0'>
                <FontAwesomeIcon
                    icon={faQuestionCircle}
                    onClick={() => {
                        setShowPIIModal(true);
                    }}
                />
            </Button>
            {!props.iconOnly && 'Do not include patient specific information'}
            <PatientIdentifiableInformationModal
                show={showPIIModal}
                onClose={() => setShowPIIModal(false)}
            />
        </Fragment>
    );
};

export default PersonalInformationWarning;
