import { UNKNOWN_ERROR } from '../consts/errors';
import { UserData } from '../components/user/UserSettingsPageContent';
import findLocalItems from '../utils/getLocalStorageByPattern';
import * as Sentry from '@sentry/browser';
import * as FullStory from '@fullstory/browser';
import mixpanel from 'mixpanel-browser';
import Keycloak from 'keycloak-js';
import APIService from './APIService';
import IDPS from '../consts/external_idps';
import config from '../consts/config';

const keycloak = Keycloak({
    url: config.REACT_APP_AUTH_ENDPOINT,
    realm: config.REACT_APP_AUTH_REALM!,
    clientId: config.REACT_APP_AUTH_CLIENT_ID!,
});

const authUrl = `${config.REACT_APP_AUTH_ENDPOINT}/realms/${config.REACT_APP_AUTH_REALM}/protocol/openid-connect/token`;

export default class AuthenticationService {
    async makeManualLoginRequest(
        username: string,
        password: string,
    ): Promise<any> {
        // keycloak requires URL-encoded form data
        const body = new URLSearchParams();
        body.append('username', username);
        body.append('password', password);
        body.append('grant_type', 'password');
        body.append('client_id', config.REACT_APP_AUTH_CLIENT_ID || '');
        return fetch(authUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body,
        });
    }

    register(
        moocAPI: APIService,
        firstName: string,
        lastName: string,
        emailAddress: string,
        password: string,
        invitation?: string,
    ): Promise<boolean> {
        const body = {
            user: {
                first_name: firstName,
                last_name: lastName,
                email: emailAddress,
                password: password,
            },
            invitation: invitation ?? null,
        };

        return moocAPI
            .post('student/create', body, true)
            .then(data => {
                mixpanel.alias(data.user.id.toString());
                mixpanel?.track('register');
                return this.makeManualLoginRequest(emailAddress, password)
                    .then(auth => auth.json())
                    .then((authData: any) => {
                        // Store tokens so that we can reuse them as long as they are valid after registration
                        localStorage.setItem(
                            'access_token',
                            authData.access_token,
                        );
                        localStorage.setItem(
                            'refresh_token',
                            authData.refresh_token,
                        );

                        return keycloak.init({
                            token: authData.access_token,
                            refreshToken: authData.refresh_token,
                            onLoad: 'check-sso',
                            checkLoginIframe: false,
                        });
                    })
                    .then(auth => {
                        if (!auth) {
                            keycloak.login({ loginHint: emailAddress });
                        }
                        return auth;
                    })
                    .catch(() =>
                        keycloak
                            .login({ loginHint: emailAddress })
                            .then(() => false),
                    );
            })
            .catch(error => {
                Sentry.captureException(error);
                throw new Error(error?.message ?? UNKNOWN_ERROR);
            });
    }

    initUserLoggers(userData: UserData): void {
        const userId = userData.student?.user.idp_id;

        if (userId) {
            const displayName = `${userData.student?.user.idp}-${userData.student?.user.idp_id}`;
            if (FullStory.isInitialized()) {
                FullStory.identify(userId, {
                    displayName,
                    idp: userData.student?.user.idp,
                    oragnisation: userData.student?.organisation.id,
                    oragnisation_role: userData.student?.organisation_role.id,
                });
            }
            mixpanel.identify(userId);
            mixpanel.people.set({
                idp: userData.student?.user.idp,
                organisation: userData.student?.organisation.id,
                oragnisation_role: userData.student?.organisation_role.id,
            });

            Sentry.configureScope(scope => {
                scope.setUser({
                    id: userId,
                    idp: userData.student?.user.idp,
                    organisation: userData.student?.organisation.id,
                    organisation_role: userData.student?.organisation_role.id,
                });
                scope.setTag(
                    'user_organisation',
                    userData.student?.organisation.id,
                );
                scope.setTag(
                    'user_organisation_role',
                    userData.student?.organisation_role.id,
                );
            });

            mixpanel?.track('login');
            localStorage.setItem('user_id', userId);
        }
    }

    logout(): void {
        // Remove tokens that were saved upon registration
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        this.clearUserLoggers();
        let redirectUri = window.location.origin;
        const possibleIDPHint = window.location.pathname.split('/')[1];
        if (IDPS.includes(possibleIDPHint)) {
            redirectUri += `/${possibleIDPHint}`;
        }
        keycloak.logout({ redirectUri });
    }

    clearUserLoggers(): void {
        mixpanel.reset();
        Sentry.configureScope(scope => {
            scope.setTag('user_organisation', undefined);
            scope.setTag('user_organisation_role', undefined);
            scope.setUser(null);
        });
    }

    clearUserLocalStorage(): void {
        const userId = localStorage.getItem('user_id');
        const userItems: any[] = findLocalItems(`${userId}_`);

        for (const i in userItems) {
            const userItem = userItems[i];
            localStorage.removeItem(userItem.key);
        }
        localStorage.removeItem('user_id');
    }

    isSubscribed(): boolean {
        const userId = localStorage.getItem('user_id');
        const subscribedVal = localStorage.getItem(`${userId}_subscribed`);
        if (!subscribedVal) {
            return false;
        }
        return subscribedVal === 'true';
    }
}

export const authService = new AuthenticationService();
export { keycloak };
