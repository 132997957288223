import React from 'react';
import BasePage from './BasePage';
import CaseOverviewPageContent from '../components/portfolio/CaseOverviewPageContent';
import { Mixpanel } from 'mixpanel-browser';
import { MixpanelConsumer } from '../components/contexts/MixpanelContext';

const CaseOverviewPage: React.FC = props => {
    return (
        <BasePage {...props}>
            <MixpanelConsumer>
                {(mixpanel?: Mixpanel): JSX.Element => (
                    <CaseOverviewPageContent mixpanel={mixpanel} />
                )}
            </MixpanelConsumer>
        </BasePage>
    );
};

export default CaseOverviewPage;
