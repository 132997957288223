import React, { Fragment } from 'react';
import { InteractionStageFeedback } from '../types';
import { Box, Heading, Text } from '@chakra-ui/react';
import { LLMStage } from './types';

const LLMFeedback: React.FC<InteractionStageFeedback<LLMStage>> = ({
    marking_feedback,
}) => {
    if (!marking_feedback) return null;

    return (
        <Box>
            {(!!marking_feedback.feedback.available_points ||
                !!marking_feedback.feedback.generated_feedback) && (
                <Heading size='lg'>
                    {marking_feedback.feedback.available_points ? (
                        <Fragment>
                            Overall Score {marking_feedback.feedback.score}%
                        </Fragment>
                    ) : (
                        <Fragment>Feedback Overview</Fragment>
                    )}
                </Heading>
            )}
            {marking_feedback.feedback.generated_feedback && (
                <Text whiteSpace='pre-line'>
                    {marking_feedback.feedback.generated_feedback}
                </Text>
            )}
            {marking_feedback.sections?.map(
                ({ title, description, feedback }, i) => (
                    <Box key={title}>
                        <Heading as='h3' size='lg'>
                            {title}
                            {!!feedback.available_points &&
                                ` (${feedback.awarded_points} / ${feedback.available_points})`}
                        </Heading>
                        <Text mb={2} whiteSpace='pre-line'>
                            {description}
                        </Text>
                        <Text whiteSpace='pre-line'>
                            {feedback.generated_feedback}
                        </Text>
                    </Box>
                ),
            )}
        </Box>
    );
};

export default LLMFeedback;
