/**
 * @deprecated This approach result in Force reflow (or Layout Reflow)
 * https://yonatankra.com/layout-reflow/
 * Use this approach instead: https://github.com/chakra-ui/chakra-ui/issues/670#issuecomment-669916624
 */
export const updateTextareaRows = (
    textarea: HTMLTextAreaElement,
    minRows: number,
    maxRows: number,
): void => {
    textarea.rows = minRows;
    let { clientHeight, scrollHeight } = textarea;

    while (textarea.rows < maxRows && clientHeight < scrollHeight) {
        textarea.rows += 1;
        clientHeight = textarea.clientHeight;
        scrollHeight = textarea.scrollHeight;
    }
};

export const isInputElement = (elem: HTMLElement | undefined): boolean => {
    const inputs = ['input', 'select', 'textarea'];
    return !!elem && inputs.includes(elem.tagName.toLowerCase());
};
