export interface FPSMeterResult {
    fps: number;
    stop: () => void;
}

export function getFps(
    onValueUpdate?: (val: number) => void,
    updateInterval = 1000,
    alertThresholds = [15, 20, 30],
): FPSMeterResult {
    let startTime = performance.now();
    let frames = 0;
    let reqId: number;
    const scaleFactor = updateInterval / 1000;
    const alertsOn = alertThresholds.map(() => false);
    const result = {
        fps: 0,
        stop: () => {
            /* EMPTY */
        },
    };

    const fpsCountingLoop = () => {
        const nowTime = performance.now();
        frames += 1;

        const timeDiff = nowTime - startTime;
        if (timeDiff >= updateInterval) {
            const fps = Math.round(((frames * 1000) / timeDiff) * scaleFactor);
            startTime = nowTime;
            frames = 0;

            if (onValueUpdate) {
                onValueUpdate(fps);
            }
            result.fps = fps;

            alertThresholds.forEach((threshold, i) => {
                if (alertsOn[i]) {
                    if (fps >= threshold) {
                        console.info(`^ FPS above ${threshold} - ${fps}`);
                        alertsOn[i] = false;
                    }
                } else {
                    if (fps < threshold) {
                        console.warn(`v FPS below ${threshold} - ${fps}`);
                        alertsOn[i] = true;
                    }
                }
            });
        }

        reqId = requestAnimationFrame(fpsCountingLoop);
        result.stop = () => cancelAnimationFrame(reqId);
    };

    reqId = requestAnimationFrame(fpsCountingLoop);
    result.stop = () => cancelAnimationFrame(reqId);

    return result;
}
