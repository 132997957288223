import React from 'react';
import { Col, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Formik, FormikHelpers, FormikValues } from 'formik';

interface Props {
    // This form is used for both updates and creates in various context
    // so the submission method should be flexible
    onSubmit: (values: FormikValues, formikHelpers: FormikHelpers<any>) => void;
    onCancel?: () => void;
    groupNameInitial?: string;
}
const GroupForm: React.FC<Props> = ({
    onSubmit,
    onCancel,
    groupNameInitial = '',
}) => {
    const validate = (values: {
        [name: string]: string;
    }): { [name: string]: string | null | undefined } => {
        const errors: {
            groupName?: null | string;
        } = {};

        if (!values.groupName) {
            errors.groupName = 'Group name required';
        } else if (values.groupName.length > 128) {
            errors.groupName =
                'Group name should have less than 128 characters';
        }
        return errors;
    };

    return (
        <Formik
            initialValues={{ groupName: groupNameInitial }}
            onSubmit={onSubmit}
            validate={validate}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
            }): JSX.Element => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Row>
                        <Col>
                            <Form.Group controlId='groupName' className='mb-0'>
                                <Form.Control
                                    type='text'
                                    name='groupName'
                                    placeholder='Group name'
                                    required
                                    isInvalid={
                                        touched.groupName && !!errors.groupName
                                    }
                                    value={values.groupName}
                                    onChange={handleChange}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.groupName}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col className='flex-grow-0'>
                            <Button variant='light' type='submit'>
                                <FontAwesomeIcon icon={faCheck} />
                            </Button>
                        </Col>
                        {onCancel && (
                            <Col>
                                <Button
                                    variant='light'
                                    type='submit'
                                    onClick={onCancel}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                            </Col>
                        )}
                    </Form.Row>
                </Form>
            )}
        </Formik>
    );
};

export default GroupForm;
