import React from 'react';
import {
    AspectRatio,
    Box,
    Button,
    Circle,
    Flex,
    Heading,
    HStack,
    Image,
    Skeleton,
    Spacer,
    Text,
} from '@chakra-ui/react';
import { HeaderHeight } from '../../components/layouts/DashboardPageLayout';
import { useCourse } from './useCourse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import getHumanReadableFromDate from '../../utils/dateUtils';

const fadeGradient =
    'linear-gradient(135deg, var(--chakra-colors-blackAlpha-700) 20%, var(--chakra-colors-blackAlpha-50) 60%)';

export const CompletedBox: React.FC<{ course: Course }> = ({ course }) => {
    return (
        <Flex>
            <Box flexGrow={1}>
                <Text fontWeight='bold'>
                    You have successfully finished the course
                </Text>
                <HStack>
                    <Circle size='2em' bgColor='green.400' textColor='white'>
                        <FontAwesomeIcon icon={faCheck} />
                    </Circle>
                    <Text>
                        <strong>Completed:</strong>{' '}
                        {getHumanReadableFromDate(
                            course.student_course?.completed_datetime,
                        )}
                    </Text>
                </HStack>
            </Box>
            {course.student_course?.score && (
                <Box borderLeft='1px solid black' pl={5}>
                    <Text fontWeight='bold' mb={0}>
                        Your grade
                    </Text>
                    <Text fontSize='4xl' fontFamily='telegraf' mb={0}>
                        {course.student_course?.score}%
                    </Text>
                </Box>
            )}
        </Flex>
    );
};

const PageHeader: React.FC<{ courseId: string }> = ({ courseId }) => {
    const { course, isLoading, enrollMutation } = useCourse(courseId, {
        enabled: false,
    });

    const isEnrolled = !!course?.student_course;
    const isCompleted = !!course?.student_course?.completed_datetime;

    const height = isCompleted ? '475px' : '385px';

    if (isLoading || !course) {
        return (
            <Skeleton
                speed={0}
                isLoaded={!isLoading && !!course}
                height='350px'
            />
        );
    }

    return (
        <Box
            bg={!course.overview_image_url ? 'brand.gradient.full' : undefined}
            bgImage={
                course.overview_image_url
                    ? `${fadeGradient}, url(${course.overview_image_url})`
                    : undefined
            }
            bgRepeat='no-repeat'
            bgSize='cover'
            bgPosition='right top'
            textColor='white'
            w='100%'
            h={height}
        >
            <Box h={HeaderHeight} />
            <Flex px={20} py={5} h='75%' alignItems='center'>
                <Box
                    flexBasis={['100%', null, null, '70%', '55%', '50%']}
                    maxWidth='40em'
                >
                    <Heading as='h1' size='xl'>
                        {course.title}
                    </Heading>
                    <Text>by {course.provider.name}</Text>
                    <Text>{course.sub_title}</Text>
                    {!isEnrolled && (
                        <Button
                            textColor='black'
                            bgColor='white'
                            px={10}
                            onClick={() => enrollMutation.mutate(course)}
                            isLoading={enrollMutation.isLoading}
                        >
                            {!course?.product_information
                                .on_subscription_plan &&
                            course?.product_information.one_off_purchase
                                ?.product !== null
                                ? 'Purchase'
                                : 'Enroll'}
                        </Button>
                    )}
                    {isCompleted && (
                        <Box
                            mt={10}
                            w='100%'
                            borderRadius='lg'
                            bgColor='whiteAlpha.800'
                            textColor='black'
                            p={7}
                        >
                            <CompletedBox course={course} />
                        </Box>
                    )}
                </Box>
                <Spacer minW='1em' />
                <Box flex='0 0 auto'>
                    {!course.overview_image_url && (
                        <AspectRatio ratio={3 / 2} width='350px'>
                            <Image
                                src={course.image_url}
                                alt={`${course.title} image`}
                                borderRadius='lg'
                                objectFit='cover'
                            />
                        </AspectRatio>
                    )}
                </Box>
            </Flex>
        </Box>
    );
};

export default PageHeader;
