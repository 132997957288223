import * as React from 'react';
import {
    Avatar,
    Box,
    Button,
    Flex,
    HStack,
    Image,
    Stack,
} from '@chakra-ui/react';
import AvatarMessage from '../../atoms/AvatarMessage/AvatarMessage';
import UserMessage from '../../atoms/UserMessage/UserMessage';
import { getChatStyles } from '../../theme/consts';
import { useStore } from '../../stores';

interface Props {
    avatarImageSrc: string;
    isTextMode: boolean;
    onCitationClick: (citation: Citation) => void;
    messages: TextMessage[];
    innerContainerRef: React.RefObject<HTMLDivElement>;
}

type MessageProps = Omit<Props, 'messages' | 'innerContainerRef'> & {
    message: TextMessage;
    showFeedbackButtons: boolean;
};

const _Message: React.FC<MessageProps> = ({
    avatarImageSrc,
    isTextMode,
    onCitationClick,
    message,
    showFeedbackButtons,
}) => {
    const setAttachment = useStore(state => state.setAttachment);
    const setCurrentModal = useStore(state => state.setCurrentModal);

    if (message.type === 'user') {
        return (
            <HStack justifyContent='flex-end'>
                <UserMessage text={message.text} isTextMode={isTextMode} />
            </HStack>
        );
    } else {
        const hasAttachments =
            message.attachments && message.attachments?.length > 0;
        return (
            <HStack alignItems='flex-start'>
                {isTextMode && <Avatar src={avatarImageSrc} boxSize='32px' />}
                <Box>
                    <AvatarMessage
                        actionId={message.actionId}
                        showFeedbackButtons={showFeedbackButtons}
                        text={message.text}
                        onCitationClick={onCitationClick}
                        isTextMode={isTextMode}
                    />
                    {hasAttachments && (
                        <Flex wrap={'wrap'} gap={4} mt={3}>
                            {message.attachments!.map((attachment, i) => (
                                <Button
                                    key={i}
                                    variant='unstyled'
                                    p='0px'
                                    w='125px'
                                    h='125px'
                                    onClick={() => {
                                        setCurrentModal('attachment');
                                        setAttachment({
                                            title: attachment.name,
                                            description: attachment.description,
                                            url: attachment.url,
                                        });
                                    }}
                                >
                                    <Image
                                        src={attachment.url}
                                        alt={attachment.name}
                                        objectFit='cover'
                                        w='125px'
                                        h='125px'
                                        borderRadius='10px'
                                    />
                                </Button>
                            ))}
                        </Flex>
                    )}
                </Box>
            </HStack>
        );
    }
};

// The message list changes often (especially with the typewriter effect running)
// so we use memoized components to skip re-rendering the already existing messages
const Message = React.memo(_Message, (prevProps, nextProps) => {
    return (
        prevProps.message.actionId === nextProps.message.actionId &&
        // Length eq is enough under the assumption that appending is the only operation on message.text
        prevProps.message.text.length === nextProps.message.text.length &&
        prevProps.isTextMode === nextProps.isTextMode &&
        prevProps.onCitationClick === nextProps.onCitationClick &&
        prevProps.avatarImageSrc === nextProps.avatarImageSrc &&
        prevProps.showFeedbackButtons === nextProps.showFeedbackButtons
    );
});

const ChatHistory: React.FC<Props> = ({
    avatarImageSrc,
    isTextMode,
    onCitationClick,
    messages,
    innerContainerRef,
}) => {
    const styles = getChatStyles(isTextMode);

    return (
        <Stack ref={innerContainerRef} mx='auto' {...styles.historyContainer}>
            {messages.map((message, index) => (
                <Message
                    key={index}
                    message={message}
                    isTextMode={isTextMode}
                    onCitationClick={onCitationClick}
                    avatarImageSrc={avatarImageSrc}
                    showFeedbackButtons={index === messages.length - 1}
                />
            ))}
        </Stack>
    );
};

export default ChatHistory;
