import React from 'react';
import GroupOverviewPageContent from '../components/portfolio/GroupOverviewPageContent';
import { Mixpanel } from 'mixpanel-browser';
import { MixpanelConsumer } from '../components/contexts/MixpanelContext';
import DashboardPageLayout from '../components/layouts/DashboardPageLayout';

const GroupOverviewPage: React.FC = () => {
    return (
        <DashboardPageLayout>
            <MixpanelConsumer>
                {(mixpanel?: Mixpanel): JSX.Element => (
                    <GroupOverviewPageContent mixpanel={mixpanel} />
                )}
            </MixpanelConsumer>
        </DashboardPageLayout>
    );
};

export default GroupOverviewPage;
