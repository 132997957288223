export interface MicrophoneConfig {
    sampleRate: number;
    bufferSize: number;
    onData: (data: Int16Array) => void;
    onError: (error: Error) => void;
}

export class MicrophoneManager {
    private onData: MicrophoneConfig['onData'];
    private _onError: MicrophoneConfig['onError'];
    private sampleRate: MicrophoneConfig['sampleRate'];
    private bufferSize: MicrophoneConfig['bufferSize'];

    private shouldBuffer = false;
    private shouldRecord = false;
    private audioBuffer: Int16Array[] = [];

    private audioContext?: AudioContext;
    private audioStream?: MediaStream;
    private audioStreamSource?: MediaStreamAudioSourceNode;
    private audioProcessor?: ScriptProcessorNode;

    constructor(config: MicrophoneConfig) {
        this.onData = config.onData;
        this._onError = config.onError;

        this.sampleRate = config.sampleRate;
        this.bufferSize = config.bufferSize;
    }

    isInitialized() {
        return !!this.audioStream;
    }

    private onError = (error: Error) => {
        console.error('[MicrophoneManager]: on error', error);
        this.destroy();
        this._onError(error);
    };

    private onAudioProcess = (event: AudioProcessingEvent) => {
        if (!this.shouldRecord) return;

        const floatSamples = event.inputBuffer.getChannelData(0);
        const int16Samples = Int16Array.from(floatSamples.map(n => n * 32767));

        if (this.shouldBuffer) {
            this.audioBuffer.push(int16Samples);
            return;
        }

        this.onData(int16Samples);
    };

    private _initialize = async () => {
        if (this.isInitialized()) return true;
        console.log('[MicrophoneManager]: initializing');

        this.audioContext = new AudioContext({ sampleRate: this.sampleRate });
        this.audioStream = await navigator.mediaDevices.getUserMedia({
            audio: {
                noiseSuppression: true,
                echoCancellation: true,
                sampleRate: this.sampleRate,
            },
        });

        this.audioStreamSource = this.audioContext.createMediaStreamSource(
            this.audioStream,
        );

        this.audioProcessor = this.audioContext.createScriptProcessor(
            this.bufferSize,
            1,
            1,
        );

        this.audioProcessor.onaudioprocess = this.onAudioProcess;

        this.audioStreamSource.connect(this.audioProcessor);
        this.audioProcessor.connect(this.audioContext.destination);
    };

    initialize = async () => {
        const startTime = performance.now();

        try {
            await this._initialize();
            return true;
        } catch (error) {
            this.onError(error as Error);
        } finally {
            console.log(
                `[MicrophoneManager]: initialize took ${performance.now() -
                    startTime}ms`,
            );
        }
    };

    private _destroy = () => {
        this.audioContext?.close();
        this.audioContext = undefined;

        this.audioStream?.getTracks().forEach(track => track.stop());
        this.audioStream = undefined;

        this.audioStreamSource?.disconnect();
        this.audioStreamSource = undefined;

        this.audioProcessor?.disconnect();
        this.audioProcessor = undefined;
    };

    destroy = () => {
        const startTime = performance.now();

        try {
            this._destroy();
        } catch (error) {
            this.onError(error as Error);
        } finally {
            console.log(
                `[MicrophoneManager]: destroy took ${performance.now() -
                    startTime}ms`,
            );
        }
    };

    sendBufferedAudio = () => {
        if (!this.shouldBuffer) return;

        console.log('[MicrophoneManager]: sending buffered audio', {
            audioBuffer: this.audioBuffer.length,
        });

        this.audioBuffer.forEach(this.onData);

        this.audioBuffer = [];
        this.shouldBuffer = false;
    };

    setShouldRecord = (shouldRecord: boolean) => {
        this.shouldRecord = shouldRecord;

        this.audioBuffer = [];
        this.shouldBuffer = this.shouldRecord;
    };
}
