// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getReadableTimeFromMinutes = timeInMinutes => {
    if (timeInMinutes === 0) {
        return '0m';
    }
    if (timeInMinutes === 1) {
        return '1m';
    }
    if (timeInMinutes < 60) {
        return `${timeInMinutes}m`;
    }
    if (timeInMinutes === 60) {
        return '1h';
    }
    if (timeInMinutes > 60 && timeInMinutes < 120) {
        const remainder = timeInMinutes - 60;
        if (remainder > 0) {
            return `1h ${timeInMinutes - 60}m`;
        } else {
            return '1h';
        }
    }

    const hours = Math.round(timeInMinutes / 60);
    const minutes = timeInMinutes % 60;
    if (minutes > 0) {
        return `${hours}h ${minutes}m`;
    } else {
        return `${hours}h`;
    }
};

const formatMinNumberOfDigits = (n, minSize) => {
    return `${n}`.padStart(minSize, '0');
};
const getReadableTimeFromSeconds = timeInSeconds => {
    return `${formatMinNumberOfDigits(
        Math.floor(timeInSeconds / 60),
        2,
    )}:${formatMinNumberOfDigits(timeInSeconds % 60, 2)}`;
};

export { getReadableTimeFromSeconds };
export default getReadableTimeFromMinutes;
