import React, { Fragment } from 'react';
import Logo from '../../images/logo_white_full.svg';
import {
    Box,
    Center,
    Flex,
    Heading,
    Image,
    Link,
    Spacer,
    useMediaQuery,
    useToken,
    VStack,
} from '@chakra-ui/react';

import Footer from '../generic/Footer';
import TutorAvatar from '../../images/avatar-tutor.png';
import config from '../../consts/config';

interface AuthPageLayoutProps {
    title: string | React.ReactElement;
    subTitle?: string | React.ReactElement;
    imgSrc?: string;
}

const PADDING_X = ['2rem', null, null, '4rem'];
const PADDING_Y = ['1.5rem', null, null, '2.5rem'];

const AuthPageLayout: React.FC<AuthPageLayoutProps> = ({
    children,
    title,
    subTitle,
    imgSrc,
}) => {
    const [breakpointLg, breakpointXXL] = useToken('breakpoints', [
        'lg',
        '2xl',
    ]);
    const [isLarge, isXXL] = useMediaQuery([
        `(min-width: ${breakpointLg})`,
        `(min-width: ${breakpointXXL})`,
    ]);

    return (
        <Flex w='100%' h='100vh' direction={['column', null, null, 'row']}>
            <Flex
                alignItems='start'
                direction='column'
                flexBasis={['auto', null, null, '50%']}
                paddingX={PADDING_X}
                paddingY={PADDING_Y}
                bg='brand.gradient.full'
            >
                <Link href={config.REACT_APP_BRANDING_HOME_URL} isExternal>
                    <Image
                        src={config.REACT_APP_BRANDING_LOGO || Logo}
                        alt={config.REACT_APP_BRANDING_TITLE}
                        h={['22px', null, null, '26px']}
                        mb={[6, null, null, 0]}
                    />
                </Link>
                {isLarge && <Spacer />}
                <VStack spacing={[3, null, null, 6]} alignItems='start'>
                    <Heading
                        color='white'
                        size={isLarge ? (isXXL ? '3xl' : '2xl') : 'xl'}
                    >
                        {title}
                    </Heading>
                    {subTitle && (
                        <Box>
                            <Heading
                                color='white'
                                fontWeight='normal'
                                size='md'
                            >
                                {subTitle}
                            </Heading>
                        </Box>
                    )}
                </VStack>
                {isLarge && (
                    <Fragment>
                        <Spacer />
                        {imgSrc ? (
                            <Image
                                src={imgSrc}
                                alt=''
                                mb={PADDING_Y.map(v =>
                                    v !== null ? `-${v}` : null,
                                )}
                                maxH='65%'
                            />
                        ) : (
                            <Spacer />
                        )}
                    </Fragment>
                )}
            </Flex>
            <Flex
                flexBasis='50%'
                flexGrow={1}
                direction='column'
                position='relative'
            >
                <Spacer />
                <Box paddingX={PADDING_X} paddingY={PADDING_Y}>
                    <Center>
                        <Box w='26rem'>{children}</Box>
                    </Center>
                </Box>

                <Spacer />
                <Footer position='relative' />
            </Flex>
        </Flex>
    );
};

export default AuthPageLayout;

export const RegistrationTitle = 'Set up your account';
export const RegistrationSubtitle: React.FC = () => {
    const [breakpointLg] = useToken('breakpoints', ['lg']);
    const [isLarge] = useMediaQuery(`(min-width: ${breakpointLg})`);

    return (
        <Fragment>
            You&apos;ve been invited to join{' '}
            <Link href={config.REACT_APP_BRANDING_HOME_URL} isExternal>
                {config.REACT_APP_BRANDING_ORGANISATION}
            </Link>
            .{isLarge ? <br /> : ' '}
            Let&apos;s get you started.
        </Fragment>
    );
};

export const LoginTitle: React.FC = () => (
    <Fragment>
        Digital humans. <br />
        Real world impact.
    </Fragment>
);

export const LoginImgSrc = TutorAvatar;
