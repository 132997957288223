import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const PersonOutline = createIcon({
    displayName: 'PersonOutline',
    viewBox: '0 0 114 91',
    path: (
        <>
            <path
                fill='currentColor'
                d='M75.336 33.285c0 6.958-2.42 14.272-6.278 19.728-3.921 5.547-8.504 8.144-12.53 8.144-4.024 0-8.607-2.597-12.529-8.144-3.857-5.456-6.278-12.77-6.278-19.728 0-8.316.911-14.64 3.463-18.804 1.047-1.708 2.538-3.319 4.936-4.504 2.4-1.186 5.713-1.949 10.409-1.949 4.695 0 8.009.763 10.408 1.949 2.398 1.185 3.89 2.796 4.936 4.504 2.552 4.164 3.463 10.488 3.463 18.804zm-18.807 35c7.178 0 13.664-4.53 18.35-11.158 4.687-6.63 7.585-15.375 7.585-23.842 0-8.447-.845-16.543-4.513-22.529C74.276 4.76 67.78.9 56.529.9c-11.252 0-17.747 3.86-21.422 9.856-3.668 5.986-4.514 14.082-4.514 22.53 0 8.466 2.899 17.211 7.586 23.841 4.686 6.629 11.171 11.158 18.35 11.158z'
            />
            <path
                fill='currentColor'
                d='M47.292 58.462a3.564 3.564 0 10-6.942 1.62c1.182 5.064-1.818 8.895-3.72 10.36L3.204 83.087a3.564 3.564 0 102.522 6.667l33.866-12.813c.262-.1.511-.229.743-.386 3.222-2.191 9.127-8.794 6.957-18.094zM66.708 58.462a3.564 3.564 0 116.941 1.62c-1.181 5.064 1.819 8.895 3.721 10.36l33.426 12.646a3.564 3.564 0 11-2.522 6.667L74.408 76.942a3.56 3.56 0 01-.743-.386c-3.223-2.191-9.127-8.794-6.957-18.094z'
            />
        </>
    ),
});

export const PenWritingOutline = createIcon({
    displayName: 'PenWritingOutline',
    viewBox: '0 0 91 100',
    d:
        'M67.959 1.085A17.292 17.292 0 0174.03 0c3.143-.006 6.217.833 8.832 2.406 2.616 1.574 4.656 3.813 5.86 6.434a13.048 13.048 0 01.908 8.29c-.613 2.784-2.127 5.34-4.35 7.346L29.9 74.486a3.726 3.726 0 01-1.698.851L5.026 80.622a4 4 0 01-1.849-.026 3.724 3.724 0 01-1.593-.847c-.441-.402-.76-.9-.924-1.447a2.966 2.966 0 01-.013-1.67l5.85-20.93a3.226 3.226 0 01.945-1.5l55.372-50.01a16.014 16.014 0 015.145-3.107zm9.385 6.033a9.434 9.434 0 00-3.314-.606 9.343 9.343 0 00-3.295.607 8.64 8.64 0 00-2.781 1.71l-3.775 3.443L76.334 23.25l3.812-3.409a7.714 7.714 0 001.9-2.519c.44-.945.667-1.96.667-2.987a7.057 7.057 0 00-.668-2.987 7.715 7.715 0 00-1.9-2.519 8.73 8.73 0 00-2.8-1.71zm-6.107 20.777l-12.192-11.02-45.79 41.35L9.139 72.95l16.347-3.74 45.75-41.316zM91 91.063H0V100h91v-8.937z',
});

export const VideoOutline = createIcon({
    displayName: 'VideoOutline',
    viewBox: '0 0 112 79',
    d:
        'M24.744 0C11.104 0 0 10.205 0 22.742v33.516C0 68.795 11.104 79 24.744 79h62.512C100.897 79 112 68.795 112 56.258V22.742C112 10.205 100.896 0 87.256 0H24.744zm0 7.182h62.512c9.447 0 16.93 6.878 16.93 15.561v33.515c0 8.683-7.483 15.561-16.93 15.561H24.744c-9.447 0-16.93-6.878-16.93-15.56V22.742c0-8.682 7.483-15.56 16.93-15.56zM72.83 37.166L41.476 18.924v40.387L72.75 42.328a2.968 2.968 0 001.565-2.559 2.964 2.964 0 00-1.486-2.603zM47.554 49.204v-19.82l17.62 10.252-17.62 9.568z',
});

export const BookOutline = createIcon({
    displayName: 'BookOutline',
    viewBox: '0 0 117 90',
    d:
        'M109.206 71.6862C109.212 72.1759 109.118 72.6619 108.931 73.1164C108.743 73.571 108.465 73.9853 108.112 74.3356C107.759 74.6859 107.338 74.9654 106.874 75.1581C106.409 75.3508 105.91 75.4529 105.405 75.4586C90.9035 75.3901 76.454 77.1484 62.4196 80.6895V22.0201C62.3823 20.1333 62.8928 18.2743 63.8929 16.6553C64.8929 15.0362 66.3423 13.7222 68.0756 12.8633C78.2964 9.06058 89.2047 7.29997 100.145 7.68722C102.113 7.68722 103.912 7.68721 105.473 7.78591C106.481 7.82003 107.437 8.23248 108.137 8.93628C108.838 9.64008 109.229 10.5802 109.229 11.5583L109.206 71.6862ZM7.7374 71.6862V11.5583C7.73364 10.5819 8.12052 9.64221 8.81668 8.93665C9.51284 8.23109 10.464 7.81468 11.4703 7.77495C24.1668 6.89733 36.9111 8.63135 48.8678 12.8633C50.6011 13.7222 52.0505 15.0362 53.0506 16.6553C54.0506 18.2743 54.5611 20.1333 54.5238 22.0201V80.7115C40.4942 77.1406 26.0432 75.3636 11.5382 75.4257C10.5241 75.4142 9.55573 75.0147 8.84389 74.3144C8.13204 73.614 7.73435 72.6694 7.7374 71.6862ZM105.733 0.241143C91.7932 -0.709885 77.8056 1.26893 64.7272 6.04228C62.2506 7.24693 60.1119 9.01544 58.4943 11.1964C56.8767 9.01544 54.7381 7.24693 52.2614 6.04228C39.1762 1.27975 25.1874 -0.698695 11.2441 0.241143C8.2173 0.338201 5.34898 1.57614 3.24913 3.69167C1.14928 5.80721 -0.0164185 8.63342 -3.32951e-05 11.5693V71.6862C-0.0271938 74.6589 1.16263 77.5206 3.30857 79.6442C5.45451 81.7677 8.38144 82.9796 11.4477 83.0144C21.2213 83.1788 45.2141 84.111 56.8202 89.5941C57.3343 89.8385 57.8991 89.966 58.4717 89.967C59.0649 89.9771 59.6516 89.8453 60.1798 89.5832C71.7746 84.1 95.7674 83.135 105.541 83.0034C108.607 82.9658 111.534 81.7533 113.681 79.6307C115.828 77.5081 117.021 74.648 117 71.6753V11.5583C117.004 8.62218 115.832 5.79924 113.731 3.68454C111.633 1.56519 108.762 0.32871 105.733 0.241143Z',
});

export const NotesMedicalOutline = createIcon({
    displayName: 'NotesMedicalOutline',
    viewBox: '0 0 18 23',
    path: (
        <>
            <path
                fill='currentColor'
                d='M14.6356 1.50023H13.0083C12.8195 0.643986 12.031 0 11.0899 0H6.90913C5.96807 0 5.18138 0.643986 4.99075 1.50023H3.36343C1.50922 1.50023 0 2.9591 0 4.75254V19.7477C0 21.5411 1.50829 23 3.36343 23H14.6356C16.4908 23 18 21.5411 18 19.7477V4.75344C18 2.96 16.4908 1.50113 14.6356 1.50113V1.50023ZM6.50834 1.89868C6.50834 1.68462 6.68874 1.51013 6.91006 1.51013H11.0899C11.3122 1.51013 11.4917 1.68462 11.4917 1.89868V3.00227H6.50834V1.89868ZM16.4378 19.7477C16.4378 20.7074 15.6288 21.489 14.6356 21.489H3.36343C2.37124 21.489 1.56223 20.7074 1.56223 19.7477V4.75344C1.56223 3.79376 2.37124 3.01216 3.36343 3.01216H4.94612V3.75778C4.94612 4.17511 5.29576 4.5133 5.72723 4.5133H12.2728C12.7042 4.5133 13.0539 4.17511 13.0539 3.75778V3.01216H14.6356C15.6288 3.01216 16.4378 3.79376 16.4378 4.75344V19.7486V19.7477Z'
            />
            <line
                x1='9'
                y1='8'
                x2='9'
                y2='16'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
            />
            <line
                x1='13'
                y1='12'
                x2='5'
                y2='12'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
            />
        </>
    ),
});
