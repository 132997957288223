import React from 'react';
import { IconButton, useTheme } from '@chakra-ui/react';
import Icon from '../Icon/Icon';
import { useStoreWithArray } from '../../stores';

interface Props {
    type: FeedbackPayload['type'];
    isSubmitted: FeedbackPayload['type'] | null;
    onSubmit: () => void;
}

const ICON_CHOICE = {
    positive: { submitted: 'thumbsUp', 'not-submitted': 'thumbsUpOutline' },
    negative: { submitted: 'thumbsDown', 'not-submitted': 'thumbsDownOutline' },
} as const;

export default function FeedbackButton({ type, isSubmitted, onSubmit }: Props) {
    const { isTextMode } = useStoreWithArray(['isTextMode']);

    const theme = useTheme();

    const buttonColors = {
        positive: theme.colors.green['400'],
        negative: theme.colors.red['400'],
    };

    const getButtonColor = (
        type: Props['type'],
        isSubmitted: Props['isSubmitted'],
        isTextMode: boolean,
    ) => {
        if (isSubmitted && isSubmitted === type) {
            return buttonColors[type];
        }
        return isTextMode ? 'dark.450' : 'white';
    };

    return (
        <IconButton
            aria-label={`Submit ${type.toLowerCase()} feedback for avatar response`}
            minW='25px!important'
            h='25px'
            background='transparent'
            disabled={!!isSubmitted}
            onClick={onSubmit}
            _hover={
                isSubmitted
                    ? { backgroundColor: 'transparent' }
                    : { color: buttonColors[type] }
            }
            _active={{ backgroundColor: 'transparent' }}
            color={getButtonColor(type, isSubmitted, isTextMode)}
        >
            <Icon
                iconChoice={
                    ICON_CHOICE[type][
                        isSubmitted ? 'submitted' : 'not-submitted'
                    ]
                }
                fontAwesomeProps={{
                    color: 'inherit',
                }}
            />
        </IconButton>
    );
}
