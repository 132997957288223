import React from 'react';
import { Dropdown, NavDropdown, NavItem, NavLink } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCog,
    faSignOutAlt,
    faSortDown,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import { authService, keycloak } from '../../services';
import WithTooltip from './WithTooltip';
import ModalOpener from './ModalOpener';
import UserSettingsModal from '../modals/UserSettingsModal';

const SettingsDropDown: React.FC = () => {
    return (
        <Dropdown as={NavItem} alignRight className='d-flex align-items-center'>
            <Dropdown.Toggle as={NavLink} className='hide-arrow pr-0'>
                <WithTooltip
                    side='bottom'
                    hoverMessage='User Actions'
                    fontSize={12}
                >
                    <div style={{ display: 'inline-block' }}>
                        <FontAwesomeIcon
                            icon={faUser}
                            size='lg'
                            color={'var(--primary)'}
                        />
                        <FontAwesomeIcon
                            icon={faSortDown}
                            className='mb-1 ml-1'
                            color={'var(--primary)'}
                        />
                    </div>
                </WithTooltip>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {!(keycloak?.tokenParsed as any)?.external_idp && (
                    <ModalOpener Modal={UserSettingsModal}>
                        <NavDropdown.Item>
                            <FontAwesomeIcon icon={faCog} /> Settings
                        </NavDropdown.Item>
                    </ModalOpener>
                )}
                <NavDropdown.Item
                    className='justify-content-end'
                    onClick={(): void => {
                        authService.logout();
                    }}
                >
                    <FontAwesomeIcon icon={faSignOutAlt} /> Logout
                </NavDropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default SettingsDropDown;
